import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAppDispatch } from './reduxHooks';
import { revokeUkgAndCleverAntSession } from '../redux/session/sessionSlice';

/** milliseconds * seconds * minutes */
const timeoutMilliseconds = 1_000 * 60 * 8; // 8 minute inactivity
const promptBeforeIdleMilliseconds = 1_000 * 60 * 2; // prompt 2 minutes before inactivity

export const useUserInactivity = (setOpen: (arg0: boolean) => void) => {
  const dispatch = useAppDispatch();
  const [idleState, setIdleState] = useState<'Active' | 'Idle' | 'Prompted'>(
    'Active',
  );
  const [remaining, setRemaining] = useState<number>(timeoutMilliseconds);
  const onIdle = () => {
    setIdleState('Idle');
    setOpen(false);
    dispatch(revokeUkgAndCleverAntSession());
  };

  const onActive = () => {
    if (idleState === 'Idle') return;
    setIdleState('Active');
    setOpen(false);
  };

  const onPrompt = () => {
    if (idleState === 'Idle') return;
    setIdleState('Prompted');
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle: promptBeforeIdleMilliseconds,
    timeout: timeoutMilliseconds,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  const handleStillHere = () => {
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return { idleState, remaining, handleStillHere };
};
