import React from 'react';
import { removeNotification } from '../../../redux/notifications/notificationsSlice';
import { Notification, Notifications } from '../Notification';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';

const GlobalNotifications = () => {
  const dispatch = useAppDispatch();

  const { notifications } = useAppSelector(state => state.notifications);

  return (
    <Notifications toaster>
      {notifications.map(n => (
        <Notification
          key={n.id}
          ariaLabel={n.props.ariaLabel || 'Notification'}
          handleDismiss={() => dispatch(removeNotification(n.id))}
          fullWidth
          type={n.props.type}
          hasTimeout={n.props.hasTimeout}
        >
          {n.message}
        </Notification>
      ))}
    </Notifications>
  );
};

export default GlobalNotifications;
