import { createSlice } from '@reduxjs/toolkit';
import { startOfToday, startOfWeek, startOfMonth } from 'date-fns';
import { addedDay, addedMonth, addedWeek } from '../../utils/helper';

const today = startOfToday();
const firstDayOfTheCurrentMonth = startOfMonth(today);

export type BackToPageObject = {
  label: string;
  url: string;
}

export type ScheduleSliceState = {
  loading: boolean;
  data: {
    shifts: any[];
    paycode: any[];
  };
  error: any;
  backTo: BackToPageObject | null;
  showSidePanel: boolean;
  sidePanelDate: string;
  selectedEvent: MySchedule.CalendarEventType | null;
  calendar: {
    currentView: string;
    selectedDay: Date;
    selectedDayOnWeek: Date;
    selectedWeek: Date;
    selectedMonthOnWeekView: Date;
    selectedMonth: Date;
    selectedMiniMonth: Date;
    hasPassedDayRange: boolean;
    isHasPassedDayRangeDismissed: boolean;
  };
  showHistoricEditModal: string | null;
  showHistoricAddModal: boolean;
  showHistoricSuccess: boolean;
  showHistoricEditSuccess: boolean;
};

const initialState: ScheduleSliceState = {
  loading: false,
  data: { shifts: [], paycode: [] },
  error: null,
  backTo: null,
  showSidePanel: false,
  sidePanelDate: '',
  selectedEvent: null,
  calendar: {
    currentView: 'month',
    selectedDay: today,
    selectedDayOnWeek: today,
    selectedWeek: startOfWeek(new Date()),
    selectedMonthOnWeekView: startOfWeek(new Date()),
    selectedMonth: firstDayOfTheCurrentMonth,
    selectedMiniMonth: firstDayOfTheCurrentMonth,
    hasPassedDayRange: false,
    isHasPassedDayRangeDismissed: false,
  },
  showHistoricSuccess: false,
  showHistoricEditSuccess: false,
  showHistoricEditModal: null,
  showHistoricAddModal: false,
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setBackTo: (state, action) => {
      state.backTo = action.payload;
    },
    openSidePanel: state => {
      state.showSidePanel = true;
    },
    closeSidePanel: state => {
      state.showSidePanel = false;
    },
    setSidePanelDate: (state, action) => {
      state.sidePanelDate = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    currentViewChange: (state, action) => {
      state.calendar.currentView = action.payload;
    },
    handleSelectedDayChange: (state, action) => {
      state.calendar.selectedDay = action.payload;
    },
    handleSelectedDayOnWeekChange: (state, action) => {
      state.calendar.selectedDayOnWeek = action.payload;
      state.calendar.selectedDay = action.payload;
    },
    handleSelectedWeekChange: (state, action) => {
      state.calendar.selectedWeek = action.payload;
    },
    handleSelectedMonthOnWeekViewChange: (state, action) => {
      state.calendar.selectedMonthOnWeekView = action.payload;
    },
    handleSelectedMonthChange: (state, action) => {
      state.calendar.selectedMonth = action.payload;
    },
    handleMiniMonthChange: (state, action) => {
      state.calendar.selectedMiniMonth = action.payload;
    },

    handlePassedDayRangeChange: (state, action) => {
      state.calendar.hasPassedDayRange = action.payload;
    },
    handleDismissPassedDayRange: (state, action) => {
      state.calendar.isHasPassedDayRangeDismissed = action.payload;
    },
    toggleHistoricEditModal: (state, action) => {
      state.showHistoricEditModal = action.payload;
    },
    toggleHistoricAddModal: (state, action) => {
      state.showHistoricAddModal = action.payload;
    },
    toggleShowHistoricSuccess: (state, action) => {
      state.showHistoricSuccess = action.payload;
    },
    toggleShowHistoricEditSuccess: (state, action) => {
      state.showHistoricEditSuccess = action.payload;
    },
    updateDate: (
      state,
      {
        payload,
      }: {
        payload: {
          direction?: 'next' | 'previous';
          type: 'day' | 'week' | 'month' | 'today';
        };
      },
    ) => {
      if (payload.type === 'today') {
        state.calendar.selectedDay = today;
        state.calendar.selectedDayOnWeek = today;
        state.calendar.selectedWeek = today;
        state.calendar.selectedMonthOnWeekView = today;
        state.calendar.selectedMonth = today;
        state.calendar.selectedMiniMonth = today;
      }

      if (payload.direction) {
        switch (payload.type) {
          case 'month':
            state.calendar.selectedDay = startOfMonth(
              addedMonth(state.calendar.selectedDay, payload.direction),
            );
            state.calendar.selectedDayOnWeek = startOfMonth(
              addedMonth(state.calendar.selectedDayOnWeek, payload.direction),
            );
            state.calendar.selectedWeek = startOfMonth(
              addedMonth(state.calendar.selectedWeek, payload.direction),
            );
            state.calendar.selectedMonthOnWeekView = startOfMonth(
              addedMonth(
                state.calendar.selectedMonthOnWeekView,
                payload.direction,
              ),
            );
            state.calendar.selectedMonth = startOfMonth(
              addedMonth(state.calendar.selectedMonth, payload.direction),
            );
            state.calendar.selectedMiniMonth = startOfMonth(
              addedMonth(state.calendar.selectedMiniMonth, payload.direction),
            );
            break;

          case 'week':
            state.calendar.selectedDay = addedWeek(
              state.calendar.selectedDay,
              payload.direction,
            );
            state.calendar.selectedDayOnWeek = addedWeek(
              state.calendar.selectedDayOnWeek,
              payload.direction,
            );
            state.calendar.selectedWeek = addedWeek(
              state.calendar.selectedWeek,
              payload.direction,
            );
            state.calendar.selectedMonthOnWeekView = addedWeek(
              state.calendar.selectedMonthOnWeekView,
              payload.direction,
            );
            state.calendar.selectedMonth = addedWeek(
              state.calendar.selectedMonth,
              payload.direction,
            );
            state.calendar.selectedMiniMonth = addedWeek(
              state.calendar.selectedMiniMonth,
              payload.direction,
            );
            break;

          case 'day':
            state.calendar.selectedDay = addedDay(
              state.calendar.selectedDay,
              payload.direction,
              1,
            );
            state.calendar.selectedDayOnWeek = addedDay(
              state.calendar.selectedDayOnWeek,
              payload.direction,
              1,
            );
            state.calendar.selectedWeek = startOfWeek(
              addedDay(state.calendar.selectedDay, payload.direction, 1),
            );
            state.calendar.selectedMonthOnWeekView = addedDay(
              state.calendar.selectedDay,
              payload.direction,
              1,
            );
            state.calendar.selectedMonth = addedDay(
              state.calendar.selectedDay,
              payload.direction,
              1,
            );
            state.calendar.selectedMiniMonth = addedDay(
              state.calendar.selectedDay,
              payload.direction,
              1,
            );
            break;

          default:
            break;
        }
      }
    },
  },
});

export const {
  setBackTo,
  openSidePanel,
  closeSidePanel,
  setSidePanelDate,
  setSelectedEvent,
  currentViewChange,
  handleSelectedDayChange,
  handleSelectedDayOnWeekChange,
  handleSelectedWeekChange,
  handleSelectedMonthOnWeekViewChange,
  handleSelectedMonthChange,
  handleMiniMonthChange,
  handlePassedDayRangeChange,
  handleDismissPassedDayRange,
  toggleHistoricEditModal,
  toggleHistoricAddModal,
  toggleShowHistoricSuccess,
  toggleShowHistoricEditSuccess,
  updateDate,
} = scheduleSlice.actions;
export default scheduleSlice.reducer;
