import {
  BaseQueryApi,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { parseISO } from 'date-fns';
import { getFormattedErrorMessage } from '../utils/data/toastMessage';
import { setError } from '../redux/systemError/systemErrorSlice';
import { extractRTKErrors } from '../components/common/NetworkErrorNotifications/NetworkErrorNotifications';
import { dateToString } from '../utils/time';

/**
 * inspects a /run response for a failed status and non 200 http code
 * @param response
 * @returns
 */
export const isCleverAntRunError = <T>(
  response: unknown,
): response is CleverAntAPI.ApiErrorResponse<T> => {
  if (typeof response !== 'object' || response === null) {
    return false;
  }

  const runArray = (response as any).run;
  if (!Array.isArray(runArray)) {
    return false;
  }
  const run = runArray[0];
  if (!(run || 'status' in run || 'httpcode' in run)) return false;

  const isStatusFailed = run?.status === 'failed';
  const isHttpCodeError =
    run?.httpcode !== undefined && !run?.httpcode.startsWith('2');

  return isStatusFailed || isHttpCodeError;
};

export const isCleverAntMultiOperationError = (
  response: unknown,
): response is CleverAntAPI.ApiMultiOperationErrorResponse => {
  if (typeof response !== 'object' || response === null) {
    return false;
  }

  const runArray = (response as any).Run;
  if (!Array.isArray(runArray)) {
    return false;
  }

  for (let i = 0; i < runArray.length; i += 1) {
    const run = runArray[i];
    if (typeof run !== 'object' || run === null) {
      return false;
    }
    if (
      typeof run.Status !== 'string' ||
      typeof run.Message !== 'string' ||
      typeof run.CaId !== 'string'
    ) {
      return false;
    }
  }

  return true;
};

/**
 * formats a CleverAnt api response into an RTK-Query error object.
 * @param response the raw data to inspect
 * @returns an RTK-Query error object
 */
export const returnCleverAntError = <T>(
  response: CleverAntAPI.ApiErrorResponse<T>,
  genericErrorMsg: string = 'An unknown error occured.',
) => {
  return {
    error: {
      status: Number(response?.run[0].httpcode) || 400,
      statusText: response?.run[0]?.logentries[0].message ?? genericErrorMsg,
      data: response?.response ?? null,
    },
  };
};

/**
 * processes specific thrown errors and returns an RTK-Query error object
 * @param error thrown error
 * @param genericErrorMsg msg if none is found
 * @returns RTK-Query error object
 */
export const handleUnexpectedApiError = (
  error: unknown,
  genericErrorMsg: string = 'An unknown error occured.',
  baseQueryApi?: BaseQueryApi,
): {
  error: {
    status: number;
    statusText: string;
    data: unknown;
  };
} => {
  const errObj = {
    error: {
      status: 400,
      statusText: genericErrorMsg,
      data: null,
    },
  };
  if (error instanceof Error) {
    errObj.error.statusText = error.message;
  }
  if (baseQueryApi) baseQueryApi.dispatch(setError([errObj.error.statusText]));
  return errObj;
};

/** type safe check for message property of server response */
export const hasLogEntryMessage = (
  data: unknown,
): data is { run: CleverAntAPI.ApiRun[]; response: any } => {
  if (
    typeof data === 'object' &&
    data !== null &&
    'run' in data &&
    Array.isArray(data.run)
  ) {
    const run = data.run[0];
    if (
      typeof run === 'object' &&
      run !== null &&
      'logentries' in run &&
      Array.isArray(run.logentries)
    ) {
      const logEntry = run.logentries[0];
      return (
        typeof logEntry === 'object' &&
        logEntry !== null &&
        'Message' in logEntry &&
        typeof logEntry.Message === 'string'
      );
    }
  }
  return false;
};

/** extract all error messages from response */
export const handleApiRunError = (
  result: CleverAntAPI.ApiErrorResponse<any>,
): QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> => {
  const data = getFormattedErrorMessage(result);

  const primaryError =
    result &&
    typeof result === 'object' &&
    'response' in result &&
    Array.isArray(result.response) &&
    'run' in result &&
    Array.isArray(result.run) &&
    'caid' in result.run[0]
      ? `${
          result.response[0]?.Message || 'An unknown error occurred.'
        } Error Details: ${result.run[0]?.caid ?? ''}`
      : '';

  return {
    error: {
      error: primaryError,
      // @ts-ignore
      status: result?.run[0].httpcode,
      data,
      // @ts-ignore
      response: result?.response,
    },
  };
};

export const stringContainsSubstring = (
  string: string,
  substrings: string[],
) => {
  for (let i = 0; i < substrings.length; i += 1) {
    if (string.includes(substrings[i])) {
      return true;
    }
  }
  return false;
};

export const handleNoPositionsErrorOnCalendar = (
  baseQueryReturnValue: FetchBaseQueryError,
  { startDate, endDate }: { startDate: string; endDate: string },
) => {
  const startFormatted = dateToString(
    parseISO(startDate),
    'EEEE, MMMM d, yyyy',
  );
  const endFormatted = dateToString(parseISO(endDate), 'EEEE, MMMM d, yyyy');
  const errors = extractRTKErrors(baseQueryReturnValue);
  const containsSubstring = errors.some(string => {
    return (
      string
        .toLowerCase()
        .includes('the current user does not have a position assignment') ||
      string.toLowerCase().includes('the user has no position assignments')
    );
  });

  if (containsSubstring) {
    const isSameDate = startFormatted === endFormatted;
    const rangeMessage = isSameDate
      ? `${startFormatted}`
      : `the range ${startFormatted} - ${endFormatted}`;
    return {
      ...baseQueryReturnValue,
      data: [
        `The current user does not have a position assignment for ${rangeMessage}.`,
      ],
    } as FetchBaseQueryError;
  }

  return baseQueryReturnValue;
};
