import React, { Suspense, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { isEqual } from 'lodash';

import Spinner from '../components/common/Spinner/Spinner';
import Routes from '../routes';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { findUKGSession } from '../hooks/useUKGSession';
import { addUKGSessionToStore } from '../redux/session/sessionSlice';
import initialSessionState from '../redux/session/initialState';
import MainNavigation from '../components/Navigation/MainNavigation';
import useGetPermissions from '../hooks/ManagerPermissions/useGetPermissions';
import FourOhFourPage from '../pages/FourOhFourPage/FourOhFourPage';
import InactivityDialog from '../components/inactivity/InactivityDialog';
import GlobalNotifications from '../components/common/GlobalNotifications/GlobalNotifications';

function AppWithInactivity() {
  const dispatch = useAppDispatch();
  const { isRevoked, ukgSession: ukgSessionInRedux } = useAppSelector(
    state => state.session,
  );

  /** fetch permissions here so that the data is available to build navigation */
  const { loading } = useGetPermissions(isRevoked);

  useEffect(() => {
    if (isEqual(ukgSessionInRedux, initialSessionState.ukgSession)) {
      const ukgSession = findUKGSession();
      if (ukgSession) {
        dispatch(addUKGSessionToStore(ukgSession));
      }
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      {loading && <Spinner />}
      <HashRouter>
        <InactivityDialog />
        <MainNavigation />
        <GlobalNotifications />
        {isRevoked ? (
          <FourOhFourPage message="Your session has ended due to inactivity. Please close any open tabs and login again." />
        ) : (
          <Routes />
        )}
      </HashRouter>
    </Suspense>
  );
}

export default AppWithInactivity;
