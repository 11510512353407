export interface DialogModalProps {
  isOpen: boolean;
  onClose: () => void;
  size: string;
  hideYOverflow?: boolean;
  zIndex: string;
}

export enum ModalDesktopSize {
  SMALL = 'small',
  LARGE = 'large',
}
