import cn from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import { isNil } from 'lodash';

import { NotificationsProps } from './Notification.types';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { clearError } from '../../../redux/systemError/systemErrorSlice';
import { RootState } from '../../../redux/store';
import BannerNotification from '../BannerNotification/BannerNotification';
import Notification from './Notification';

/**
 * Notifications
 * @description Container to handle display and layout of multiple
 * Notification components. Also handles whether notifications should
 * be displayed as "toasts".
 * @param {NotificationsProps} props
 */
export function Notifications(
  props: PropsWithChildren<NotificationsProps>,
): ReactElement {
  const { children, toaster, onViewport } = props;

  const dispatch = useAppDispatch();
  const systemError = useAppSelector((state: RootState) => state.error);
  const handleDismissError = () => {
    dispatch(clearError());
  };

  return (
    <div
      className={
        onViewport
          ? 'top-2 left-0 right-0 z-[1] sticky'
          : `flex justify-center sticky ${
              isNil(systemError.error) ? 'top-2' : 'top-0'
            } z-notification`
      }
    >
      <div
        className={cn(
          'flex flex-col gap-2 justify-center w-full absolute',
          {
            'top-0 left-0 right-0 z-notification': toaster,
          },
          isNil(systemError.error) ? 'mt-2' : 'mt-0',
        )}
      >
        {!isNil(systemError.error) && (
          <Notification
            ariaLabel="System error message"
            type="system"
            handleDismiss={handleDismissError}
            fullWidth
          >
            <BannerNotification message={systemError.error} />
          </Notification>
        )}

        <div
          className={cn(
            'flex flex-col gap-2 justify-center max-w-toaster md:mx-auto px-4 md:px-0',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
