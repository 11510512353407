import React, { useState } from 'react';
import DialogModal from '../common/DialogModal/DialogModal';
import Button from '../common/Button/Button';
import { useUserInactivity } from '../../hooks/useUserInactivity';

const InactivityDialog = () => {
  const [openTimeoutDialog, setOpenTimeoutDialog] = useState<boolean>(false);

  const { remaining, handleStillHere } =
    useUserInactivity(setOpenTimeoutDialog);

  const seconds = remaining > 1 ? 'seconds' : 'second';

  return (
    <DialogModal
      isOpen={openTimeoutDialog}
      onClose={() => setOpenTimeoutDialog(false)}
      size="small"
      zIndex="z-[60]"
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <h2 className="text-lg font-bold">Inactivity Detected</h2>
          <p>
            It appears that you&apos;ve been inactive for some time. To ensure
            the security of your account, we will automatically log you out in{' '}
            {remaining} {seconds}.
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-4 sm:mt-6">
          <Button
            variant="primary"
            onClick={handleStillHere}
            text="I'm still here"
            size="small"
            customizedClassName="text-base h-10"
          />
        </div>
      </div>
    </DialogModal>
  );
};

export default InactivityDialog;
