import { DefaultBodyType, rest } from 'msw';
import { endPointConfig } from '../config';
import { apiParams } from '../../../api/params';

const handlers = [
  rest.post(`${apiParams.runActionUrl}`, (req, res, ctx) => {
    // validate search params
    const { searchParams } = req.url;
    const action = searchParams.get('action');
    const endpoint = endPointConfig.mockedEndpoints.find(
      e => e.action === action,
    );
    if (!endpoint) {
      return req.passthrough();
    }
    const expectedParams = endpoint.searchParams;
    const paramKeys = [...searchParams.keys()];
    const validSearchParams =
      paramKeys.length === expectedParams.length &&
      paramKeys.every(p => expectedParams.includes(p));

    if (validSearchParams) {
      // @ts-ignore
      const httpcode = endpoint.response?.run?.[0]?.httpcode;
      return res(
        ctx.status(httpcode ? +httpcode : 200),
        ctx.delay(),
        ctx.json(endpoint.response as DefaultBodyType),
      );
    }

    return res(
      ctx.status(400),
      ctx.delay(),
      ctx.text(
        `Missing one or more required params.
        \nExpected: ${JSON.stringify(expectedParams)}
        \nActual: ${JSON.stringify(paramKeys)}`,
      ),
    );
  }),
];

export default handlers;
