import React from 'react';
import { DesktopNavigationGroupProps } from './DesktopNavigationGroup.types';
import DesktopNavigationGroupItem from '../DesktopNavigationGroupItem/DesktopNavigationGroupItem';

const DesktopNavigationGroup: React.FC<DesktopNavigationGroupProps> = props => {
  const { title, groupItems } = props;

  return (
    <div className="flex flex-col first:pl-0 px-2 last:pr-0 w-[220px]">
      <p className="text-xs text-secondary-dark-green font-semibold px-2 pb-2">
        {title}
      </p>
      <div className="flex flex-col gap-y-2 pt-1">
        {groupItems.map((groupItem, index) => {
          const visibility = {
            visibleOnDesktop: true,
            ...groupItem.visibility,
          };

          if (visibility.visibleOnDesktop) {
            return (
              <DesktopNavigationGroupItem
                key={index}
                title={groupItem.title}
                description={groupItem.description}
                path={groupItem.path}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default DesktopNavigationGroup;
