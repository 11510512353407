import { rest } from 'msw';
import openSessionResponse from '../../responses/session/open-session.json';
import { endPointConfig } from '../config';

const handlers = [
  rest.post(`${endPointConfig.baseUrl}/open`, async (req, res, ctx) => {
    // only mock /open requests when the value of the request templatename
    // matches the templatename value in the mockedEndpoints object
    const { searchParams } = req.url;
    const reqTemplatename = searchParams.get('templatename') ?? '';

    const endpoint = endPointConfig.mockedEndpoints.find(
      config => config.templatename === reqTemplatename,
    );
    if (!endpoint || endpoint.templatename !== reqTemplatename) {
      return req.passthrough();
    }

    const expectedParams = [
      'templatename',
      'username',
      'password',
      'serveraddress',
      'accessToken',
    ];
    const paramKeys = [...searchParams.keys()];
    const validSearchParams =
      paramKeys.length === expectedParams.length &&
      paramKeys.every(p => expectedParams.includes(p));

    if (validSearchParams) {
      return res(ctx.status(200), ctx.delay(), ctx.json(openSessionResponse));
    }

    return res(
      ctx.status(400),
      ctx.delay(),
      ctx.text(
        'missing one or more required params: [templatename, username, password, serveraddress, accessToken]',
      ),
    );
  }),
];

export default handlers;
