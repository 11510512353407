import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { apiSlice } from '../apiSlice';
import { apiParams } from '../../../api/params';
import { handleUnexpectedApiError } from '../../../api/apiUtils';
import { setError } from '../../systemError/systemErrorSlice';
import { RootState } from '../../store';
import { enableOpenCaching, keepOpenDataInSeconds } from '../constants';

const enableManagerPermissions =
  process.env.REACT_APP_BETA_FEATURE_MANAGER_PERMISSIONS === 'true';

/** default manager profile when feature flag is off */
const allPermissions: CleverAntAPI.ManagerPermissionsReadPermissionsResponse['response']['apiAccessRights'] =
  [
    {
      apiName: 'Call List',
      accessType: 'EDIT',
    },
    {
      apiName: 'Call List by Employee',
      accessType: 'EDIT',
    },
    {
      apiName: 'Manage Open Shifts',
      accessType: 'EDIT',
    },
    {
      apiName: 'Manage Vacancies',
      accessType: 'EDIT',
    },
    {
      apiName: 'Book On',
      accessType: 'EDIT',
    },
    {
      apiName: 'Submit For Employee',
      accessType: 'EDIT',
    },
    {
      apiName: 'Schedule Editor',
      accessType: 'EDIT',
    },
    {
      apiName: 'Manager Unit Schedule',
      accessType: 'EDIT',
    },
    {
      apiName: 'Row Assignment',
      accessType: 'EDIT',
    },
    {
      apiName: 'Resolve Requests',
      accessType: 'EDIT',
    },
    {
      apiName: 'Resolve Long-Term Leave Requests',
      accessType: 'EDIT',
    },
    {
      apiName: 'Manage Average Hours',
      accessType: 'EDIT',
    },
    {
      apiName: 'Manager Dashboard',
      accessType: 'EDIT',
    },
    {
      apiName: 'Scheduler Dashboard',
      accessType: 'EDIT',
    },
  ];
const managerPermissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    openManagerPermissionsSession: builder.query<
      CleverAntAPI.OpenSessionResponse,
      void
    >({
      ...(enableOpenCaching && { keepUnusedDataFor: keepOpenDataInSeconds }),
      query: () => ({
        url: apiParams.openSessionUrl,
        method: 'POST',
        params: {
          templatename: process.env.REACT_APP_API_MANAGER_PERMISSIONS,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
      transformResponse: (response: CleverAntAPI.OpenResponse) => ({
        caid: response.Open[0].CaId,
        jwt: response.Open[0].Jwt,
      }),
    }),
    readPermissionDetailsMP: builder.query<
      ManagerPermissions.ApiPermissions,
      CleverAntAPI.ManagerPermissionsReadPermissionDetailsInput
    >({
      async queryFn({ jwt, caid }, baseQueryApi, __, fetchBaseQuery) {
        const genericErrorMsg =
          'readPermissions query failed and no error message was found.';

        // if the feature flag is off bypass the api call and return all manager permissions
        if (!enableManagerPermissions) {
          const isEmployee =
            (baseQueryApi.getState() as RootState).session.ukgSession
              .userRole === 'employee';

          if (isEmployee)
            return {
              data: { accessProfileName: 'Employee', apiAccessRights: [] },
            };

          return {
            data: {
              accessProfileName: 'Manager',
              apiAccessRights: allPermissions,
            },
          };
        }
        try {
          const readPermissionsResponse = (await fetchBaseQuery({
            url: apiParams.runActionUrl,
            method: 'POST',
            body: {},
            params: {
              action: 'readPermissionDetails',
              caid,
            },
            headers: {
              Accept: 'application/json',
              Authorization: jwt,
              'Content-Type': 'application/json',
            },
          })) as QueryReturnValue<
            CleverAntAPI.ManagerPermissionsReadPermissionsResponse,
            FetchBaseQueryError,
            {}
          >;
          const isApiError = readPermissionsResponse.error;

          const isEmployee =
            readPermissionsResponse.data?.response?.accessProfileName?.toLowerCase() ===
            'employee';

          if (isApiError) {
            baseQueryApi.dispatch(
              setError([
                'Could not retrieve permissions, application access is limited.',
              ]),
            );
          }

          if (isApiError || isEmployee) {
            return {
              data: { accessProfileName: 'Employee', apiAccessRights: [] },
            };
          }

          return {
            data: readPermissionsResponse.data?.response,
          };
        } catch (error: unknown) {
          return handleUnexpectedApiError(error, genericErrorMsg, baseQueryApi);
        }
      },
      serializeQueryArgs: () => {
        return 'readPermissionDetailsMP'; // omit `caid and jwt` from the cache key
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useOpenManagerPermissionsSessionQuery,
  useLazyOpenManagerPermissionsSessionQuery,
  useReadPermissionDetailsMPQuery,
  useLazyReadPermissionDetailsMPQuery,
} = managerPermissionsApiSlice;
