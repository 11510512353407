import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { KeyboardArrowDownIcon } from '../../../../icons';
import { MobileNavigationMenuProps } from './MobileNavigationMenu.types';
import MobileNavigationMenuItem from '../MobileNavigationMenuItem/MobileNavigationMenuItem';

const MobileNavigationMenu: React.FC<MobileNavigationMenuProps> = props => {
  const { title, groups, onClick } = props;

  const menuItemsToDisplay = groups.flatMap(group => group.groupItems);

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="flex justify-between h-10 w-full px-4 py-2 font-normal text-white focus:outline-none focus:bg-secondary-dark-green hover:bg-secondary-dark-green">
            <p className="text-base font-semibold">{title}</p>
            <KeyboardArrowDownIcon
              className={cn('h-6 w-6 ml-2 fill-white', {
                'rotate-180': open,
              })}
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Menu.Items className="focus-visible:outline-none">
              {menuItemsToDisplay.map((groupItem, index) => {
                const visibility = {
                  visibleOnMobile: true,
                  ...groupItem.visibility,
                };

                if (visibility.visibleOnMobile) {
                  return (
                    <MobileNavigationMenuItem
                      key={`${groupItem.title}${index}`}
                      title={groupItem.title}
                      path={groupItem.path}
                      onClick={onClick}
                    />
                  );
                }

                return null;
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default MobileNavigationMenu;
