import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { uniqBy } from 'lodash';
import {
  ShiftTradeContextState,
  ShiftTradeUpdateBody,
} from './ShiftTradeContext.types';

const ShiftTradeContext = createContext<ShiftTradeContextState | undefined>(
  undefined,
);

/**
 * Manages all common states and logic for the Shift Trade page
 */
function ShiftTradeProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<ShiftTrade.ShiftTradeRequest[]>();
  const [selectedRequestIds, setSelectedRequestIds] = useState<string[]>([]);
  const [selectedShifts, setSelectedShifts] = useState<ShiftTrade.Shift[]>([]);
  const [selectedShiftsByRequester, setSelectedShiftsByRequester] = useState<
    Map<string, ShiftTrade.Shift[]>
  >(new Map());
  const [isIncomingRequests, setIsIncomingRequests] = useState<boolean>(false);

  /** Get the full request objects for all the ids in `selectedRequestIds` */
  const getSelectedRequests = (): ShiftTrade.ShiftTradeRequest[] => {
    return (
      data?.filter(req => selectedRequestIds.includes(req.requestId)) || []
    );
  };

  /** Get the shifts from each of the trade requests with ids in `selectedRequestIds` */
  const getSelectedShifts = (
    _selectedRequests?: ShiftTrade.ShiftTradeRequest[],
  ): ShiftTrade.Shift[] => {
    const selectedRequestsToUse = _selectedRequests || getSelectedRequests();
    let shifts: ShiftTrade.Shift[] = [];
    selectedRequestsToUse.forEach(req => {
      shifts = isIncomingRequests
        ? [...shifts, ...req.tradeInShifts]
        : [...shifts, ...req.tradeOutShifts];
    });
    return shifts;
  };

  /** Get the shifts from each of the incoming trade requests grouped by the requester id */
  const getSelectedShiftsByRequester = (
    _selectedRequests?: ShiftTrade.ShiftTradeRequest[],
  ): Map<string, ShiftTrade.Shift[]> => {
    const selectedRequestsToUse = _selectedRequests || getSelectedRequests();
    const shiftsByRequester = new Map<string, ShiftTrade.Shift[]>();

    selectedRequestsToUse.forEach(req => {
      const { employeeNumber } = req.requester;

      const tradedShifts: ShiftTrade.Shift[] = isIncomingRequests
        ? [...req.tradeInShifts]
        : [...req.tradeOutShifts];

      if (shiftsByRequester.has(employeeNumber)) {
        shiftsByRequester.set(employeeNumber, [
          ...shiftsByRequester.get(employeeNumber)!,
          ...tradedShifts,
        ]);
      } else {
        shiftsByRequester.set(employeeNumber, [...req.tradeInShifts]);
      }
    });

    return shiftsByRequester;
  };

  /** Get the requestId for the request that includes the given shift */
  const getShiftRequestId = (shift: ShiftTrade.Shift): string => {
    const selectedRequests = getSelectedRequests();
    const shiftRequest = selectedRequests.find(req =>
      req.tradeOutShifts.includes(shift),
    );
    return shiftRequest?.requestId || '';
  };

  /** Make the API body for the "update" call (cancel, approve, deny) */
  const makeApiBody = (
    action: ShiftTradeUpdateBody['action'],
  ): ShiftTradeUpdateBody[] => {
    const uniqueArray: ShiftTradeUpdateBody[] = uniqBy(selectedShifts.map((shift: ShiftTrade.Shift) => ({
        requestId: getShiftRequestId(shift),
        action,
      })), 'requestId')

    return uniqueArray;
  };

  useEffect(() => {
    const selectedRequests = getSelectedRequests();

    setSelectedShifts(getSelectedShifts(selectedRequests));
    setSelectedShiftsByRequester(
      getSelectedShiftsByRequester(selectedRequests),
    );
  }, [selectedRequestIds, data]);

  const value = useMemo(() => {
    return {
      makeApiBody,
      data,
      setData,
      selectedShifts,
      setSelectedRequestIds,
      selectedShiftsByRequester,
      setIsIncomingRequests,
    };
  }, [selectedShifts]);

  return (
    <ShiftTradeContext.Provider value={value}>
      {children}
    </ShiftTradeContext.Provider>
  );
}

/**
 * Convenient hook to access ShiftTradeContext value in components
 */
function useShiftTrade() {
  const context = useContext(ShiftTradeContext);
  if (context === undefined) {
    throw new Error('useShiftTrade must be used within a ShiftTradeProvider');
  }
  return context;
}

export { ShiftTradeProvider, useShiftTrade };
