// @ts-nocheck
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parseISO,
  subDays,
} from 'date-fns';
import qs from 'qs';
import axios from 'axios';
import { getEventDuration, getRequestDuration } from '../time';

/**
 * Increase/Decrease number of day depending on the type of PrevNextButton
 * @param {Date} prevDay
 * @param {string} direction - 'previous' or 'next'
 * @param {number} numberOfDays
 */
export const addedDay = (prevDay, direction, numberOfDays) => {
  return add(prevDay, {
    days: direction === 'previous' ? -numberOfDays : numberOfDays,
  });
};

/**
 * Increase/Decrease number of month depending on the type of PrevNextButton
 * @param {Date} prevMonth
 * @param {string} direction - 'previous' or 'next'
 */
export const addedMonth = (prevMonth, direction) => {
  return add(prevMonth, { months: direction === 'previous' ? -1 : 1 });
};

/**
 * Increase/Decrease number of week depending on the type of PrevNextButton
 * @param {Date} prevWeek
 * @param {string} direction - 'previous' or 'next'
 */
export const addedWeek = (prevWeek, direction) => {
  return add(prevWeek, { weeks: direction === 'previous' ? -1 : 1 });
};

/**
 * Congifure the headers in axios API call
 * @param {string} jwt
 * @param {string} paramsAction
 */
export const apiHeaderConfig = (jwt, paramsAction = '') => {
  if (paramsAction !== '') {
    return {
      'Content-Type': 'application/json',
      Authorization: jwt,
    };
  }
  return { Authorization: jwt };
};

/**
 * Object of the authentication credentials
 * @param {string} templateName
 * @param {string} userName
 * @param {string} accessToken
 * @param {Aims.OpenSubmitForEmployeeSessionInput} [delegateParams] - delegate optional parameters.
 */
export const authenticationCredentials = (
  templateName,
  userName,
  accessToken,
  delegateParams = {},
) => {
  return qs.stringify({
    templatename: templateName,
    username: userName,
    password: process.env.REACT_APP_API_PASSWORD,
    serveraddress: process.env.REACT_APP_UKG_SERVER,
    accessToken,
    ...delegateParams,
  });
};

/**
 * Set a range of week
 * @param {Date} selectedWeek
 */
export const daysOfWeekFunc = selectedWeek => {
  return eachDayOfInterval({
    start: selectedWeek,
    end: endOfWeek(selectedWeek),
  });
};

/**
 * Object structure from API call based on the date range
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {string} caid
 * @param {string} jwt
 */
export const dateRangeObject = (startDate, endDate, caid, jwt) => {
  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    caid,
    jwt,
  };
};

/**
 * Format the first letter of the text to uppercase
 * @param {string} value
 */
export const formattedWordFunc = value => {
  const formattedFirstLetter = value.split('')[0].toUpperCase();
  const valArr = value.split('');
  valArr.splice(0, 1, formattedFirstLetter);
  const formattedVal = valArr.join('');
  return formattedVal;
};

/**
 * Get all of the events of the selected day in the calendar
 * @param {array} events
 * @param {string} date
 */
export const getEventsOfDay = (events, date) => {
  return events.filter(obj =>
    isSameDay(parseISO(obj.startDateTime), parseISO(date)),
  );
};

/**
 * Get the value for the 'grid-row' to update styling for Tailwind utility class
 * @param {object} event
 */
export const getGridRowValue = event => {
  const gridRowStart =
    (parseISO(event.startDateTime).getMinutes() / 60 +
      parseISO(event.startDateTime).getHours()) *
      12 +
    2;
  const gridRowEnd = getEventDuration(event) * 12;

  return `${Math.round(gridRowStart)} / span ${Math.round(gridRowEnd)}`;
};

/**
 * Get formatted date or date range of request
 * @param {startDateTime} string
 * @param {endDateTime} string
 */
export const getRequestDate = (startDateTime, endDateTime) => {
  try {
    const dayInHours = 24;
    if (isSameDay(parseISO(startDateTime), parseISO(endDateTime))) {
      return format(parseISO(startDateTime), 'EEE, MMM dd, yyyy');
    }
    // check if it is an overnight request
    // and display it accordingly
    const startDateFmt = format(parseISO(startDateTime), 'EEE, MMM dd, yyyy');
    let endDateFmt = format(parseISO(endDateTime), 'EEE, MMM dd, yyyy');
    const requestDuration = +getRequestDuration({ startDateTime, endDateTime });
    if (requestDuration > dayInHours) {
      const previous = subDays(parseISO(endDateTime), 1);
      endDateFmt = format(previous, 'EEE, MMM dd, yyyy');
    }
    return `${startDateFmt} - ${endDateFmt}`;
  } catch (error) {
    return '';
  }
};

/**
 * Get rounded number
 * @param {number} number
 */
export const getRoundedNumber = number => {
  const roundedDecimal = Math.round((number % 1) * 100) / 100;
  return Math.floor(number) + roundedDecimal;
};

/**
 * Check if the array of month length
 * @param {number} monthLength
 */
export const isMonthSixWeeks = monthLength => monthLength > 35;

/**
 * Update month if the previous month and the updated month are not the same month
 * @param {Date} prevMonth
 * @param {string} direction - 'previous' or 'next'
 * @param {Date} selectedMonth
 */
export const newMonthChange = (prevMonth, direction, selectedMonth) => {
  let newMonth;
  if (direction === 'previous') {
    newMonth = isSameMonth(prevMonth, selectedMonth) ? -1 : 0;
  } else if (direction === 'next') {
    newMonth = isSameMonth(prevMonth, selectedMonth) ? 1 : 0;
  }
  return newMonth;
};

/**
 * Axios API call with 'post' request
 * @param {string} url
 * @param {string | null} data
 * @param {object} params
 * @param {object} headers
 */
export const postData = async (url, payLoad, params = {}, headers = {}) => {
  const response = await axios({
    method: 'post',
    url,
    data: payLoad,
    params,
    headers,
  });
  return response;
};

/**
 * Sorted an array of events function
 * @param {array} eventArray
 */
export const sortedEvents = eventArray =>
  eventArray.sort(
    (event1, event2) =>
      new Date(event1.startDateTime) - new Date(event2.startDateTime),
  );

/**
 * Leave Overview Section Validator
 * @param {dataObject} Object
 * @param {leaveOverViewSection} Object
 * @param {key} string
 */

export const leaveOverViewSectionValidator = (
  dataObject,
  leaveOverViewSection,
  key,
) => {
  let returnVal = null;
  const keyElement = leaveOverViewSection[key];
  if (dataObject && dataObject[keyElement]) {
    returnVal = dataObject[keyElement];
  }
  return returnVal;
};

/**
 * GetTotalHrsSummation
 * @param {dataObject} Object
 * @param {leaveOverViewSection} Object
 * @param {key} string
 */

export const getTotalHrsSummation = selectedAccruals => {
  let returnVal = null;
  const totalHrs = selectedAccruals
    ? selectedAccruals.reduce(
        (accumulator, obj) => accumulator + getRoundedNumber(obj.balance),
        0,
      )
    : 0;
  returnVal = getRoundedNumber(totalHrs);
  return returnVal;
};

export const capitalizeFirstLetter = str => {
  return str[0].toUpperCase() + str.slice(1);
};

/**
* GetTotalBalance (used in the new Request Leave / Time Off Page)
* @param {selectedAccruals} array
*/
export const getTotalBalance = selectedAccruals => {
  const totalHrs = selectedAccruals
    ? selectedAccruals.reduce(
      (accumulator, obj) => { 
        if (obj?.balance) {
          accumulator = (accumulator === undefined) 
            ? getRoundedNumber(obj.balance) 
            : accumulator + getRoundedNumber(obj.balance);
        }
        return accumulator;
      },
      undefined
    )
    : null;
  
  const returnVal = totalHrs ? getRoundedNumber(totalHrs) : '--';
  return returnVal;
};

/**
 * Normalize String
 * @param {string} str
 */
export const normalizeString = str => str.replace(/\s/g, '').toLowerCase();

/**
 * Check if string exists in array
 * @param {array} arr
 * @param {string} expectedStr
 */
export const checkStringInArray = (arr, expectedStr) => {
  const formattedArray = arr.filter(item =>
    normalizeString(item).includes(normalizeString(expectedStr)),
  );
  return formattedArray.length === 1;
};
