import { StatePillVariants } from '../components/historical-add-edit/StatePill/StatePill.types';
import {
  StatusBadgeStatus,
  StatusBadgeVariants,
} from '../components/common/StatusBadge/StatusBadge.types';
import {
  RequestDenyReasons,
  RequestDenyReasonsValues,
  TapApproveReasons,
  TradesRequestDenyReasonsValues,
} from '../components/resolve-requests/ResolveRequestsTable/ResolveRequestsTable.types';
import { SelectOption } from '../components/common/Select/Select.types';

/**
 * These breakpoints match Tailwind config.
 * For use in JS (e.g. with the useWindowSize hook)
 */
export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
} as const;

/**
 * Map string value statuses to StatusBadge use
 * @docs API status strings: https://deloittedigital.atlassian.net/wiki/spaces/AUW/pages/3088121880/Status+Index
 */
export const REQUEST_STATUSES: { [key: StatusBadgeStatus]: any } = {
  'Action Needed': {
    variant: StatusBadgeVariants.ACTION,
    label: 'Action Needed',
  },
  Approved: {
    variant: StatusBadgeVariants.SUCCESS,
    label: 'Approved',
  },
  'Avg. Hrs Required': {
    variant: StatusBadgeVariants.ERROR,
    label: 'Avg. Hrs Required',
  },
  'Pending Manage Avg Hours': {
    variant: StatusBadgeVariants.ERROR,
    label: 'Avg. Hrs Required',
  },
  Cancelled: {
    variant: StatusBadgeVariants.WARNING,
    label: 'Cancelled',
  },
  'Cancel Submitted': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Pending',
  },
  'Cancel Submitted MAH': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Cancel Submitted',
  },
  Open: {
    variant: StatusBadgeVariants.OPEN,
    label: 'Open',
  },
  Pending: {
    variant: StatusBadgeVariants.WARNING,
    label: 'Pending',
  },
  'Pending Immediate Need': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Pending',
  },
  'Processing Rules': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Pending',
  },
  Refused: {
    variant: StatusBadgeVariants.ERROR,
    label: 'Rejected',
  },
  'Refused Premium Historic': {
    variant: StatusBadgeVariants.ERROR,
    label: 'Refused',
  },
  Submitted: {
    variant: StatusBadgeVariants.WARNING,
    label: 'Submitted',
  },
  'Submitted MAH': {
    variant: StatusBadgeVariants.SUCCESS,
    label: 'Submitted',
  },
  'Submitted Shift Bid': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Submitted',
  },
  'Submitted Premium Historic': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Submitted',
  },
  'Submitted to Manager': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Pending',
  },
  Withdrawn: {
    variant: StatusBadgeVariants.LIGHT,
    label: 'Withdrawn',
  },
  'Offering Regular': {
    variant: StatusBadgeVariants.OPEN,
    label: 'Offering Regular',
  },
  'Offering Overtime': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Offering Overtime',
  },
  'Bid Regular': {
    variant: StatusBadgeVariants.OPEN,
    label: 'Bid Regular',
  },
  'Bid Overtime': {
    variant: StatusBadgeVariants.WARNING,
    label: 'Bid Overtime',
  },
  RNN: {
    variant: StatusBadgeVariants.LIGHT,
    label: 'RNN',
  },
  Posted: {
    variant: StatusBadgeVariants.SUCCESS,
    label: 'Posted',
  },
  Vacant: {
    variant: StatusBadgeVariants.WARNING,
    label: 'Vacant',
  },
  '': {
    badge: '',
    dot: '',
    label: '',
  },
};

export const STATE_PILL_VARIANTS: {
  [key: StatePillVariants]: { bg_color: string; text_color: string };
} = {
  'New Historic Time': {
    bg_color: 'bg-[#BEE3F8]',
    text_color: 'text-[#083C68]',
  },
  'Confirmed Paycode': {
    bg_color: 'bg-purpel-200',
    text_color: 'text-neutral-grey-800',
  },
  'Confirmed Shift': {
    bg_color: 'bg-green-200',
    text_color: 'text-neutral-grey-800',
  },
  'Pending Request': {
    bg_color: 'bg-warning-600',
    text_color: 'text-[#713F12]',
  },
  Fallback: {
    bg_color: 'bg-neutral-grey-200',
    text_color: 'text-neutral-grey-800',
  },
};

/**
 * Params object with action values
 * For use in API call
 */
export const PARAMSACTIONVALUE = {
  initialize: 'initialize',
  loadSchedule: 'loadSchedule',
  submitRequests: 'submitRequests',
  readEmpSchedule: 'readEmpSchedule',
};

export const REQUEST_TIME_OFF_TYPES = ['planned', 'payout', 'unavailable'];

export const REQUEST_LEAVE_TYPES: TimeManagement.RequestLeaveTypes = {
  unplanned: 'unplanned',
  unplannedAA: 'unplanned-aa',
  longTerm: 'long-term',
  historicalEditRequest: 'historical edit request',
  premiumRequest: 'premium request',
  timecardException: 'timecard exception',
};

/**
 * Deny options for Request Action Modal
 */
export const REQUEST_DENY_REASONS: SelectOption[] = [
  {
    id: 0,
    text: RequestDenyReasons.doesNotQualify,
    value: RequestDenyReasonsValues.doesNotQualify,
  },
  {
    id: 1,
    text: RequestDenyReasons.contactManager,
    value: RequestDenyReasonsValues.contactManager,
  },
  {
    id: 2,
    text: RequestDenyReasons.maxNamedReplacementPerYear,
    value: RequestDenyReasonsValues.maxNamedReplacementPerYear,
  },
];

/**
 * Deny options for Trades Request Action Modal
 */
export const TRADES_REQUEST_DENY_REASONS: SelectOption[] = [
  {
    id: 0,
    text: RequestDenyReasons.doesNotQualify,
    value: TradesRequestDenyReasonsValues.doesNotQualify,
  },
  {
    id: 1,
    text: RequestDenyReasons.contactManager,
    value: TradesRequestDenyReasonsValues.contactManager,
  },
  {
    id: 2,
    text: RequestDenyReasons.maxNamedReplacementPerYear,
    value: TradesRequestDenyReasonsValues.maxNamedReplacementPerYear,
  },
];

/**
 * Approve options for Request Action Modal
 */
export const REQUEST_APPROVE_REASONS: SelectOption[] = [
  {
    id: 1,
    text: TapApproveReasons.payTap,
    value: TapApproveReasons.payTap,
  },
  {
    id: 2,
    text: TapApproveReasons.paySchedule,
    value: TapApproveReasons.paySchedule,
  },
];

/**
 * Constant used for leave overview section data mapping
 */
export const LEAVE_OVERVIEW_SECTION = {
  'Hospital Location': 'location',
  Department: 'dept',
  Job: 'job',
};

/**
 * Constant used for accrual table headers
 */
export const ACCRUAL_TABLE_HEADERS = ['Accrued Type', 'Accrued Balance'];

/**
 * Constant used for vacation leave balance table headers
 */
export const VACATION_LEAVE_BALANCE_TABLE_HEADERS = ['Accrued Type', 'Balance'];

/**
 * Constant used for addtional questions overview data mapping
 */
export const ADDITIONAL_QUESTIONS_OVERVIEW_SECTION = {
  'Hospital Location': 'location',
  Department: 'dept',
  Job: 'job',
  'Date Range': 'date',
  Reason: 'reason',
};

/**
 * Constant used for RTKQ tags
 */
export const RTKQ_TAGS = {
  timeManagementRequests: 'TimeManagementRequests',
  tapExceptions: 'TapExceptions',
  longTermLeaveRequests: 'LongTermLeaveRequests',
  shiftBid: 'ShiftBid',
  resolveRequests: 'ResolveRequests',
  shiftTrade: 'ShiftTrade',
  premiumRequest: 'PremiumRequest',
  manageOpenShifts: 'ManageOpenShifts',
  requestHistoricalEdits: 'RequestHistoricalEdits',
  manageVacancies: 'ManageVacancies',
  scheduleEditor: 'ScheduleEditor',
  bookOn: 'BookOn',
  rowAssignment: 'RowAssignment',
  callListByEmployee: 'CallListByEmployee',
  callList: 'CallList',
  requestAbsence: 'RequestAbsence',
};

/**
 * Threshold in hours for which a request will create an open shift
 */
export const OPEN_SHIFT_HOUR_THRESHOLD = 3;

/**
 * Constant used for user roles
 */
export const USER_ROLES = {
  manager: 'manager',
  employee: 'employee',
};

export const CALENDAR_VIEWS = ['day', 'week', 'month'] as const;

/** dates before this value should not be allowed in features like historicEdit */
export const GO_LIVE_DATE_STRING = '2023-11-19';
