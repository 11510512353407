import { createSlice } from '@reduxjs/toolkit';
import { NotificationProps } from '../../components/common/Notification/Notification.types';

export type ReduxNotification = {
  id?: string;
  message: string;
  props: Pick<NotificationProps, 'type'> &
    Partial<Pick<NotificationProps, 'hasTimeout' | 'ariaLabel'>>;
};
export type NotificationsInitialState = {
  notifications: Required<ReduxNotification>[];
};

const initialState: NotificationsInitialState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, { payload }: { payload: ReduxNotification }) => {
      state.notifications.push({
        id: payload.id !== undefined ? payload.id : crypto.randomUUID(),
        message: payload.message,
        props: {
          ...payload.props,
          hasTimeout:
            /** By default, success notification has timeout */
            payload.props.hasTimeout !== undefined
              ? payload.props.hasTimeout
              : payload.props.type === 'success',
        },
      });
    },
    removeNotification: (
      state,
      { payload: idToRemove }: { payload: string },
    ) => {
      state.notifications = state.notifications.filter(
        n => n.id !== idToRemove,
      );
    },
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
