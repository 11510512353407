// @ts-nocheck
/* eslint-disable react/button-has-type */
import { cloneElement } from 'react';
import { func, node, oneOf, string, bool } from 'prop-types';
import cn from 'classnames';

/**
 * Button
 * @description Renders a styled button (with optional icons left &/or right)
 * according to UI kit sepcifications.
 */
function Button(props) {
  const {
    iconLeft,
    iconRight,
    onClick,
    size,
    text,
    type,
    variant,
    isDisabled,
    customizedClassName,
  } = props;
  const smallLink = size === 'small' && variant === 'link';
  const defaultLink = size === 'default' && variant === 'link';
  const extraSmallPlain = size === 'x-small' && variant === 'plain';
  const iconProps = {
    className: cn('aims-iconfix text-2xl', {
      'text-xl': smallLink || extraSmallPlain,
    }),
    'aria-hidden': 'true',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={cn(
        `aims-btn`,
        {
          'aims-btn-primary': variant === 'primary',
          'aims-btn-secondary': variant === 'secondary',
          'aims-btn-link': variant === 'link',
          'aims-btn-link px-4 py-2': defaultLink,
          'aims-btn-plain': variant === 'plain',
          'aims-btn-large': size === 'large',
          'aims-btn-small': size === 'small',
          'aims-btn-extra-small': size === 'x-small',
          'text-xs aims-btn-link': smallLink,

          'border-neutral-grey-200': isDisabled,
        },
        customizedClassName,
      )}
    >
      <div className="inline-flex gap-2 items-center mx-auto">
        {iconLeft && cloneElement(iconLeft, iconProps)}
        {text}
        {iconRight && cloneElement(iconRight, iconProps)}
      </div>
    </button>
  );
}

Button.propTypes = {
  iconLeft: node,
  iconRight: node,
  onClick: func.isRequired,
  size: oneOf(['large', 'default', 'small', 'x-small']),
  text: string.isRequired,
  type: oneOf(['button', 'submit', 'reset']),
  variant: oneOf(['primary', 'secondary', 'link', 'plain']),
  isDisabled: bool,
  customizedClassName: string,
};

Button.defaultProps = {
  iconLeft: null,
  iconRight: null,
  size: 'large',
  type: 'button',
  variant: 'primary',
  isDisabled: false,
  customizedClassName: '',
};

export default Button;
