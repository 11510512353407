// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: {
    items: {
      positions: [],
      accruals: [],
      payoutDropdown: [],
      plannedDropdown: [],
      requests: [],
      schedule: [],
    },
  },
  formData: [],
  orgId: null,
  loading: false,
  error: null,
};

export const timeOffRequestSlice = createSlice({
  name: 'timeOffRequest',
  initialState,
  reducers: {
    updateOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    updateFormData: (state, action) => {
      state.formData = action.payload;
    },
    clearFormData: state => {
      state.formData = [];
    },
    fetchTimeOffRequestDetails: state => {
      state.error = null;
      state.loading = true;
    },
    fetchTimeOffRequestDetailsSuccess: (state, action) => {
      state.list.items = action.payload;
      state.loading = false;
    },
    fetchTimeOffRequestDetailsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    submitTimeOffRequest: (state, action) => {
      state.loading = true;
      action.payload;
    },
    submitTimeOffRequestSuccess: state => {
      state.loading = false;
    },
    submitTimeOffRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  updateOrgId,
  updateFormData,
  clearFormData,
  fetchTimeOffRequestDetails,
  fetchTimeOffRequestDetailsSuccess,
  fetchTimeOffRequestDetailsFailure,
  submitTimeOffRequest,
  submitTimeOffRequestSuccess,
  submitTimeOffRequestFailure,
} = timeOffRequestSlice.actions;
export default timeOffRequestSlice.reducer;
