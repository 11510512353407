import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import '@mdi/font/css/materialdesignicons.min.css';
import '@fontsource/source-sans-3';

import './index.css';
import { registerLicense } from '@syncfusion/ej2-base';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import worker from './mocks/browser';
import AppWithInactivity from './App/AppWithInactivity';

// TODO: move to App component
const enableMockServer = () => {
  if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
    worker.start({
      onUnhandledRequest(req) {
        // ignore some console warnings e.g. for favicon and lazy loaded chunks
        if (req.url.origin !== 'http://localhost:3000')
          // eslint-disable-next-line no-console
          console.error(
            'Found an unhandled %s request to %s',
            req.method,
            req.url.href,
          );
      },
    });
  } else if (process.env.REACT_APP_DEPLOY_ENV === 'development') {
    worker.start({
      serviceWorker: {
        url: `${process.env.REACT_APP_DEV_SERVER_DEPLOY_PATH}/mockServiceWorker.js`,
      },
      onUnhandledRequest: 'bypass', // don't print console warnings in dev
    });
  }
};
enableMockServer();

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

const useInactivity =
  process.env.REACT_APP_BETA_FEATURE_USER_INACTIVTY === 'true';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {useInactivity ? <AppWithInactivity /> : <App />}
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
