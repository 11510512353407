// @ts-nocheck
import { rtoRunLoadSchedule, rtoSubmitSchedule } from './rto/api';

export const fetchRTOdetailsFromApi = async ({
  startDate,
  endDate,
  caid,
  jwt,
  accessToken,
}) => {
  const rtoRunLoadScheduleResp = await rtoRunLoadSchedule({
    caid,
    jwt,
    startDate,
    endDate,
    accessToken,
  });
  return rtoRunLoadScheduleResp;
};

export const SubmitRto = ({ events, caid, jwt, accessToken }) => {
  const response = rtoSubmitSchedule({
    payload: events,
    caid,
    jwt,
    accessToken,
  });
  return response;
};
