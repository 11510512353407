import React, { createContext, useContext, useState, useMemo } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import {
  NetworkErrorNotification,
  PageNotification,
  ShiftTradeRequestPageNotificationState,
  ShiftTradeRequestContextState,
} from './ShiftTradeRequestContext.types';
import { SelectOption } from '../../../common/Select/Select.types';

const defaultStartDate = new Date();
const defaultEndDate = addDays(defaultStartDate, 13);
const defaultDateRange = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
};

const ShiftTradeRequestContext = createContext<
  ShiftTradeRequestContextState | undefined
>(undefined);

export const ShiftTradeRequestProvider: React.FC<{
  children: React.ReactNode;
}> = props => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(false);
  const showLoadingSpinner = (isPageLoading: boolean) => {
    setIsLoading(isPageLoading);
  };

  /**
   * NOTIFICATIONS
   */

  /** State & handler for showing network errors */
  const [networkErrorNotifications, setNetworkErrorNotifications] = useState<
    NetworkErrorNotification[]
  >([]);

  const showNetworkErrorNotification = (
    error: FetchBaseQueryError | SerializedError | undefined,
  ) => {
    setNetworkErrorNotifications(prevState => {
      return [...prevState, error];
    });
  };

  /** State & handler for showing any other page notification on ShiftTradeRequest page */
  const [pageNotification, setPageNotification] =
    useState<ShiftTradeRequestPageNotificationState>({
      isShowingNotification: false,
      type: 'success',
      message: undefined,
    });
  const showPageNotification = (notification: PageNotification) => {
    setPageNotification({ ...notification, isShowingNotification: true });
  };

  const dismissPageNotification = () => {
    setPageNotification(prevState => {
      return { ...prevState, isShowingNotification: false };
    });
  };

  /**
   * TRADE OUT
   */

  const [employeeNumber, setEmployeeNumber] = useState<string>('');

  /** State & handlers for selected shift to trade out (currently we have only one shift, but in future this state will be converted into array) */
  const [selectedTradeOutShifts, setSelectedTradeOutShifts] = useState<
    ShiftTrade.Shift[]
  >([]);

  const addTradeOutShift = (shiftToTradeOut: ShiftTrade.Shift) => {
    setSelectedTradeOutShifts(prevSelectedShifts => [
      ...prevSelectedShifts,
      shiftToTradeOut,
    ]);
  };

  const removeTradeOutShift = (shifIdToRemove: string) => {
    setSelectedTradeOutShifts(prevSelectedShifts =>
      prevSelectedShifts.filter(shift => shift.shiftId !== shifIdToRemove),
    );
  };

  const clearTradeOutShifts = () => {
    setSelectedTradeOutShifts([]);
  };

  /** State variable for all available location options */
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const addLocationOptions = (locationOpts: SelectOption[]) => {
    setLocationOptions(locationOpts);
  };

  /** State variable for all available job options */
  const [jobOptions, setJobOptions] = useState<SelectOption[]>([]);
  const addJobOptions = (jobOpts: SelectOption[]) => {
    setJobOptions(jobOpts);
  };

  /** State variable for selected location */
  const [selectedLocation, setSelectedLocation] = useState('');
  const addSelectedLocation = (sLoc: string) => {
    setSelectedLocation(sLoc);
  };

  /** State variable for selected job */
  const [selectedJob, setSelectedJob] = useState('');
  const addSelectedJob = (sJob: string) => {
    setSelectedJob(sJob);
  };

  /** State variable for current date range to fetch coworker's schedule for */
  const defaultEmpScheduleDateRange = defaultDateRange;
  const [empScheduleDateRange, setEmpScheduleDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>(defaultDateRange);
  const addEmpScheduleDateRange = (startDate: Date, endDate: Date) => {
    setEmpScheduleDateRange({
      startDate,
      endDate,
    });
  };

  /**
   * TRADE IN
   */

  /** State variable for all available coworker options */
  const [coworkerOptions, setCoworkerOptions] = useState<SelectOption[]>([]);
  const addCoworkerOptions = (coworkerOpts: SelectOption[]) => {
    setCoworkerOptions(coworkerOpts);
  };

  /** State variable for selected coworker */
  const [selectedCoworker, setSelectedCoworker] = useState('');
  const addSelectedCoworker = (sCoworker: string) => {
    setSelectedCoworker(sCoworker);
  };

  /** State & handlers for selected shift to trade in (currently we have only one shift, but in future this state will be converted into array) */
  const [selectedTradeInShifts, setSelectedTradeInShifts] = useState<
    ShiftTrade.Shift[]
  >([]);

  const addTradeInShift = (shiftToTradeIn: ShiftTrade.Shift) => {
    setSelectedTradeInShifts(prevSelectedShifts => [
      ...prevSelectedShifts,
      shiftToTradeIn,
    ]);
  };

  const removeTradeInShift = (shifIdToRemove: string) => {
    setSelectedTradeInShifts(prevSelectedShifts =>
      prevSelectedShifts.filter(shift => shift.shiftId !== shifIdToRemove),
    );
  };

  const clearTradeInShifts = () => {
    setSelectedTradeInShifts([]);
  };

  /** State variable for current date range to fetch coworker's schedule for */
  const [coworkerScheduleDateRange, setCoworkerScheduleDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>(defaultDateRange);

  const addCoworkerScheduleDateRange = (startDate: Date, endDate: Date) => {
    setCoworkerScheduleDateRange({
      startDate,
      endDate,
    });
  };

  /** State variable to show if there is a successful name replacement request  */
  const [
    isShowingTradeInAsNameReplacement,
    setIsShowingTradeInAsNameReplacement,
  ] = useState(false);

  const showTradeInAsNameReplacement = () => {
    setIsShowingTradeInAsNameReplacement(true);
  };

  const removeTradeInAsNameReplacement = () => {
    setIsShowingTradeInAsNameReplacement(false);
  };

  /** State variable to show if we are trying to request name replacement */
  const [isRequestingNameReplacement, setIsRequestingNameReplacement] =
    useState(false);

  const handleRequestingNameReplacement = (isRequesting: boolean) => {
    setIsRequestingNameReplacement(isRequesting);
  };

  const providerValues = useMemo(() => {
    return {
      isLoading,
      showLoadingSpinner,

      employeeNumber,
      setEmployeeNumber,

      locationOptions,
      addLocationOptions,

      jobOptions,
      addJobOptions,

      selectedLocation,
      addSelectedLocation,

      selectedJob,
      addSelectedJob,

      empScheduleDateRange,
      addEmpScheduleDateRange,

      coworkerOptions,
      addCoworkerOptions,

      selectedCoworker,
      addSelectedCoworker,

      defaultEmpScheduleDateRange,
      coworkerScheduleDateRange,
      addCoworkerScheduleDateRange,

      selectedTradeOutShifts,
      addTradeOutShift,
      removeTradeOutShift,
      clearTradeOutShifts,

      selectedTradeInShifts,
      addTradeInShift,
      removeTradeInShift,
      clearTradeInShifts,

      networkErrorNotifications,
      showNetworkErrorNotification,

      pageNotification,
      showPageNotification,
      dismissPageNotification,

      isShowingTradeInAsNameReplacement,
      showTradeInAsNameReplacement,
      removeTradeInAsNameReplacement,

      isRequestingNameReplacement,
      handleRequestingNameReplacement,
    };
  }, [
    isLoading,
    locationOptions,
    jobOptions,
    selectedLocation,
    selectedJob,
    empScheduleDateRange,
    coworkerOptions,
    selectedCoworker,
    coworkerScheduleDateRange,
    selectedTradeOutShifts,
    selectedTradeInShifts,
    networkErrorNotifications,
    pageNotification,
    isRequestingNameReplacement,
    isShowingTradeInAsNameReplacement,
  ]);

  return (
    <ShiftTradeRequestContext.Provider value={providerValues}>
      {children}
    </ShiftTradeRequestContext.Provider>
  );
};

export const useShiftTradeRequest = () => {
  const context = useContext(ShiftTradeRequestContext);
  if (context === undefined) {
    throw new Error(
      'useShiftTradeRequest must be used within a ShiftTradeRequestProvider',
    );
  }
  return context;
};
