import React, { createContext, useContext, useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import {
  NetworkErrorNotification,
  PageNotification,
  ScheduleEditorShiftManagementContextState,
  ScheduleEditorShiftManagementPageNotificationState,
} from './ScheduleEditorShiftManagementContext.types';
import { SelectOption } from '../../common/Select/Select.types';
import { EditedOpenShift } from '../../manage-open-shifts/manageOpenShifts.types';
import { UserActions } from '../../call-list/CallList.types';

import {
  UpdateCallListActionsCLBE,
  UpdateEmployeeActionsCLBE,
  UpdateFilterValidationCLBE,
} from '../../call-list-by-employee/CallListByEmployee.types';

const ScheduleEditorShiftManagementContext = createContext<
  ScheduleEditorShiftManagementContextState | undefined
>(undefined);

export const ScheduleEditorShiftManagementProvider: React.FC<{
  children: React.ReactNode;
}> = props => {
  const { children } = props;

  /**
   * a boolean flag to set when navigating to a tab.
   * this will differentiate renders so that switching tabs
   * will not re-trigger and duplicate notifications
   */
  const [wasTabClicked, setTabClicked] = useState<boolean>(false);
  const setWasTabClicked = (tabClicked: boolean) => {
    setTabClicked(tabClicked);
  };
  /* all open shifts on manage open shifts tab  */
  const [allOpenShiftsData, setAllOpenShiftsData] =
    useState<ManageOpenShifts.LoadOpenShiftsResponse>({});

  const updateAllOpenShiftsData = (
    newShifts: ManageOpenShifts.LoadOpenShiftsResponse,
  ) => {
    setAllOpenShiftsData(newShifts);
    setWasTabClicked(false);
  };

  /* edited shifts on manage open shifts tab  */
  const [editedShiftsData, setEditedShiftsData] = useState<EditedOpenShift[]>(
    [],
  );

  const updateEditedShiftData = (newEdits: EditedOpenShift[]) => {
    setEditedShiftsData(newEdits);
  };

  /* call list user actions on call list tab */
  const [callListUserActions, setCallListUserActions] = useState<UserActions>(
    new Map(),
  );

  const updateCallListUserActions = (actions: UserActions) => {
    setCallListUserActions(actions);
  };

  /** call list selections */
  const [CLselectedShift, setCLselectedShift] =
    useState<CallList.OpenShift | null>(null);

  const updateCLselectedShift = (
    newSelectedShift: CallList.OpenShift | null,
  ) => {
    setCLselectedShift(newSelectedShift);
  };
  const [selectedCallListType, setSelectedCallListType] = useState<
    string | null
  >(null);

  const updateSelectedCallListType = (
    newSelectedCallListType: string | null,
  ) => {
    setSelectedCallListType(newSelectedCallListType);
  };
  /* unsaved data at page level */
  const [pageHasUnsavedChanges, setPageHaveUnsavedChanges] = useState(false);

  const updatePageHasUnsavedChanges = (hasUnsavedChanges: boolean) => {
    setPageHaveUnsavedChanges(hasUnsavedChanges);
  };

  /* initialize data */
  const [initializeData, setInitializeData] = useState<
    | ManageOpenShifts.InitializeResponse
    | CallListByEmployee.CallListByEmployeeInitializeResponse
    | undefined
  >(undefined);

  const addInitializeData = (
    dataToAdd:
      | ManageOpenShifts.InitializeResponse
      | CallListByEmployee.CallListByEmployeeInitializeResponse,
  ) => {
    setInitializeData(dataToAdd);
  };

  /* Loc/Dept/Job */
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const addLocationOptions = (locOptionsToAdd: SelectOption[]) => {
    setLocationOptions(locOptionsToAdd);
  };

  const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>(
    [],
  );
  const addDepartmentOptions = (deptOptionsToAdd: SelectOption[]) => {
    setDepartmentOptions(deptOptionsToAdd);
  };

  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const addRoleOptions = (roleOptionsToAdd: SelectOption[]) => {
    setRoleOptions(roleOptionsToAdd);
  };

  /* Loc/Dept/Role */
  const [selectedLocation, setLocationValue] = useState<string>('');
  const addSelectedLocation = (sLoc: string) => {
    setLocationValue(sLoc);
  };

  const [selectedDepartment, setDepartmentValue] = useState<string>('');
  const addSelectedDepartment = (sLoc: string) => {
    setDepartmentValue(sLoc);
  };

  const [selectedRole, setRoleValue] = useState<string>('');
  const addSelectedRole = (sLoc: string) => {
    setRoleValue(sLoc);
  };

  const [orgId, setOrgId] = useState<string>('');
  const addOrgId = (id: string) => {
    setOrgId(id);
  };

  /* Start and end date picker */
  const [startDate, setStartDate] = useState<Date>(new Date());
  const updateStartDate = (newDate: Date) => {
    setStartDate(newDate);
  };

  const [endDate, setEndDate] = useState<Date>(addDays(startDate, 6));
  const updateEndDate = (newDate: Date) => {
    setEndDate(newDate);
  };

  /* Call List by employee */
  const [callListActionsCLBE, setCallListActionsCLBE] = useState<{
    options: SelectOption[];
    selection: string;
  }>(Object);
  const updateCallListActionsCLBE = (
    newStateOptions: UpdateCallListActionsCLBE,
  ) => {
    const currState = callListActionsCLBE;
    const newState = {
      options: newStateOptions.options ?? currState.options,
      selection: newStateOptions.selection ?? currState.selection,
    };

    setCallListActionsCLBE(newState);
  };

  const [employeeActionsCLBE, setEmployeeActionsCLBE] = useState<{
    options: SelectOption[];
    employees: CallListByEmployee.Employee[];
    selection: CallListByEmployee.Employee;
    selectedEmployeeSchedule: CallListByEmployee.Schedule[];
    skills: CallListByEmployee.Skills;
  }>(Object);
  const updateEmployeeActionsCLBE = (
    newStateOptions: UpdateEmployeeActionsCLBE,
  ) => {
    const currState = employeeActionsCLBE;
    const newState = {
      options: newStateOptions.options ?? currState.options,
      employees: newStateOptions.employees ?? currState.employees,
      selection: newStateOptions.selection ?? currState.selection,
      selectedEmployeeSchedule:
        newStateOptions.selectedEmployeeSchedule ??
        currState.selectedEmployeeSchedule,
      skills: newStateOptions.skills ?? [],
    };

    setEmployeeActionsCLBE(newState);
  };

  // CLBE filter validation
  const [filterValidationCLBE, setFilterValidationCLBE] = useState({
    callListSelection: true,
    employeeSelection: true,
  });
  const updateFilterValidationCLBE = (
    newStateOptions: UpdateFilterValidationCLBE,
  ) => {
    // const currState = filterValidationCLBE;
    const newState = {
      callListSelection: newStateOptions.callListSelection,
      employeeSelection: newStateOptions.employeeSelection,
    };
    setFilterValidationCLBE(newState);
  };

  /** CL selected open shifts from schedule editor */
  const [SEselectedOpenShifts, setSEselectedOpenShifts] = useState<any[]>();
  const updateSEselectedOpenShifts = (newOpenShifts: any[]) => {
    setSEselectedOpenShifts(newOpenShifts);
  };

  /**
   * NOTIFICATIONS
   */

  /** State & handler for showing network errors */
  const [networkErrorNotifications, setNetworkErrorNotifications] = useState<
    NetworkErrorNotification[]
  >([]);

  const showNetworkErrorNotification = (
    error: FetchBaseQueryError | SerializedError | undefined,
  ) => {
    setNetworkErrorNotifications(prevState => {
      return [...prevState, error];
    });
  };

  /** State & handler for showing any other page notification on the shift management page */
  const [pageNotification, setPageNotification] = useState<
    ScheduleEditorShiftManagementPageNotificationState[]
  >([]);

  const showPageNotification = (
    notification: PageNotification | PageNotification[],
  ) => {
    if (Array.isArray(notification)) {
      const notificationCopy = notification.map(n => ({
        ...n,
        id: crypto.randomUUID(),
      }));
      setPageNotification(prevState => {
        return [...prevState, ...notificationCopy];
      });
    } else {
      const notificationCopy = {
        ...notification,
        id: crypto.randomUUID(),
      };
      setPageNotification(prevState => {
        return [...prevState, notificationCopy];
      });
    }
  };

  const dismissPageNotification = (
    notificationToDismiss: ScheduleEditorShiftManagementPageNotificationState,
  ) => {
    setPageNotification(prevState => {
      return prevState.filter(n => n.id !== notificationToDismiss.id);
    });
  };

  const providerValue = useMemo(() => {
    return {
      allOpenShiftsData,
      updateAllOpenShiftsData,

      editedShiftsData,
      updateEditedShiftData,

      callListUserActions,
      updateCallListUserActions,

      pageHasUnsavedChanges,
      updatePageHasUnsavedChanges,

      initializeData,
      addInitializeData,

      locationOptions,
      addLocationOptions,
      departmentOptions,
      addDepartmentOptions,
      roleOptions,
      addRoleOptions,

      selectedLocation,
      addSelectedLocation,
      selectedDepartment,
      addSelectedDepartment,
      selectedRole,
      addSelectedRole,
      orgId,
      addOrgId,

      startDate,
      updateStartDate,
      endDate,
      updateEndDate,

      CLselectedShift,
      updateCLselectedShift,
      selectedCallListType,
      updateSelectedCallListType,

      callListActionsCLBE,
      updateCallListActionsCLBE,
      employeeActionsCLBE,
      updateEmployeeActionsCLBE,
      filterValidationCLBE,
      updateFilterValidationCLBE,

      SEselectedOpenShifts,
      updateSEselectedOpenShifts,

      networkErrorNotifications,
      showNetworkErrorNotification,

      pageNotification,
      showPageNotification,
      dismissPageNotification,

      wasTabClicked,
      setWasTabClicked,
    };
  }, [
    allOpenShiftsData,

    CLselectedShift,
    selectedCallListType,
    editedShiftsData,
    callListUserActions,
    pageHasUnsavedChanges,
    selectedLocation,
    selectedDepartment,
    selectedRole,
    orgId,
    startDate,
    endDate,
    callListActionsCLBE,
    employeeActionsCLBE,
    filterValidationCLBE,
    locationOptions,
    departmentOptions,
    roleOptions,
    initializeData,
    SEselectedOpenShifts,
    networkErrorNotifications,
    pageNotification,
    wasTabClicked,
    setWasTabClicked,
  ]);

  return (
    <ScheduleEditorShiftManagementContext.Provider value={providerValue}>
      {children}
    </ScheduleEditorShiftManagementContext.Provider>
  );
};

export const useScheduleEditorShiftManagement = () => {
  const context = useContext(ScheduleEditorShiftManagementContext);
  if (context === undefined) {
    throw new Error(
      'useScheduleEditorShiftManagement must be used within a ScheduleEditorShiftManagementProvider',
    );
  }
  return context;
};
