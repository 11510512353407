import React, { createContext, useContext, useMemo, useState } from 'react';
import { startOfWeek, startOfToday } from 'date-fns';
import {
  SelectedElement,
  RequestLeaveTimeOffState,
  ElementOptions,
  Filters,
  ValidationInformation,
  CancelRequest,
  ReasonOption,
  ModalProps,
} from './RequestLeaveTimeOffContext.type';
import { SplitShiftRequest } from '../../splitShifts.types';
import { addedDay } from '../../../../utils/helper';

const modalInitialValues: ModalProps = {
  isOpen: false,
  type: 'cancel',
  title: 'Cancel',
  size: 'small',
};

const today = startOfToday();
export const datePickerStartDate = startOfWeek(today);
export const datePickerEndDate = addedDay(datePickerStartDate, 'next', 29);

export const initialFilters = {
  selectedLocation: '',
  locationOptions: [],
  selectedDepartment: '',
  departmentOptions: [],
  selectedRole: '',
  roleOptions: [],
  selectedType: [],
  typeOptions: [],
  startDateSelected: new Date(datePickerStartDate),
  endDateSelected: new Date(datePickerEndDate),
};

export const initialCancelRequest = {
  isModalOpen: false,
  content: undefined,
};

export const contextInitialValues: RequestLeaveTimeOffState = {
  changeFilters: () => {},
  resetFilterDate: () => {},
  filters: {
    selectedLocation: '',
    locationOptions: [],
    selectedDepartment: '',
    departmentOptions: [],
    selectedRole: '',
    roleOptions: [],
    selectedType: [],
    typeOptions: [],
    startDateSelected: new Date(),
    endDateSelected: new Date(),
  },

  cancelRequest: {
    isModalOpen: false,
    content: undefined,
  },
  updatedCancelRequest: () => {},

  invalidSplitTime: [],
  setInvalidSplitTime: () => {},

  requests: [
    {
      orgId: '',
      requestId: '',
      shiftId: '',
      status: 'Unknown',
      date: '',
      startDateTime: '',
      endDateTime: '',
      startTime: '',
      endTime: '',
      location: '',
      department: '',
      job: '',
      requestType: 'Unknown',
      leaveType: '',
      segments: [],
    },
  ],
  changeRequests: () => {},

  orgId: '',
  setOrgId: () => {},
  shifts: [],
  setShifts: () => {},
  splits: [],
  setSplits: () => {},
  reasonOptions: [],
  setReasonOptions: () => {},
  modal: modalInitialValues,
  setModal: () => {},
  closeModal: () => {},

  toasts: [],
  setToasts: () => {},
  dimissToast: () => {},
};

export const RequestLeaveTimeOffContext =
  createContext<RequestLeaveTimeOffState>(contextInitialValues);

export const RequestLeaveTimeOffProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  /**
   * Filters options and selections
   */

  const [filters, setFilters] = useState<Filters>(initialFilters);

  const [invalidSplitTime, setInvalidSplitTime] = useState<
    ValidationInformation[]
  >([]);

  const addSelectedOption = ({ selected, param }: SelectedElement) => {
    setFilters((prev: Filters) => {
      return {
        ...prev,
        [param]: selected,
      };
    });
  };
  const addOptions = ({ options, param }: ElementOptions) => {
    setFilters((prev: Filters) => {
      return {
        ...prev,
        [param]: options,
      };
    });
  };

  const changeFilters = (filter: SelectedElement | ElementOptions) => {
    if ('selected' in filter) addSelectedOption(filter);

    if ('options' in filter) addOptions(filter);
  };

  const resetFilterDate = () => {
    addSelectedOption({
      selected: datePickerStartDate,
      param: 'startDateSelected',
    });
    addSelectedOption({
      selected: datePickerEndDate,
      param: 'endDateSelected',
    });
  };

  /* Cancel Request */
  const [cancelRequest, setCancelRequest] =
    useState<CancelRequest>(initialCancelRequest);

  const updatedCancelRequest = ({ isModalOpen, content }: CancelRequest) => {
    setCancelRequest({ isModalOpen, content });
  };

  /* Split shifts Original Requests */
  const [requests, setRequests] = useState<SplitShiftRequest[]>([]);
  const changeRequests = (requestsList: SplitShiftRequest[]) => {
    setRequests(
      requestsList.sort((a, b) => {
        return (
          new Date(a.startDateTime).getTime() -
          new Date(b.startDateTime).getTime()
        );
      }),
    );
  };

  /** Org Id */
  const [orgId, setOrgId] = useState('');

  /** Shifts and Splits */
  const [shifts, setShifts] = useState<SplitShiftRequest[]>([]);
  const [splits, setSplits] = useState<SplitShiftRequest[]>([]);

  /** Reason Options */
  const [reasonOptions, setReasonOptions] = useState<ReasonOption[]>([]);

  /** Modals */
  const [modal, setModal] = useState<ModalProps>(modalInitialValues);
  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  /** Invalid split */
  const [toasts, setToasts] = useState<ValidationInformation[]>([]);
  const dimissToast = (id: string) => {
    const updatedToasts = toasts.filter(item => item.id !== id);
    setToasts(updatedToasts);
  };

  const providerValues = useMemo(() => {
    return {
      changeFilters,
      resetFilterDate,
      filters,

      cancelRequest,
      updatedCancelRequest,

      invalidSplitTime,
      setInvalidSplitTime,
      requests,
      changeRequests,

      orgId,
      setOrgId,
      shifts,
      setShifts,
      splits,
      setSplits,
      reasonOptions,
      setReasonOptions,
      modal,
      setModal,
      closeModal,

      toasts,
      setToasts,
      dimissToast,
    };
  }, [
    changeFilters,
    resetFilterDate,
    filters,

    cancelRequest,
    updatedCancelRequest,

    invalidSplitTime,
    setInvalidSplitTime,
    modal,
    shifts,
    splits,
    requests,
    setRequests,
    toasts,
    setToasts,
  ]);

  return (
    <RequestLeaveTimeOffContext.Provider value={providerValues}>
      {children}
    </RequestLeaveTimeOffContext.Provider>
  );
};

export const useRequestLeaveTimeOff = () => {
  const context = useContext(RequestLeaveTimeOffContext);

  if (context === undefined) {
    throw new Error(
      'useRequestLeaveTimeOff must be used within a RequestLeaveTimeOffProvider',
    );
  }

  return context;
};
