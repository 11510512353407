import React, { Fragment } from 'react';
import { isArray } from 'lodash';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Notification from '../Notification/Notification';

export function extractRTKErrors(
  error: FetchBaseQueryError | SerializedError | undefined,
): string[] {
  const errorMessages: string[] = [];

  if (!error) return [];
  // FetchBaseQueryError & SerializedError can be narrowed by looking for 'status'
  if ('status' in error) {
    if (error.data && isArray(error.data)) {
      error.data.forEach(element => {
        if (typeof element === 'string') errorMessages.push(element);
      });
    } else {
      errorMessages.push('A network error has occured. Please try again.');
    }
  } else if (error.message && errorMessages.length === 0)
    errorMessages.push(error.message);

  return errorMessages;
}

/**
 * Parses the message and returns a `<Notification/>` for each error in errors prop
 */
const NetworkErrorNotifications = ({
  errors,
  isInlineAlert = false,
}: {
  errors: (FetchBaseQueryError | SerializedError | undefined)[];
  isInlineAlert?: boolean;
}) => {
  return (
    <>
      {errors.map((error, i) => {
        if (!error) return null;
        const errorMessages = extractRTKErrors(error);
        return (
          <Fragment key={i}>
            <Notification
              type="error"
              ariaLabel="Network error occurred"
              fullWidth={isInlineAlert}
            >
              <div className="flex justify-between flex-col">
                {errorMessages[0]}

                {errorMessages.length > 1 && (
                  <ul className="list-disc pl-4 mt-1">
                    {errorMessages.map(
                      (item, index) =>
                        index !== 0 && (
                          <li key={index} className="mb-2">
                            {item}
                          </li>
                        ),
                    )}
                  </ul>
                )}
              </div>
            </Notification>
          </Fragment>
        );
      })}
    </>
  );
};

export default NetworkErrorNotifications;
