import axios from 'axios';
import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { apiParams } from '../params';

export const TIMEOUT_URL = `${apiParams.baseUrl}/spa/caworkspace/timeout`;

// We set the interval to 1 minute because Chrome will throttle timers in the background.
// https://developer.chrome.com/blog/timer-throttling-in-chrome-88#intensive_throttling
export const DECREMENT_AMOUNT = 1_000 * 60 * 1; // 1 min
export const TIME_TO_INVOKE_CALLBACK = 1_000 * 60 * 2; // 2 min
export const TIME_REMAINING = 1_000 * 60 * 20; // 20 min

/**
 * A simple countdown timer.
 * The timer invokes a callback after a certain amount time has elapsed.
 *
 * We are using the callback to make a fetch call.
 * This fetch call is used to 'refresh' the user's CleverAnt session.
 * This refresh is needed on features where we anticipate the user to be active
 * but not making any new API calls which would cause their session to prematurely expire.
 */
export class Timer {
  time: number;

  decrementAmount: number;

  name: string;

  intervalId: ReturnType<Window['setInterval']> | null = null;

  timeToInvokeCallback: number;

  fetchDetails: FetchArgs | undefined;

  timeoutUrl: string;

  callBack: () => Promise<void>;

  constructor(config: {
    name?: string;
    decrementAmount?: number;
    time?: number;
    timeToInvokeCallback?: number;
    timeoutUrl?: string;
  }) {
    this.name = config?.name || '';
    this.decrementAmount = config?.decrementAmount || DECREMENT_AMOUNT;
    this.time = config?.time || TIME_REMAINING;
    this.timeToInvokeCallback =
      config?.timeToInvokeCallback || TIME_TO_INVOKE_CALLBACK;
    this.timeoutUrl = config?.timeoutUrl || TIMEOUT_URL;

    this.callBack = async () => {
      const fetchArgs = this.getFetchDetails();

      if (fetchArgs) {
        const { params, headers } = fetchArgs;
        try {
          // @ts-ignore
          await axios({
            method: 'GET',
            url: this.timeoutUrl,
            data: JSON.stringify({}),
            params: { caid: params?.caid },
            headers,
          });
        } catch {
          // console.error('Refresh error');
        }
      }
    };
  }

  startTimer() {
    if (this.intervalId) {
      // console.log('%s Timer is already running.', this.name);
      return;
    }

    // console.log('%s: Timer started.', this.name);
    this.intervalId = window.setInterval(async () => {
      this.time -= this.decrementAmount;

      // a timer completing on its own should not happpen so we will do nothing and return
      if (this.time < 0) {
        this.stopTimer();
        // console.log('%s: Timer finished.', this.name);
      } else if (this.time <= this.timeToInvokeCallback) {
        // console.log('%s: Time to invoke callback.', this.name);
        if (this.callBack) {
          await this.callBack();
          this.resetTimer();
        }
      }
    }, this.decrementAmount);
  }

  resetTimer() {
    this.stopTimer();
    this.time = TIME_REMAINING;
    // console.log('%s: Timer reset.', this.name);
    this.startTimer();
  }

  private stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  cleanup() {
    this.stopTimer();
  }

  getFetchDetails() {
    return this.fetchDetails;
  }

  setFetchDetails(fetchArgs: FetchArgs) {
    this.fetchDetails = fetchArgs;
  }

  getCurrentTime() {
    return this.time;
  }

  printCurrentTime() {
    const minutes = Math.floor(this.time / 1000 / 60);
    const seconds = Math.floor((this.time / 1000) % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
}
