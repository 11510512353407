import React from 'react';

/**
 * @description Displays an animated spinner. Use while data is loading in dynamic components.
 */
const Spinner = () => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-white bg-opacity-75 z-spinner">
      <div
        role="progressbar"
        aria-label="spinning progress bar"
        className="spinner inline-block w-10 h-10 rounded-full animate-spin border-success-400 border-t-primary-dark-green border-4"
      />
    </div>
  );
};

export default Spinner;
