import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { DesktopNavigationLinkProps } from './DesktopNavigationLink.types';

const DesktopNavigationLink: React.FC<DesktopNavigationLinkProps> = props => {
  const { linkTitle, to, Icon } = props;
  return (
    <NavLink
      className={() =>
        cn(
          'flex text-white focus:outline-none focus:bg-secondary-dark-green',
          'mr-6',
          'h-10',
          'py-2',
          {
            'px-4': !Icon,
          },
          'font-semibold',
          'rounded',
          'hover:bg-secondary-dark-green',
        )
      }
      to={to}
    >
      {Icon || linkTitle}
    </NavLink>
  );
};

export default DesktopNavigationLink;
