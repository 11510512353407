import React from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';
import DesktopMainNavigation from './desktop/DesktopMainNavigation';
import MobileMainNavigation from './mobile/MobileMainNavigation';
import useGetPermissions from '../../hooks/ManagerPermissions/useGetPermissions';
import { getNavigationItems } from '../../utils/navigationConstants';

const MainNavigation: React.FC<{}> = () => {
  const { data, loading } = useGetPermissions();
  const navItems = getNavigationItems(data) ?? [];
  const isLgScreen = useBreakpoint('lg');

  if (isLgScreen) {
    return <DesktopMainNavigation navItems={navItems} isLoading={loading} />;
  }

  return <MobileMainNavigation navItems={navItems} isLoading={loading} />;
};

export default MainNavigation;
