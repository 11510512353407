import React, { lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import {
  ADDITIONALQUESTIONS,
  HISTORIC_ADD_EDIT,
  MANAGE_AVERAGE_HOURS,
  MANAGE_AVERAGE_HOURS_EXCEPTION_REPORT,
  MANAGE_VACANCIES,
  MY_REQUESTS,
  REQUEST_LEAVE_TIME_OFF,
  REQUESTLEAVE,
  REQUESTTIMEOFF,
  RESOLVE_REQUESTS,
  ROW_ASSIGNMENTS,
  ROW_ASSIGNMENTS_EXCEPTION_REPORT,
  SCHEDULE,
  SCHEDULE_EDITOR,
  SCHEDULE_EDITOR_SHIFT_MANAGEMENT,
  SHIFT_BID,
  SHIFT_BID_REQUESTS,
  SHIFT_TRADE,
  SHIFT_TRADE_REQUEST_REVIEW,
  SHIFT_TRADE_REQUEST_SELECT_SHIFT,
  SHIFT_TRADE_REQUEST_SELECT_WORKER,
  SPLIT_SHIFT_REQUESTS,
  SUBMIT_FOR_EMPLOYEE,
  TAP_EXCEPTION,
  TIMECARD_PREMIUMS,
  UNIT_SCHEDULE,
} from './paths';

import { ResolveRequestsProvider } from '../components/resolve-requests/context/ResolveRequestsContext';
import { ShiftTradeProvider } from '../components/shift-trade/context/ShiftTradeContext/ShiftTradeContext';
import { ShiftTradeRequestProvider } from '../components/shift-trade/context/ShiftTradeRequestContext/ShiftTradeRequestContext';
import { RowAssignmentsProvider } from '../components/row-assignments/context/RowAssignmentsContext';
import { ScheduleEditorProvider } from '../components/manager-schedule-refactor/schedule-editor/ScheduleEditorContext/ScheduleEditorContext';
import { ScheduleEditorShiftManagementProvider } from '../components/schedule-editor-shift-management/context/ScheduleEditorShiftManagementContext';
import { ManageVacanciesProvider } from '../components/manage-vacancies/ManageVacanciesContext/ManageVacanciesContext';
import { RequestLeaveTimeOffProvider } from '../components/split-shifts/context/RequestLeaveTimeOffContext/RequestLeaveTimeOffContext';
import { ProtectedRoute } from './ProtectedRoute';
import useGetPermissions from '../hooks/ManagerPermissions/useGetPermissions';

/* All page view components are lazy-imported: */
const AdditionalQuestionsPage = lazy(
  () => import('../pages/AdditionalQuestionsPage/AdditionalQuestionsPage'),
);
const DashboardPage = lazy(
  () => import('../pages/DashboardPage/DashboardPage'),
);
const FourOhFourPage = lazy(
  () => import('../pages/FourOhFourPage/FourOhFourPage'),
);
const ManageAverageHoursPage = lazy(
  () => import('../pages/ManageAverageHoursPage/ManageAverageHoursPage'),
);

const SchedulePage = lazy(() => import('../pages/SchedulePage/SchedulePage'));
const RequestTimeOffPage = lazy(
  () => import('../pages/RequestTimeOffPage/RequestTimeOffPage'),
);
const RequestLeaveWithPrimaryJobData = lazy(
  () => import('../pages/RequestLeavePage/RequestLeaveWithPrimaryJobData'),
);
const ShiftBidRequestsPage = lazy(
  () => import('../pages/ShiftBidRequestsPage/ShiftBidRequestsPage'),
);
const ShiftBidPage = lazy(() => import('../pages/ShiftBidPage/ShiftBidPage'));
const ResolveRequestsPage = lazy(
  () => import('../pages/ResolveRequestsPage/ResolveRequestsPage'),
);

const ShiftTradePage = lazy(
  () => import('../pages/ShiftTradePage/ShiftTradePage'),
);
const ShiftTradeRequestPage = lazy(
  () => import('../pages/ShiftTradeRequestPage/ShiftTradeRequestPage'),
);
const ShiftTradeRequestSelectShiftPage = lazy(
  () => import('../pages/ShiftTradeRequestPage/ShiftTradeRequestSelectShift'),
);
const ShiftTradeRequestSelectWorkerPage = lazy(
  () => import('../pages/ShiftTradeRequestPage/ShiftTradeRequestSelectWorker'),
);
const ShiftTradeRequestReviewPage = lazy(
  () => import('../pages/ShiftTradeRequestPage/ShiftTradeRequestReview'),
);
const SplitShiftsRequestsPage = lazy(
  () => import('../pages/SplitShiftsRequestsPage/SplitShiftsRequestsPage'),
);
const RequestLeaveTimeOffPage = lazy(
  () => import('../pages/RequestLeaveTimeOffPage/RequestLeaveTimeOffPage'),
);
const TapExceptionPage = lazy(
  () => import('../pages/TapExceptionPage/TapExceptionPage'),
);
const TerminationReportPage = lazy(
  () => import('../pages/TerminationReportPage/TerminationReportPage'),
);
const TimeManagementPage = lazy(
  () =>
    import('../pages/TimeManagementRequestsPage/TimeManagementRequestsPage'),
);
const ScheduleEditorPage = lazy(
  () => import('../pages/ScheduleEditorPage/ScheduleEditorPage'),
);
const UnitSchedulePage = lazy(
  () => import('../pages/ManagerSchedule/UnitSchedulePage/UnitSchedulePage'),
);
const TimecardPremiumsPage = lazy(
  () => import('../pages/TimecardPremiumsPage/TimecardPremiumsPage'),
);
const HistoricAddEditPage = lazy(
  () => import('../pages/HistoricAddEditPage/HistoricAddEditPage'),
);
const ScheduleEditorShiftManagementPage = lazy(
  () =>
    import(
      '../pages/ScheduleEditorShiftManagementPage/ScheduleEditorShiftManagementPage'
    ),
);
const ManageVacanciesPage = lazy(
  () => import('../pages/ManageVacanciesPage/ManageVacanciesPage'),
);
const RowAssignmentsPage = lazy(
  () => import('../pages/RowAssignmentsPage/RowAssignmentsPage'),
);
const RowAssignmentsTerminationReportPage = lazy(
  () =>
    import(
      '../pages/RowAssignmentsPage/TerminationReportPage/TerminationReportPage'
    ),
);

const SubmitForEmployeePage = lazy(
  () => import('../pages/SubmitForEmployeePage/SubmitForEmployeePage'),
);

const IndexRoute = () => {
  const { data } = useGetPermissions();
  const isEmployee =
    data?.accessProfileName === 'Employee' ||
    data?.apiAccessRights.length === 0;
  return isEmployee ? <Navigate to={SCHEDULE} /> : <DashboardPage />;
};

/**
 * @description Describes routing structure for the app, and any page components
 * or other properties related to the view
 */
const Routes = () => {
  return useRoutes([
    /* HOME / DASHBOARD */
    {
      path: '/',
      element: <IndexRoute />,
    },

    /* SCHEDULE */
    {
      path: `${SCHEDULE}/*`,
      element: <Outlet />,
      children: [
        {
          path: ':calendarView',
          element: <SchedulePage />,
        },
        {
          path: '*',
          element: <Navigate to={`${SCHEDULE}/month`} />,
        },
      ],
    },

    /* REQUESTS */
    {
      path: `${MY_REQUESTS}/*`,
      element: <Outlet />,
      children: [
        /* Request Time Off */
        {
          path: REQUESTTIMEOFF,
          element: <RequestTimeOffPage />,
        },
        /* Request Leave */
        {
          path: REQUESTLEAVE,
          element: <RequestLeaveWithPrimaryJobData />,
        },
        /* Additional Questions */
        {
          path: ADDITIONALQUESTIONS,
          element: <AdditionalQuestionsPage />,
        },
        /* index */
        {
          path: '*',
          element: <TimeManagementPage />,
        },
      ],
    },

    /* SPLIT SHIFTS */
    {
      path: `${SPLIT_SHIFT_REQUESTS}/*`,
      element: (
        <RequestLeaveTimeOffProvider>
          <Outlet />
        </RequestLeaveTimeOffProvider>
      ),
      children: [
        /* request leave / time off */
        {
          path: REQUEST_LEAVE_TIME_OFF,
          element: <RequestLeaveTimeOffPage />,
        },
        /* index */
        {
          path: '*',
          element: <SplitShiftsRequestsPage />,
        },
      ],
    },

    /* TAP EXCEPTION */
    {
      path: `${TAP_EXCEPTION}/*`,
      element: <TapExceptionPage />,
    },

    /* SHIFT BID */
    {
      path: SHIFT_BID_REQUESTS,
      element: <ShiftBidRequestsPage />,
    },
    {
      path: SHIFT_BID,
      element: <ShiftBidPage />,
    },

    /* SHIFT TRADE */
    {
      path: SHIFT_TRADE,
      element: (
        <ShiftTradeProvider>
          <ShiftTradePage />
        </ShiftTradeProvider>
      ),
    },
    {
      element: (
        <ShiftTradeRequestProvider>
          <ShiftTradeRequestPage />
        </ShiftTradeRequestProvider>
      ),
      children: [
        {
          path: SHIFT_TRADE_REQUEST_SELECT_SHIFT,
          element: <ShiftTradeRequestSelectShiftPage />,
        },
        {
          path: SHIFT_TRADE_REQUEST_SELECT_WORKER,
          element: <ShiftTradeRequestSelectWorkerPage />,
        },
        {
          path: SHIFT_TRADE_REQUEST_REVIEW,
          element: <ShiftTradeRequestReviewPage />,
        },
      ],
    },

    /* Timecard & Premiums */
    {
      path: TIMECARD_PREMIUMS,
      element: <TimecardPremiumsPage />,
    },

    /* Historic Add + Edit */
    {
      path: HISTORIC_ADD_EDIT,
      element: <HistoricAddEditPage />,
    },

    /** Admin Routes are placed under the parent ProtectedRoute
     *  The child routes are rendered via an Outlet in ProtectedRoute.
     */
    {
      element: <ProtectedRoute />,
      children: [
        /* Resolve Requests */
        {
          path: RESOLVE_REQUESTS,
          element: (
            <ResolveRequestsProvider>
              <ResolveRequestsPage />
            </ResolveRequestsProvider>
          ),
        },

        /* Row Assignments */
        {
          path: ROW_ASSIGNMENTS,
          element: (
            <RowAssignmentsProvider>
              <RowAssignmentsPage />
            </RowAssignmentsProvider>
          ),
        },
        /* Row Assignments Termination Report */
        {
          path: ROW_ASSIGNMENTS_EXCEPTION_REPORT,
          element: (
            <RowAssignmentsProvider>
              <RowAssignmentsTerminationReportPage />
            </RowAssignmentsProvider>
          ),
        },

        /* Manage Average Hours */
        {
          path: MANAGE_AVERAGE_HOURS,
          element: <ManageAverageHoursPage />,
        },
        /* Termination Report */
        {
          path: MANAGE_AVERAGE_HOURS_EXCEPTION_REPORT,
          element: <TerminationReportPage />,
        },

        /* Manager Schedule */
        {
          path: SCHEDULE_EDITOR,
          element: (
            <ScheduleEditorProvider>
              <ScheduleEditorPage />
            </ScheduleEditorProvider>
          ),
        },
        {
          path: SCHEDULE_EDITOR_SHIFT_MANAGEMENT,
          element: (
            <ScheduleEditorProvider>
              <ScheduleEditorShiftManagementProvider>
                <ScheduleEditorShiftManagementPage />
              </ScheduleEditorShiftManagementProvider>
            </ScheduleEditorProvider>
          ),
        },
        {
          path: UNIT_SCHEDULE,
          element: <UnitSchedulePage />,
        },

        /* Manage Vacancies */
        {
          path: MANAGE_VACANCIES,
          element: (
            <ManageVacanciesProvider>
              <ManageVacanciesPage />
            </ManageVacanciesProvider>
          ),
        },

        /* Submit For Employee */
        {
          path: SUBMIT_FOR_EMPLOYEE,
          element: <SubmitForEmployeePage />,
        },
      ],
    },
    /* Fallback (404) */ {
      path: '*',
      element: <FourOhFourPage />,
    },
  ]);
};

export default Routes;
