let apiServer: string;

if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
  apiServer = process.env.REACT_APP_API_PROXY_SERVER;
} else {
  apiServer = process.env.REACT_APP_API_SERVER;
}

const apiParams: CleverAntAPI.ApiParams = {
  baseUrl: apiServer,
  openSessionUrl: `${apiServer}/api/open`,
  runActionUrl: `${apiServer}/api/run`,
  closeSessionUrl: `${apiServer}/api/close`,
};

export { apiParams };
