import React, { createContext, useContext, useMemo, useState } from 'react';
import { endOfWeek, startOfToday, startOfWeek } from 'date-fns';
import {
  ScheduleCalendarView,
  ScheduleEditorContextState,
  ScheduleEditorPageNotificationState,
} from './ScheduleEditorContext.types';
import { SelectFieldValue } from '../../../common/Select/Select.types';
import { CheckboxData } from '../../../common/MultiSelectFilter/MultiSelectFilter.types';
import { PageNotification } from '../../../shift-trade/context/ShiftTradeRequestContext/ShiftTradeRequestContext.types';

const ScheduleEditorContext = createContext<
  ScheduleEditorContextState | undefined
>(undefined);

export const ScheduleEditorProvider: React.FC<{
  children: React.ReactNode;
}> = props => {
  const { children } = props;

  const emptyOptionValue = {
    id: undefined,
    text: '',
    value: '',
  };

  /** STATES */
  /** Page Notification State */
  const [pageNotification, setPageNotification] =
    useState<ScheduleEditorPageNotificationState>({
      isShowingNotification: false,
      type: 'success',
      message: undefined,
    });

  /** Filter States */
  const [selectedLocation, setSelectedLocation] =
    useState<SelectFieldValue>(emptyOptionValue);
  const [selectedDepartment, setSelectedDepartment] =
    useState<SelectFieldValue>(emptyOptionValue);
  const [selectedJob, setSelectedJob] =
    useState<SelectFieldValue>(emptyOptionValue);
  const [selectedEmployee, setSelectedEmployee] =
    useState<SelectFieldValue>(emptyOptionValue);
  const [selectedFilters, setSelectedFilters] = useState<CheckboxData[]>([]);

  /** Toolbar States */
  const [currentScheduleCalendarView, setCurrentScheduleCalendarView] =
    useState<ScheduleCalendarView>('Week');
  const today = startOfToday();
  const defaultScheduleDateWeekView = {
    startDate: startOfWeek(today),
    endDate: endOfWeek(today),
  };
  const defaultScheduleDateDayView = {
    startDate: today,
    endDate: today,
  };
  const [scheduleDateRange, setScheduleDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>(defaultScheduleDateWeekView);

  /** Calendar States */
  const [scheduleSideDrawer, setScheduleSideDrawer] = useState<{
    isOpen: boolean;
    showAsQuickInfo: boolean;
    schedule?: ScheduleEditor.Schedule | undefined;
  }>({
    isOpen: false,
    showAsQuickInfo: false,
  });

  const [openShiftsSideDrawer, setOpenShiftsSideDrawer] = useState<{
    isOpen: boolean;
    sameOpenShifts?: ScheduleEditor.SameOpenShift[] | undefined;
  }>({
    isOpen: false,
  });

  const [bookOnModal, setBookOnModal] = useState<{
    isOpen: boolean;
    startTime?: Date;
    endTime?: Date;
    tabToSelect?: 'Book On' | 'Open Shift';
    personNumberToSelect?: string;
  }>({
    isOpen: false,
  });

  /** HANDLERS */
  /** Page Notification Handlers */
  const showPageNotification = (notification: PageNotification) => {
    setPageNotification({ ...notification, isShowingNotification: true });
  };

  const dismissPageNotification = () => {
    setPageNotification(prevState => {
      return { ...prevState, isShowingNotification: false };
    });
  };

  /** Filter Handlers */
  const addSelectedLocation = (sLoc: SelectFieldValue) => {
    setSelectedLocation(sLoc);
    setSelectedDepartment(emptyOptionValue);
    setSelectedJob(emptyOptionValue);
    setSelectedEmployee(emptyOptionValue);
  };

  const addSelectedDepartment = (sDept: SelectFieldValue) => {
    setSelectedDepartment(sDept);
    setSelectedJob(emptyOptionValue);
    setSelectedEmployee(emptyOptionValue);
  };

  const addSelectedJob = (sJob: SelectFieldValue) => {
    setSelectedJob(sJob ?? emptyOptionValue);
    setSelectedEmployee(emptyOptionValue);
  };

  const addSelectedEmployee = (sEmp: SelectFieldValue) => {
    setSelectedEmployee(sEmp);
  };
  const clearSelectedEmployee = () => {
    setSelectedEmployee(emptyOptionValue);
  };

  const addSelectedFilters = (sFilters: CheckboxData[]) => {
    setSelectedFilters(sFilters);
  };

  /** Toolbar Handlers */
  const changeCurrentScheduleCalendarView = (
    viewToSelect: ScheduleCalendarView,
  ) => {
    setCurrentScheduleCalendarView(viewToSelect);
  };

  const addScheduleDateRange = (startDate: Date, endDate: Date) => {
    setScheduleDateRange({
      startDate,
      endDate,
    });
  };

  /** Calendar Handlers */
  const openScheduleSideDrawer = (schedule: ScheduleEditor.Schedule) => {
    setScheduleSideDrawer(prevState => ({
      ...prevState,
      isOpen: true,
      schedule,
    }));
  };

  const closeScheduleSideDrawer = () => {
    setScheduleSideDrawer(prevState => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const toggleScheduleSideDrawerQuickInfo = () => {
    setScheduleSideDrawer(prevState => ({
      ...prevState,
      showAsQuickInfo: !prevState.showAsQuickInfo,
    }));
  };

  const openSameOpenShiftsSideDrawer = (
    openShifts: ScheduleEditor.SameOpenShift[],
  ) => {
    setOpenShiftsSideDrawer({
      isOpen: true,
      sameOpenShifts: openShifts,
    });
  };

  const closeSameOpenShiftsSideDrawer = () => {
    setOpenShiftsSideDrawer({
      isOpen: false,
    });
  };

  const openBookOnModal = (
    startTime?: Date,
    endTime?: Date,
    tabToSelect?: 'Book On' | 'Open Shift',
    personNumberToSelect?: string,
  ) => {
    setBookOnModal({
      isOpen: true,
      startTime,
      endTime,
      tabToSelect,
      personNumberToSelect,
    });
  };

  const closeBookOnModal = () => {
    setBookOnModal({
      isOpen: false,
    });
  };

  const providerValues = useMemo(
    () => ({
      pageNotification,
      showPageNotification,
      dismissPageNotification,

      emptyOptionValue,

      selectedLocation,
      addSelectedLocation,

      selectedDepartment,
      addSelectedDepartment,

      selectedJob,
      addSelectedJob,

      selectedEmployee,
      addSelectedEmployee,
      clearSelectedEmployee,

      selectedFilters,
      addSelectedFilters,

      currentScheduleCalendarView,
      changeCurrentScheduleCalendarView,

      defaultScheduleDateWeekView,
      defaultScheduleDateDayView,
      scheduleDateRange,
      addScheduleDateRange,

      scheduleSideDrawer,
      openScheduleSideDrawer,
      closeScheduleSideDrawer,
      toggleScheduleSideDrawerQuickInfo,

      openShiftsSideDrawer,
      openSameOpenShiftsSideDrawer,
      closeSameOpenShiftsSideDrawer,

      bookOnModal,
      openBookOnModal,
      closeBookOnModal,
    }),
    [
      pageNotification,
      selectedLocation,
      selectedDepartment,
      selectedJob,
      selectedEmployee,
      selectedFilters,
      currentScheduleCalendarView,
      scheduleDateRange,
      scheduleSideDrawer,
      openShiftsSideDrawer,
      bookOnModal,
    ],
  );
  return (
    <ScheduleEditorContext.Provider value={providerValues}>
      {children}
    </ScheduleEditorContext.Provider>
  );
};

export const useScheduleEditor = () => {
  const context = useContext(ScheduleEditorContext);
  if (context === undefined) {
    throw new Error(
      'useScheduleEditor must be used within a ScheduleEditorProvider',
    );
  }
  return context;
};
