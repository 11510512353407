import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { SelectOption } from '../../common/Select/Select.types';
import { RowAssignmentsContextValue } from './RowAssignments.types';

const RowAssignmentsContext = createContext<
  RowAssignmentsContextValue | undefined
>(undefined);

/**
 * Manages all common states for Resolve Requests Page
 */
function RowAssignmentsProvider({ children }: { children: ReactNode }) {
  /** State variable for selected location */
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | number | undefined
  >('');

  const addSelectedLocation = (
    sLoc: string,
    sLocId: string | number | undefined,
  ) => {
    setSelectedLocation(sLoc);
    setSelectedLocationId(sLocId);
  };

  /** State variable for selected Department */
  const [selectedDept, setSelectedDept] = useState('');
  const [selectedDeptId, setSelectedDeptId] = useState<
    string | number | undefined
  >('');
  const addSelectedDept = (
    sDep: string,
    sDepId: string | number | undefined,
  ) => {
    setSelectedDept(sDep);
    setSelectedDeptId(sDepId);
  };

  /** State variable for selected job */
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState<
    string | number | undefined
  >('');
  const addSelectedJob = (
    sJob: string,
    sOrgId: string | number | undefined,
  ) => {
    setSelectedJob(sJob);
    setSelectedOrgId(sOrgId);
  };

  /** State variable for selected types */
  const [selectedRotation, setSelectedRotation] = useState('');
  const [selectedRotationId, setSelectedRotationId] = useState<
    string | number | undefined
  >('');

  const addSelectedRotation = (
    sRotation: string,
    sRotationId: string | number | undefined,
  ) => {
    setSelectedRotation(sRotation);
    setSelectedRotationId(sRotationId);
  };

  /* State of dynamic Select options */
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const [deptOptions, setDeptOptions] = useState<SelectOption[]>([]);
  const [jobOptions, setJobOptions] = useState<SelectOption[]>([]);
  const [rotationOptions, setRotationOptions] = useState<SelectOption[]>([]);

  const [rowAssignmentsToDisplay, setRowAssignmentsToDisplay] = useState<
    RowAssignment.Assignment[]
  >([]);

  /** Shared callbacks and variables */
  const value = useMemo(() => {
    return {
      locationOptions,
      setLocationOptions,
      deptOptions,
      setDeptOptions,
      jobOptions,
      setJobOptions,
      rotationOptions,
      setRotationOptions,

      selectedLocation,
      selectedLocationId,
      addSelectedLocation,

      selectedDept,
      selectedDeptId,
      addSelectedDept,

      selectedJob,
      selectedOrgId,
      addSelectedJob,

      selectedRotation,
      selectedRotationId,
      addSelectedRotation,

      rowAssignmentsToDisplay,
      setRowAssignmentsToDisplay,
    };
  }, [
    locationOptions,
    deptOptions,
    jobOptions,
    rotationOptions,
    selectedLocation,
    selectedLocationId,
    selectedDept,
    selectedDeptId,
    selectedJob,
    selectedOrgId,
    selectedRotation,
    selectedRotationId,
    rowAssignmentsToDisplay,
  ]);

  return (
    <RowAssignmentsContext.Provider value={value}>
      {children}
    </RowAssignmentsContext.Provider>
  );
}

/**
 * Convenient hook to access ShiftTradeContext value in components
 */
function useRowAssignments() {
  const context = useContext(RowAssignmentsContext);
  if (context === undefined) {
    throw new Error(
      'useRowAssignments must be used within a RowAssingmentsProvider',
    );
  }
  return context;
}

export { RowAssignmentsProvider, useRowAssignments };
