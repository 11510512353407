import React from 'react';
import PageContainer from '../../components/common/PageContainer/PageContainer';

/**
 * FourOhFourPage
 * @description Renders the view for any URL that could not be found (404)
 */
function FourOhFourPage({ message }: { message?: string }) {
  return (
    <PageContainer>
      <h1 className="mx-auto max-w-2xl px-6 py-24 text-center text-4xl font-bold tracking-tight">
        {message || "Oops! That page can't be found."}
      </h1>
    </PageContainer>
  );
}

export default FourOhFourPage;
