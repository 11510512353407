import React, { Suspense, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

import Spinner from '../components/common/Spinner/Spinner';
import Routes from '../routes';
import { useAppSelector } from '../hooks/reduxHooks';
import { findUKGSession } from '../hooks/useUKGSession';
import { addUKGSessionToStore } from '../redux/session/sessionSlice';
import initialSessionState from '../redux/session/initialState';
import MainNavigation from '../components/Navigation/MainNavigation';
import useGetPermissions from '../hooks/ManagerPermissions/useGetPermissions';
import GlobalNotifications from '../components/common/GlobalNotifications/GlobalNotifications';

function App() {
  const dispatch = useDispatch();
  const ukgSessionInRedux = useAppSelector(state => state.session.ukgSession);
  /** fetch permissions here so that the data is available to build navigation */
  useGetPermissions();

  useEffect(() => {
    if (isEqual(ukgSessionInRedux, initialSessionState.ukgSession)) {
      const ukgSession = findUKGSession();
      if (ukgSession) {
        dispatch(addUKGSessionToStore(ukgSession));
      }
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <HashRouter>
        <MainNavigation />
        <GlobalNotifications />
        <Routes />
      </HashRouter>
    </Suspense>
  );
}

export default App;
