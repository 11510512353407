import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { DesktopNavigationDropdownProps } from './DesktopNavigationDropdown.types';
import DesktopNavigationGroup from '../DesktopNavigationGroup/DesktopNavigationGroup';
import { KeyboardArrowDownIcon } from '../../../../icons';

const DesktopNavigationDropdown: React.FC<
  DesktopNavigationDropdownProps
> = props => {
  const { title, groups } = props;

  return (
    <Popover className="relative">
      <Popover.Button className="flex mr-6 h-10 px-4 py-2 font-bold rounded focus:outline-none focus:bg-secondary-dark-green hover:bg-secondary-dark-green">
        <p className="text-base text-white font-semibold">{title}</p>
        <KeyboardArrowDownIcon
          alt="Arrow Down Icon"
          className="h-6 w-6 ml-2 fill-white"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-navDropdownMenu overflow-hidden shadow-navigation-dropdown rounded-lg w-fit">
          <div className="navigation-dropdown-caret" />
          <div className="flex divide-x divide-neutral-grey-200 shadow-navigation-dropdown p-4 rounded-lg bg-white w-fit">
            {groups.map((item, index) => (
              <DesktopNavigationGroup
                key={index}
                title={item.title}
                groupItems={item.groupItems}
              />
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default DesktopNavigationDropdown;
