import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import cn from 'classnames';
import { MobileNavigationMenuItemProps } from './MobileNavigationMenuItem.types';

const MobileNavigationMenuItem: React.FC<
  MobileNavigationMenuItemProps
> = props => {
  const { title, path, onClick } = props;
  return (
    <Menu.Item>
      {({ active: isMenuItemActive }) => (
        <NavLink
          to={path}
          className={cn(
            'block h-10 px-8 py-2',
            isMenuItemActive
              ? 'bg-secondary-dark-green'
              : 'hover:bg-secondary-dark-green',
          )}
          onClick={onClick}
        >
          <p className="text-base text-white font-semibold">{title}</p>
        </NavLink>
      )}
    </Menu.Item>
  );
};

export default MobileNavigationMenuItem;
