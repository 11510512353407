import React from 'react';
import { Popover } from '@headlessui/react';
import { MainNavigationProps } from '../MainNavigation.types';
import DesktopNavigationDropdown from './DesktopNavigationDropdown/DesktopNavigationDropdown';
import AimsLogoAsLink from '../../common/AimsLogoAsLink/AimsLogoAsLink';
import DesktopNavigationLink from './DesktopNavigationLink/DesktopNavigationLink';

const DesktopMainNavigation: React.FC<
  MainNavigationProps & { isLoading: boolean }
> = props => {
  const { navItems, isLoading } = props;

  return (
    <header className="flex items-center mx-auto py-2 px-6 h-16 gap-x-10 bg-primary-dark-green">
      <AimsLogoAsLink />
      {!isLoading && (
        <Popover.Group as="nav" className="flex">
          {navItems.map((navItem, index) => {
            if ('path' in navItem) {
              return (
                <DesktopNavigationLink
                  key={index}
                  linkTitle={navItem.title}
                  to={navItem.path}
                  Icon={navItem.Icon}
                />
              );
            }

            return (
              <DesktopNavigationDropdown
                key={index}
                title={navItem.title}
                groups={navItem.groups}
              />
            );
          })}
        </Popover.Group>
      )}
    </header>
  );
};

export default DesktopMainNavigation;
