import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEqual } from 'lodash';
import {
  useOpenManagerPermissionsSessionQuery,
  useReadPermissionDetailsMPQuery,
} from '../../redux/api/ManagerPermissions/managerPermissionsApiSlice';
import { useAppSelector } from '../reduxHooks';
import initialSessionState from '../../redux/session/initialState';

const useGetPermissions = (skip?: boolean) => {
  const ukgSessionInRedux = useAppSelector(state => state.session.ukgSession);
  /** when the page refreshes we should wait until ukgSessionInRedux is set and then call the api */
  const shouldSkip =
    isEqual(ukgSessionInRedux, initialSessionState.ukgSession) || skip;
  const {
    data: sessionData,
    isFetching: sessionFetching,
    isLoading: sessionLoading,
    error: sessionError,
    isSuccess: sessionSuccess,
  } = useOpenManagerPermissionsSessionQuery(undefined, { skip: shouldSkip });

  const {
    data: permissionData,
    error: permissionError,
    isLoading: permissionLoading,
    isFetching: permissionFetching,
  } = useReadPermissionDetailsMPQuery(
    !shouldSkip && sessionSuccess
      ? {
          caid: sessionData?.caid,
          jwt: sessionData?.jwt,
        }
      : skipToken,
  );

  return {
    data: permissionData,
    fetching: sessionFetching || permissionFetching,
    loading: sessionLoading || permissionLoading,
    error: sessionError || permissionError,
  };
};

export default useGetPermissions;
