import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import AimsLogoAsLink from '../../common/AimsLogoAsLink/AimsLogoAsLink';
import { MainNavigationProps } from '../MainNavigation.types';
import MobileNavigationLink from './MobileNavigationLink/MobileNavigationLink';
import MobileNavigationMenu from './MobileNavigationMenu/MobileNavigationMenu';

const MobileMainNavigation: React.FC<
  MainNavigationProps & { isLoading: boolean }
> = props => {
  const { navItems, isLoading } = props;

  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  const openMobileNavigationHandler = () => {
    setIsMobileNavigationOpen(true);
  };

  const closeMobileNavigationHandler = () => {
    setIsMobileNavigationOpen(false);
  };

  return (
    <header className="bg-primary-dark-green">
      <div className="flex items-center justify-between mx-auto h-14 py-2 px-4 gap-x-10">
        <AimsLogoAsLink />
        {!isLoading && (
          <button
            type="button"
            className="text-white"
            onClick={openMobileNavigationHandler}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>
      <Transition as={Fragment} show={isMobileNavigationOpen}>
        <Dialog
          open={isMobileNavigationOpen}
          onClose={closeMobileNavigationHandler}
        >
          <div className="fixed inset-0 z-navDropdownMenu" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="fixed inset-y-0 right-0 z-navDropdownMenu w-full overflow-y-auto bg-primary-dark-green">
              <div className="flex items-center justify-between mx-auto h-14 py-2 px-4 gap-x-10">
                <AimsLogoAsLink />
                <button
                  type="button"
                  className="text-white focus-visible:outline focus-visible:outline-white focus-visible:outline-2"
                  onClick={closeMobileNavigationHandler}
                >
                  <span className="sr-only">Open main menu</span>
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <nav className="flow-root">
                {navItems.map((navItem, index) => {
                  if ('path' in navItem) {
                    return (
                      <MobileNavigationLink
                        key={index}
                        title={navItem.title}
                        to={navItem.path}
                        onClick={closeMobileNavigationHandler}
                      />
                    );
                  }

                  return (
                    <MobileNavigationMenu
                      key={index}
                      title={navItem.title}
                      groups={navItem.groups}
                      onClick={closeMobileNavigationHandler}
                    />
                  );
                })}
              </nav>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </header>
  );
};

export default MobileMainNavigation;
