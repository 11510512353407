// @ts-nocheck
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { fetchRTOdetailsFromApi, SubmitRto } from '../../api/timeOff';
import {
  fetchTimeOffRequestDetailsSuccess,
  fetchTimeOffRequestDetailsFailure,
  submitTimeOffRequestSuccess,
  submitTimeOffRequestFailure,
  clearFormData,
  fetchTimeOffRequestDetails,
} from './timeOffRequestSlice';
import {
  showInfoNotification,
  setError,
} from '../systemError/systemErrorSlice';
import { getFormattedErrorMessage } from '../../utils/data/toastMessage';

export function* fetchTimeOffRequestDetailsSaga({ payload }) {
  try {
    const ukgSession = yield select(state => state.session?.ukgSession);
    const response = yield call(fetchRTOdetailsFromApi, {
      ...payload,
      accessToken: ukgSession?.accessToken ?? '',
    });
    yield put(fetchTimeOffRequestDetailsSuccess(response.data.response));
  } catch (error) {
    yield put({
      type: fetchTimeOffRequestDetailsFailure,
      error: error.message,
    });
  }
}
export function* submitTimeOffRequestSaga({ payload }) {
  const { startDate, endDate, caid, jwt, events } = payload;
  const payloadForSubmit = {
    events,
    caid,
    jwt,
  };
  const payloadForFetchDetails = {
    startDate,
    endDate,
    caid,
    jwt,
  };
  try {
    const ukgSession = yield select(state => state.session?.ukgSession);
    const response = yield call(SubmitRto, {
      ...payloadForSubmit,
      accessToken: ukgSession?.accessToken ?? '',
    });
    yield put(submitTimeOffRequestSuccess(response.data.response));
    yield put(clearFormData());
    yield put(showInfoNotification('Your request was completed successfuly!'));
  } catch (error) {
    const errorMessages = getFormattedErrorMessage(error.response.data);
    yield put({ type: submitTimeOffRequestFailure, error: error.message });
    yield put(setError(errorMessages));
  }
  yield put(fetchTimeOffRequestDetails(payloadForFetchDetails));
}
export function* saga() {
  yield takeEvery(
    'timeOffRequest/fetchTimeOffRequestDetails',
    fetchTimeOffRequestDetailsSaga,
  );
  yield takeEvery(
    'timeOffRequest/submitTimeOffRequest',
    submitTimeOffRequestSaga,
  );
}
