import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useGetPermissions from '../hooks/ManagerPermissions/useGetPermissions';
import { MANAGER_URL_TO_API } from './paths';
import FourOhFourPage from '../pages/FourOhFourPage/FourOhFourPage';

/**
 * This component wraps all admin routes and checks against
 * the permissions api value of a route before allowing access. If a user
 * does not have access they will be shown the FourOhFourPage.
 */
export const ProtectedRoute = () => {
  const location = useLocation();

  const { pathname } = location;
  const { data } = useGetPermissions();

  const havePermission =
    MANAGER_URL_TO_API[pathname] &&
    MANAGER_URL_TO_API[pathname].some(apiName => {
      const apiPermission = data?.apiAccessRights.find(
        api => api.apiName === apiName,
      );
      return apiPermission?.accessType !== 'NONE';
    });

  if (data?.accessProfileName === 'Employee' || !havePermission) {
    return <FourOhFourPage message="You do not have access to this feature." />;
  }
  return <Outlet />;
};
