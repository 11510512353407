import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

export const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState,
  reducers: {
    addUKGSessionToStore: (state, action) => {
      state.ukgSession = {
        ...state.ukgSession,
        ...action.payload,
      };
    },
    revokeUkgAndCleverAntSession: (state,) => {
      state.ukgSession = {
        accessToken: '',
        tokenType: '',
        refreshToken: '',
        expiresInSeconds: 0,
        username: '',
        userRole: '',
      }; 
      state.isRevoked=true;
    },
    fetchRtoSession: state => {
      state.loading = true;
    },
    fetchRtoSessionSuccess: (state, action) => {
      state.rtoSession = action.payload;
    },
    fetchRtoSessionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  addUKGSessionToStore,
  fetchRtoSession,
  fetchRtoSessionSuccess,
  fetchRtoSessionFailure,
  revokeUkgAndCleverAntSession,
} = sessionSlice.actions;
export default sessionSlice.reducer;
