import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { addDays } from 'date-fns';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ResolveRequestsPageTabs } from '../../../pages/ResolveRequestsPage/ResolveRequestsPage.types';
import { SelectOption } from '../../common/Select/Select.types';
import { CheckboxData } from '../../common/MultiSelectFilter/MultiSelectFilter.types';
import {
  NetworkErrorNotification,
  ResolveRequestsContextValue,
} from './ResolveRequestsContext.types';

const ResolveRequestsContext = createContext<
  ResolveRequestsContextValue | undefined
>(undefined);

/**
 * Manages all common states for Resolve Requests Page
 */
function ResolveRequestsProvider({ children }: { children: ReactNode }) {
  /* State of current Tab selection */
  const [selectedTab, setSelectedTab] = useState<ResolveRequestsPageTabs>(
    ResolveRequestsPageTabs.LONG_TERM_LEAVE,
  );

  /** State variable for selected location */
  const [selectedLocation, setSelectedLocation] = useState('');
  const addSelectedLocation = (sLoc: string) => {
    setSelectedLocation(sLoc);
  };

  /** State variable for selected department */
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const addSelectedDepartment = (sDep: string) => {
    setSelectedDepartment(sDep);
  };

  /** State variable for selected job */
  const [selectedJob, setSelectedJob] = useState('');
  const addSelectedJob = (sJob: string) => {
    setSelectedJob(sJob);
  };

  /** State variable for selected types */
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const addSelectedTypes = (sTypes: string[]) => {
    setSelectedTypes(sTypes);
  };
  const defaultStartDate = new Date();

  /** State variable for selected start date */
  const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(
    defaultStartDate,
  );
  const addSelectedStartDate = (startDateToSelect: Date | undefined) => {
    setSelectedStartDate(startDateToSelect);
  };

  const defaultEndDate = addDays(defaultStartDate, 30);

  /** State variable for selected end date */
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(
    defaultEndDate,
  );
  const addSelectedEndDate = (endDateToSelect: Date | undefined) => {
    setSelectedEndDate(endDateToSelect);
  };

  const clearFields = (): void => {
    setSelectedLocation('');
    setSelectedDepartment('');
    setSelectedJob('');
    setSelectedTypes([]);
    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
  };

  /* State of dynamic Select options */
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>(
    [],
  );
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const [typeOptions, setTypeOptions] = useState<CheckboxData[]>([]);

  const [selectedOrgId, setSelectedOrgId] = useState<string>('');

  /** State & handler for showing network errors */
  const [networkErrorNotifications, setNetworkErrorNotifications] = useState<
    NetworkErrorNotification[]
  >([]);

  const showNetworkErrorNotification = (
    error: FetchBaseQueryError | SerializedError | undefined,
  ) => {
    setNetworkErrorNotifications(prevState => {
      return [...prevState, error];
    });
  };

  /** Shared callbacks and variables */
  const value = useMemo(() => {
    return {
      selectedTab,
      setSelectedTab,

      locationOptions,
      setLocationOptions,
      departmentOptions,
      setDepartmentOptions,
      roleOptions,
      setRoleOptions,
      typeOptions,
      setTypeOptions,

      selectedLocation,
      addSelectedLocation,

      selectedDepartment,
      addSelectedDepartment,

      selectedJob,
      addSelectedJob,

      selectedTypes,
      addSelectedTypes,

      selectedStartDate,
      addSelectedStartDate,

      selectedEndDate,
      addSelectedEndDate,

      clearFields,

      selectedOrgId,
      setSelectedOrgId,

      networkErrorNotifications,
      showNetworkErrorNotification,
    };
  }, [
    selectedTab,
    locationOptions,
    departmentOptions,
    roleOptions,
    typeOptions,
    selectedOrgId,
    selectedLocation,
    selectedDepartment,
    selectedJob,
    selectedTypes,
    selectedStartDate,
    selectedEndDate,
    networkErrorNotifications,
  ]);

  return (
    <ResolveRequestsContext.Provider value={value}>
      {children}
    </ResolveRequestsContext.Provider>
  );
}

/**
 * Convenient hook to access ShiftTradeContext value in components
 */
function useResolveRequests() {
  const context = useContext(ResolveRequestsContext);
  if (context === undefined) {
    throw new Error(
      'useResolveRequests must be used within a ResolveRequestsProvider',
    );
  }
  return context;
}

export { ResolveRequestsProvider, useResolveRequests };
