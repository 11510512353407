import React from 'react';
import { NavLink } from 'react-router-dom';
import { MobileNavigationLinkProps } from './MobileNavigationLink.types';

const MobileNavigationLink: React.FC<MobileNavigationLinkProps> = props => {
  const { title, to, onClick } = props;
  return (
    <NavLink
      to={to}
      className="block h-10 px-4 py-2 font-normal text-white focus:outline-none focus:bg-secondary-dark-green hover:bg-secondary-dark-green"
      onClick={onClick}
    >
      <p className="text-base font-semibold">{title}</p>
    </NavLink>
  );
};

export default MobileNavigationLink;
