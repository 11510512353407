import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedStartListening } from '@reduxjs/toolkit';
import { RECEIVE_INIT_STATE } from 'redux-state-sync';
import { isEqual } from 'lodash';
import type { RootState, AppDispatch } from '../store';
import { addUKGSession, clearUKGSession } from '../../hooks/useUKGSession';
import {
  addUKGSessionToStore,
  revokeUkgAndCleverAntSession,
} from '../session/sessionSlice';

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const persistUkgSessionMiddleware = createListenerMiddleware();

const typedStartListening =
  persistUkgSessionMiddleware.startListening as AppStartListening;

typedStartListening({
  predicate: (action, currentState, previousState) => {
    if (action.type === revokeUkgAndCleverAntSession.type) {
      return true;
    }
    if (
      action.type === addUKGSessionToStore.type ||
      action.type === RECEIVE_INIT_STATE
    ) {
      return !isEqual(
        previousState.session.ukgSession,
        currentState.session.ukgSession,
      );
    }
    return false;
  },
  effect: action => {
    if (action.type === addUKGSessionToStore.type) {
      const sessionValueToPersist = action?.payload;
      sessionValueToPersist && addUKGSession(sessionValueToPersist);
    }

    if (action.type === RECEIVE_INIT_STATE && action.payload) {
      const sessionValueToPersist = action?.payload?.session?.ukgSession;
      sessionValueToPersist && addUKGSession(sessionValueToPersist);
    }

    if (action.type === revokeUkgAndCleverAntSession.type) {
      clearUKGSession();
    }
  },
});

export default persistUkgSessionMiddleware.middleware;
