import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { DesktopNavigationGroupItemProps } from './DesktopNavigationGroupItem.types';

const DesktopNavigationGroupItem: React.FC<
  DesktopNavigationGroupItemProps
> = props => {
  const { title, path, description } = props;

  return (
    <NavLink
      to={path}
      className="flex flex-col p-2 rounded focus:outline-none focus:bg-green-50 hover:bg-green-50"
    >
      <Popover.Button as="div">
        <p className="text-base text-neutral-grey-800 font-semibold">{title}</p>
        <p className="text-base text-neutral-grey-600">{description}</p>
      </Popover.Button>
    </NavLink>
  );
};

export default DesktopNavigationGroupItem;
