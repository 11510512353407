import { ReactNode } from 'react';
import { SelectFieldValue } from '../../common/Select/Select.types';

export interface ResolveRequestsTableParentProps {
  children?: ReactNode;
  emptyBody?: ReactNode;
  smallScreenEmptyBody?: ReactNode;
}

export interface ResolveRequestsShiftTrade {
  shiftId: string;
  shiftIcon: string;
  startDateTime: string;
  endDateTime: string;
  duration: string;
  workRule: string;
}

export interface ResolveRequestsRowConfig
  extends Omit<
    ResolveRequests.RequestOverviewResponse,
    | 'orgId'
    | 'employeeName'
    | 'requestedStartDateTime'
    | 'requestedEndDateTime'
    | 'shifts'
    | 'submittedDateTime'
    | 'additionalInformation'
  > {
  index: number;
  dateRequested: { start: string; end: string };
  dateTimeSubmitted?: string;
  isSelectError: boolean;
  checkbox: boolean;
  name: string;
  start: string;
  end: string;
  expanded: boolean;
  createDateTime?: string;
  additionalInformation?: AdditionalInformation;
}

interface AdditionalInformation {
  questions: {
    question: string;
    answer: string;
    comment: string;
    leaveType?: string;
  }[];
}
export interface ResolveRequestsTableProps {
  resolveRequestData: Array<ResolveRequestsRowConfig>;
  selectedResolveRequest: ResolveRequestsRowConfig | null;
  onCheckBoxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  onSelect: (value: SelectFieldValue, index: number) => void;
  singleActionButtons: (
    index: number,
    rowData: ResolveRequestsRowConfig,
  ) => ReactNode;
  isViewOnly?: boolean;
}
export interface ResponsiveResolveRequestTableProps {
  resolveRequestData: Array<ResolveRequestsRowConfig>;
  selectedResolveRequest: ResolveRequestsRowConfig | null;
  setSelectedResolveRequest: React.Dispatch<
    React.SetStateAction<ResolveRequestsRowConfig | null>
  >;
  actionButtons: ReactNode;
  inInitState: boolean;
  submitData: (
    rowData: ResolveRequestsRowConfig,
    typeAction: '1' | '2',
    actionModalData: {
      additionalComments: string | null;
      reason: SelectFieldValue;
    },
  ) => void;
  isViewOnly: boolean;
}

export interface ResolveRequestsExpandableCardProps {
  cardHeader?: string | null;
  leaveOverViewSection?: LeaveOverviewSection | null;
  correspondentPosition?: CorrespondentPosition | null;
  children?: ReactNode | ReactNode[];
  accrualTableHeaders?: Array<string>;
  isAdditionalInformation?: boolean;
  isCardComponent?: boolean;
}

export type LeaveOverviewSection = {
  Name: string;
  'Date(s) Requested'?: string;
  'Start/End'?: string;
  'Trading With'?: string;
  Duration?: string;
  Location: string;
  Department: string;
  Job: string;
  'Leave Type': string;
  'Icon(s)': string;
  'Date/Time Submitted': string;
  Paycode?: string;
  'Reason for Edit'?: string;
  'Deadline to Review'?: string;
  'Tap In/Out Time'?: string;
  Exception?: string;
  Reason?: string;
  'Date/Time Sbmitted'?: string;
  'Named Replacements this year'?: string;
  'Requested Time Off'?: string;
};

export type CorrespondentPosition = {
  location?: string;
  dept: string;
  job: string;
  name: string;
  tradingwith?: string;
  date: string;
  startEnd?: string;
  duration?: string;
  type: string | string[];
  requestedTimeOff?: string;
  icons?: string[];
  datesubmited: string;
  paycode?: string; // RR: Historical Edits only
  editReason?: string; // RR: Historical Edits only
  deadlineToReview?: string; // RR: Premiums, Timecard Exceptions
  tapTime?: string; // RR: Timecard Exceptions
  exception?: string; // RR: Timecard Exceptions
  reason?: string; // RR: Timecard Exceptions
  tradingWith?: string; // RR: Shift Trade Requests
  dateTimeSubmitted?: string; // RR: Shift Trade Requests
  totalNameReplacements?: number; // RR: Shift Trade Requests
};

export type AccuralsProps = {
  accruedType: string;
  balance: string;
};

export enum ManagerActions {
  approve = 'Approve',
  deny = 'Deny',
}

export enum RequestDenyReasons {
  doesNotQualify = 'Does not meet operational needs',
  contactManager = 'Please contact Manager/Delegate',
  maxNamedReplacementPerYear = 'Met maximum number per year (only for Named Replacement)',
}

export enum RequestDenyReasonsValues {
  doesNotQualify = 'Does not qualify',
  contactManager = 'Contact manager',
  maxNamedReplacementPerYear = 'Met maximum number per year',
}

export enum TradesRequestDenyReasonsValues {
  doesNotQualify = 'Does not meet operational needs',
  contactManager = 'Contact Manager/Delegate',
  maxNamedReplacementPerYear = 'Met maximum number per year',
}

export enum TradeShiftTypes {
  shiftTrade = 'Shift Trade',
  nameReplacement = 'Name Replacement',
}

export type ResolveRequestsUpdateData =
  CleverAntAPI.ResolveRequestsUpdateRequestsInput['body'];
export interface ResolveRequestExpandedRowProps {
  rowData: ResolveRequestsRowConfig;
  index: number;
  singleActionButtons: (
    index: number,
    rowData: ResolveRequestsRowConfig,
  ) => ReactNode;
}

export enum TapApproveReasons {
  payTap = 'Pay from Tap',
  paySchedule = 'Pay from Schedule',
}
