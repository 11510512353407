import { createSlice } from '@reduxjs/toolkit';

export type SystemErrorInitialState = {
  error: string[] | null;
  message: TodoTypeMe | null;
  success: boolean | null;
};

const initialState: SystemErrorInitialState = {
  error: null,
  message: '',
  success: null,
};

const systemErrorSlice = createSlice({
  name: 'uiFeedback',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },

    clearError: state => {
      state.error = null;
      state.success = null;
    },
    showInfoNotification: (state, message) => {
      state.message = message;
      state.success = true;
    },
  },
});

export const { setError, clearError, showInfoNotification } =
  systemErrorSlice.actions;

export default systemErrorSlice.reducer;
