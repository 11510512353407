import eeSchedule from '../responses/schedule/employee-schedule.json';
import eeRtoInitializeSchedule from '../responses/rto/initialize.json';
import eeRtoLoadSchedule from '../responses/rto/load-schedule.json';
import eeRtoSubmitRequests from '../responses/rto/submit-requests.json';
import requestLeaveInitialize from '../responses/requestLeave/initialize.json';
import requestLeaveLoadSelectedDetails from '../responses/requestLeave/load-selected-details.json';
import requestLeaveSubmit from '../responses/requestLeave/submit.json';
import requestLeaveSubmitWithQuestions from '../responses/requestLeave/submit-with-questions.json';
import requestLeaveSubmitWithAnswers from '../responses/requestLeave/submit-with-answers.json';
import resolveTapExceptionLoadExceptions from '../responses/resolvetapExceptions/loadExceptions.json';
import resolveTapExceptionSubmit from '../responses/resolvetapExceptions/submit.json';
import shiftBidLoadOpenShifts from '../responses/shiftBid/loadOpenShifts.json';
import shiftBidSubmit from '../responses/shiftBid/submit.json';
import shiftTradeLoadEmployeeSchedule from '../responses/shiftTrade/loadEmployeeSchedule.json';
import shiftTradeLoadColleagueList from '../responses/shiftTrade/loadColleagueList.json';
import shiftTradeLoadColleagueSchedule from '../responses/shiftTrade/loadColleagueSchedule.json';
import shiftTradeLoadRequests from '../responses/shiftTrade/loadRequests.json';
import shiftTradeSubmitRequestsShiftTrade from '../responses/shiftTrade/submitRequestShiftTrade.json';
import shiftTradeSubmitRequestsNameReplacement from '../responses/shiftTrade/submitRequestNameReplacement.json';
import shiftTradeUpdateRequests from '../responses/shiftTrade/updateRequests.json';
import manageAverageHoursInitialize from '../responses/manageAverageHours/initialize.json';
import manageAverageHoursCalculateAverage from '../responses/manageAverageHours/calculateAverageHours.json';
import manageAverageHoursloadRequests from '../responses/manageAverageHours/loadRequests.json';
import manageAverageHoursRunTerminationReport from '../responses/manageAverageHours/runTerminationReport.json';
import manageAverageHoursSubmit from '../responses/manageAverageHours/submit.json';
import resolveRequestsInitialize from '../responses/resolveRequests/initialize.json';
import resolveRequestsLoadRequests from '../responses/resolveRequests/loadRequests.json';
import resolveRequestsUpdateRequests from '../responses/resolveRequests/updateRequests.json';
import resolveLongTermLeaveRequestsInitialize from '../responses/resolveLongTermLeaveRequests/initialize.json';
import resolveLongTermLeaveRequestsLoadRequests from '../responses/resolveLongTermLeaveRequests/loadRequests.json';
import resolveLongTermLeaveRequestsSubmitRequests from '../responses/resolveLongTermLeaveRequests/submit.json';
import manageUnitScheduleInitialize from '../responses/manageUnitSchedule/initialize.json';
import manageUnitScheduleLoadSchedules from '../responses/manageUnitSchedule/loadSchedules.json';
import premiumRequestLoadPremiumData from '../responses/premiumShifts/loadPremiumData.json';
import premiumRequestSubmitRequests from '../responses/premiumShifts/submitRequests.json';
import manageOpenShiftsInitialize from '../responses/manageOpenShifts/initialize.json';
import manageOpenShiftsLoadOpenShifts from '../responses/manageOpenShifts/loadOpenShifts.json';
import manageOpenShiftsLoadSelectedOpenShift from '../responses/manageOpenShifts/loadSelectedOpenShift.json';
import manageOpenShiftsSubmit from '../responses/manageOpenShifts/submit.json';
import requestHistoricalEditsLoadHistoricalData from '../responses/requestHistoricalEdits/loadHistoricalData.json';
import requestHistoricalEditsSubmitRequests from '../responses/requestHistoricalEdits/submitRequests.json';
import scheduleEditorInitialize from '../responses/scheduleEditor/initialize.json';
import scheduleEditorLoadSchedules from '../responses/scheduleEditor/loadSchedules.json';
import scheduleEditorSubmit from '../responses/scheduleEditor/submit.json';
import manageVacanciesInitialize from '../responses/manageVacancies/initialize.json';
import manageVacanciesLoadRotationVacancies from '../responses/manageVacancies/loadRotationVacancies.json';
import manageVacanciesLoadRowSchedule from '../responses/manageVacancies/loadRowSchedule.json';
import manageVacanciesSubmitOffers from '../responses/manageVacancies/submitOffers.json';
import rowAssignmentInitalize from '../responses/rowAssignment/initialize.json';
import rowAssignmentLoadAssignments from '../responses/rowAssignment/loadAssignments.json';
import rowAssignmentLoadMasters from '../responses/rowAssignment/loadMasters.json';
import rowAssignmentLoadSchedules from '../responses/rowAssignment/loadSchedules.json';
import rowAssignmentRunTerminationReport from '../responses/rowAssignment/runTerminationReport.json';
import rowAssignmentSubmitAssignments from '../responses/rowAssignment/submitAssignments.json';
import bookOnInitialize from '../responses/bookOn/initialize.json';
import bookOnLoadOpenShiftData from '../responses/bookOn/loadOpenShiftData.json';
import bookOnLoadAssignedShiftData from '../responses/bookOn/loadAssignedShiftData.json';
import bookOnLoadAssignPayCodeData from '../responses/bookOn/loadAssignPayCodeData.json';
import bookOnCreateOpenShift from '../responses/bookOn/createOpenShift.json';
import bookOnAssignShift from '../responses/bookOn/assignShift.json';
import bookOnAssignPayCode from '../responses/bookOn/assignPayCode.json';
import callListByEmployeeInitialize from '../responses/CallListByEmployee/initialize.json';
import callListByEmployeeLoadEmployeeList from '../responses/CallListByEmployee/loadEmployeeList.json';
import callListByEmployeeLoadSelectedEmployee from '../responses/CallListByEmployee/loadSelectedEmployee.json';
import callListByEmployeeSubmit from '../responses/CallListByEmployee/submit.json';
import callListInitialize from '../responses/callList/initialize.json';
import callListLoadOpenShifts from '../responses/callList/callListLoadOpenShifts.json';
import callListLoadList from '../responses/callList/loadList.json';
import callListSubmit from '../responses/callList/submit.json';
import callListRefusePendingApproval from '../responses/callList/refusePendingApproval.json';
import submitForEmployeeInitialize from '../responses/submitForEmployee/initialize.json';
import submitForEmployeeLoadList from '../responses/submitForEmployee/loadEmployeeList.json';
import requestAbsenceInitialize from '../responses/requestAbsence/initialize.json';
import requestAbsenceLoadSelectedDetails from '../responses/requestAbsence/loadSelectedDetails.json';
import requestAbsenceSubmit from '../responses/requestAbsence/submit.json';
import requestAbsenceSubmitWithAnswers from '../responses/requestAbsence/submitWithAnswers.json';
import cancelRequestInitialize from '../responses/cancelRequests/initialize.json';
import cancelRequestLoadRequests from '../responses/cancelRequests/loadRequests.json';
import cancelRequestSubmit from '../responses/cancelRequests/submit.json';
import managerPermissionsReadPermissionDetails from '../responses/managerPermissions/readPermissionDetails.json';

import { apiParams } from '../../api/params';

/**
 * Endpoint definitions using https://mswjs.io/ to intercept and mock network requests.
 *
 *
 * To enable an endpoint for mocking
 * scroll to the bottom of this file and add an endpoint object to `endPointConfig.mockedEndpoints`
 * ```typescript
 * // eg1. to mock all requestLeave endpoints
 * const endPointConfig = {
 *    mockedEndpoints: [...endpoints.requestLeave],
 * }
 * ```
 * ```typescript
 * // eg2. to mock a specific requestLeave endpoint
 * const endPointConfig = {
 *    mockedEndpoints: [endpoints.requestLeave[0]],
 * }
 * ```
 * note: mocking an `/open` endpoint works by matching the `templatename` value in an endpoint object
 * to the templatename of the incoming request.
 */
export const endpoints = {
  mySchedule: [
    {
      templatename: process.env.REACT_APP_API_MY_SCHEDULE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'readEmpSchedule',
      searchParams: [
        'accessToken',
        'caid',
        'select',
        'startDate',
        'endDate',
        'action',
      ],
      response: eeSchedule,
    },
  ],
  requestTimeOff: [
    {
      templatename: process.env.REACT_APP_API_RTO_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}/`,
      action: 'initializeRequestTimeOff',
      searchParams: ['accessToken', 'caid', 'action'],
      response: eeRtoInitializeSchedule,
    },
    {
      templatename: process.env.REACT_APP_API_RTO_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSchedule',
      searchParams: [
        'accessToken',
        'caid',
        'select',
        'startDate',
        'endDate',
        'action',
      ],
      response: eeRtoLoadSchedule,
    },
    {
      templatename: process.env.REACT_APP_API_RTO_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: eeRtoSubmitRequests,
    },
  ],
  requestLeave: [
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeRequestLeave',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestLeaveInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSelectedDetails',
      searchParams: [
        'accessToken',
        'caid',
        'orgId',
        'startDate',
        'endDate',
        'action',
      ],
      response: requestLeaveLoadSelectedDetails,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitWithoutQuestions',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestLeaveSubmit,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitWithQuestions',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestLeaveSubmitWithQuestions,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitWithAnswers',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestLeaveSubmitWithAnswers,
    },
  ],
  resolveTapException: [
    {
      templatename: process.env.REACT_APP_API_TE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadExceptions',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveTapExceptionLoadExceptions,
    },
    {
      templatename: process.env.REACT_APP_API_TE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitTapException',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveTapExceptionSubmit,
    },
  ],
  cancelRequests: [
    {
      templatename: process.env.REACT_APP_API_CR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeCancelRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: cancelRequestInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_CR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRequestsCancelRequests',
      searchParams: [
        'accessToken',
        'caid',
        'startDate',
        'endDate',
        'action',
        'orgId',
      ],
      response: cancelRequestLoadRequests,
    },
    {
      templatename: process.env.REACT_APP_API_CR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitCancelRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: cancelRequestSubmit,
    },
  ],
  shiftBid: [
    {
      templatename: process.env.REACT_APP_API_SHIFT_BID_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadOpenShifts',
      searchParams: ['accessToken', 'caid', 'startDate', 'endDate', 'action'],
      response: shiftBidLoadOpenShifts,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_BID_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: shiftBidSubmit,
    },
  ],
  shiftTrade: [
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadEmployeeSchedule',
      searchParams: ['accessToken', 'caid', 'startDate', 'endDate', 'action'],
      response: shiftTradeLoadEmployeeSchedule,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadColleagueList',
      searchParams: ['accessToken', 'caid', 'action'],
      response: shiftTradeLoadColleagueList,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadColleagueSchedule',
      searchParams: [
        'accessToken',
        'caid',
        'startDate',
        'endDate',
        'personNumber',
        'isNR',
        'action',
      ],
      response: shiftTradeLoadColleagueSchedule,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRequests',
      searchParams: [
        'accessToken',
        'caid',
        'requestType',
        'action',
        'startDate',
        'endDate',
      ],
      response: shiftTradeLoadRequests,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitRequestShiftTrade',
      searchParams: ['accessToken', 'caid', 'action'],
      response: shiftTradeSubmitRequestsShiftTrade,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'shiftTradeSubmitRequestNameReplacement',
      searchParams: ['accessToken', 'caid', 'action'],
      response: shiftTradeSubmitRequestsNameReplacement,
    },
    {
      templatename: process.env.REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'updateRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: shiftTradeUpdateRequests,
    },
  ],
  manageAverageHours: [
    {
      templatename: process.env.REACT_APP_API_MAH_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeManageAverageHours',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageAverageHoursInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_MAH_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRequests',
      searchParams: ['accessToken', 'caid', 'orgId', 'action'],
      response: manageAverageHoursloadRequests,
    },
    {
      templatename: process.env.REACT_APP_API_MAH_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'runTerminationReport',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageAverageHoursRunTerminationReport,
    },
    {
      templatename: process.env.REACT_APP_API_MAH_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'calculateAverageHours',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageAverageHoursCalculateAverage,
    },
    {
      templatename: process.env.REACT_APP_API_MAH_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageAverageHoursSubmit,
    },
  ],
  resolveRequests: [
    {
      templatename: process.env.REACT_APP_API_RR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeResolveRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveRequestsInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_RR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRequests',
      searchParams: [
        'accessToken',
        'caid',
        'startDate',
        'endDate',
        'orgId',
        'select',
        'action',
      ],
      response: resolveRequestsLoadRequests,
    },
    {
      templatename: process.env.REACT_APP_API_RR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'updateRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveRequestsUpdateRequests,
    },
  ],
  resolveLongTermLeaveRequests: [
    {
      templatename:
        process.env.REACT_APP_API_RESOLVE_LONG_TERM_LEAVE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeLongTermLeaveRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveLongTermLeaveRequestsInitialize,
    },
    {
      templatename:
        process.env.REACT_APP_API_RESOLVE_LONG_TERM_LEAVE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRequests',
      searchParams: [
        'accessToken',
        'caid',
        'orgId',
        'startDate',
        'endDate',
        'action',
      ],
      response: resolveLongTermLeaveRequestsLoadRequests,
    },
    {
      templatename:
        process.env.REACT_APP_API_RESOLVE_LONG_TERM_LEAVE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: resolveLongTermLeaveRequestsSubmitRequests,
    },
  ],
  manageUnitSchedule: [
    {
      templatename:
        process.env.REACT_APP_API_MANAGE_UNIT_SCHEDULE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeManageUnitSchedule',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageUnitScheduleInitialize,
    },
    {
      templatename:
        process.env.REACT_APP_API_MANAGE_UNIT_SCHEDULE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSchedules',
      searchParams: [
        'accessToken',
        'caid',
        'startDate',
        'endDate',
        'deptId',
        'action',
      ],
      response: manageUnitScheduleLoadSchedules,
    },
  ],
  premiumRequest: [
    {
      templatename: process.env.REACT_APP_API_PREMIUM_REQUEST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadPremiumData',
      searchParams: ['accessToken', 'caid', 'action'],
      response: premiumRequestLoadPremiumData,
    },
    {
      templatename: process.env.REACT_APP_API_PREMIUM_REQUEST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitRequests',
      searchParams: ['accessToken', 'caid', 'action', 'orgId'],
      response: premiumRequestSubmitRequests,
    },
  ],
  manageOpenShifts: [
    {
      templatename: process.env.REACT_APP_API_MANAGE_OPEN_SHIFTS_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeMOS',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageOpenShiftsInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_OPEN_SHIFTS_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadOpenShifts',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
      ],
      response: manageOpenShiftsLoadOpenShifts,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_OPEN_SHIFTS_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSelectedOpenShift',
      searchParams: ['accessToken', 'caid', 'action', 'openShiftId'],
      response: manageOpenShiftsLoadSelectedOpenShift,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_OPEN_SHIFTS_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageOpenShiftsSubmit,
    },
  ],
  requestHistoricEdits: [
    {
      templatename: process.env.REACT_APP_API_HISTORICAL_EDITS_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadHistoricalData',
      searchParams: ['accessToken', 'caid', 'startDate', 'endDate', 'action'],
      response: requestHistoricalEditsLoadHistoricalData,
    },
    {
      templatename: process.env.REACT_APP_API_PREMIUM_REQUEST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitRequests',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestHistoricalEditsSubmitRequests,
    },
  ],
  scheduleEditor: [
    {
      templatename: process.env.REACT_APP_API_SCHEDULE_EDITOR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeSE',
      searchParams: ['accessToken', 'caid', 'action'],
      response: scheduleEditorInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_SCHEDULE_EDITOR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSchedules',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
      ],
      response: scheduleEditorLoadSchedules,
    },
    {
      templatename: process.env.REACT_APP_API_SCHEDULE_EDITOR_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: scheduleEditorSubmit,
    },
  ],
  manageVacancies: [
    {
      templatename: process.env.REACT_APP_API_MANAGE_VACANCIES_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeMV',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageVacanciesInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_VACANCIES_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRotationVacancies',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
      ],
      response: manageVacanciesLoadRotationVacancies,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_VACANCIES_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadRowSchedule',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'rowId',
      ],
      response: manageVacanciesLoadRowSchedule,
    },
    {
      templatename: process.env.REACT_APP_API_MANAGE_VACANCIES_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitOffers',
      searchParams: ['accessToken', 'caid', 'action'],
      response: manageVacanciesSubmitOffers,
    },
  ],
  rowAssignment: [
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeRA',
      searchParams: ['accessToken', 'caid', 'action'],
      response: rowAssignmentInitalize,
    },
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadAssignments',
      searchParams: ['accessToken', 'caid', 'action', 'rotationId'],
      response: rowAssignmentLoadAssignments,
    },
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadMasters',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'locationId',
        'deptId',
        'orgId',
      ],
      response: rowAssignmentLoadMasters,
    },
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSchedules',
      searchParams: ['accessToken', 'caid', 'action', 'startDate', 'endDate'],
      response: rowAssignmentLoadSchedules,
    },
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'runTerminationReport',
      searchParams: ['accessToken', 'caid', 'action'],
      response: rowAssignmentRunTerminationReport,
    },
    {
      templatename: process.env.REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitAssignments',
      searchParams: ['accessToken', 'caid', 'action'],
      response: rowAssignmentSubmitAssignments,
    },
  ],
  bookOn: [
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeBO',
      searchParams: ['accessToken', 'caid', 'action'],
      response: bookOnInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadOpenShiftData',
      searchParams: ['accessToken', 'caid', 'action', 'startDate', 'orgId'],
      response: bookOnLoadOpenShiftData,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadAssignShiftData',
      searchParams: ['accessToken', 'caid', 'action', 'startDate', 'orgId'],
      response: bookOnLoadAssignedShiftData,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadAssignPayCodeData',
      searchParams: ['accessToken', 'caid', 'action', 'startDate', 'orgId'],
      response: bookOnLoadAssignPayCodeData,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'createOpenShift',
      searchParams: ['accessToken', 'caid', 'action'],
      response: bookOnCreateOpenShift,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'assignShift',
      searchParams: ['accessToken', 'caid', 'action'],
      response: bookOnAssignShift,
    },
    {
      templatename: process.env.REACT_APP_API_BOOK_ON_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'assignPayCode',
      searchParams: ['accessToken', 'caid', 'action'],
      response: bookOnAssignPayCode,
    },
  ],
  callListByEmployee: [
    {
      templatename:
        process.env.REACT_APP_API_CALL_LIST_BY_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeCLBE',
      searchParams: ['accessToken', 'caid', 'action'],
      response: callListByEmployeeInitialize,
    },
    {
      templatename:
        process.env.REACT_APP_API_CALL_LIST_BY_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadEmployeeList',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
        'callListType',
      ],
      response: callListByEmployeeLoadEmployeeList,
    },
    {
      templatename:
        process.env.REACT_APP_API_CALL_LIST_BY_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSelectedEmployee',
      searchParams: ['accessToken', 'caid', 'action', 'personNumber'],
      response: callListByEmployeeLoadSelectedEmployee,
    },
    {
      templatename:
        process.env.REACT_APP_API_CALL_LIST_BY_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: callListByEmployeeSubmit,
    },
  ],
  callList: [
    {
      templatename: process.env.REACT_APP_API_CALL_LIST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeCL',
      searchParams: ['accessToken', 'caid', 'action'],
      response: callListInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_CALL_LIST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadOpenShiftsCL',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
      ],
      response: callListLoadOpenShifts,
    },
    {
      templatename: process.env.REACT_APP_API_CALL_LIST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadList',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'openShiftId',
        'openShiftType',
      ],
      response: callListLoadList,
    },
    {
      templatename: process.env.REACT_APP_API_CALL_LIST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submit',
      searchParams: ['accessToken', 'caid', 'action'],
      response: callListSubmit,
    },
    {
      templatename: process.env.REACT_APP_API_CALL_LIST_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'refusePendingApproval',
      searchParams: ['accessToken', 'caid', 'action', 'openShiftId'],
      response: callListRefusePendingApproval,
    },
  ],
  submitForEmployee: [
    {
      templatename: process.env.REACT_APP_API_SUBMIT_FOR_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeSFE',
      searchParams: ['accessToken', 'caid', 'action'],
      response: submitForEmployeeInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_SUBMIT_FOR_EMPLOYEE_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadEmployeeList',
      searchParams: [
        'accessToken',
        'caid',
        'action',
        'startDate',
        'endDate',
        'orgId',
      ],
      response: submitForEmployeeLoadList,
    },
  ],
  managerPermissions: [
    {
      templatename: process.env.REACT_APP_API_MANAGER_PERMISSIONS,
      url: `${apiParams.runActionUrl}`,
      action: 'readPermissionDetails',
      searchParams: ['accessToken', 'caid', 'action'],
      response: managerPermissionsReadPermissionDetails,
    },
  ],
  requestAbsence: [
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'initializeRequestAbsence',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestAbsenceInitialize,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'loadSelectedDetailsRequestAbsence',
      searchParams: ['accessToken', 'caid', 'action', 'startDate', 'endDate'],
      response: requestAbsenceLoadSelectedDetails,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitRequestAbsence',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestAbsenceSubmit,
    },
    {
      templatename: process.env.REACT_APP_API_RL_READ_TEMPLATE,
      url: `${apiParams.runActionUrl}`,
      action: 'submitWithAnswersRequestAbsence',
      searchParams: ['accessToken', 'caid', 'action'],
      response: requestAbsenceSubmitWithAnswers,
    },
  ],
};

/**
 * contains the MSW configuration
 */
export const endPointConfig: {
  /** the url MSW should use to intercept network requests */
  baseUrl: 'http://localhost:3001/api';
  /** the active list of endpoints that should be mocked */
  mockedEndpoints: {
    templatename: string;
    url: string;
    action: string;
    searchParams: string[];
    response: unknown;
  }[];
} = {
  baseUrl: 'http://localhost:3001/api',
  mockedEndpoints: [
    // uncomment a line below to mock the respective feature
    // ...endpoints.bookOn,
    // ...endpoints.callList,
    // ...endpoints.callListByEmployee,
    // ...endpoints.cancelRequests,
    // ...endpoints.manageAverageHours,
    // ...endpoints.manageOpenShifts,
    // ...endpoints.managerPermissions,
    // ...endpoints.manageUnitSchedule,
    // ...endpoints.manageVacancies,
    // ...endpoints.mySchedule,
    // ...endpoints.premiumRequest,
    // ...endpoints.requestAbsence,
    // ...endpoints.requestHistoricEdits,
    // ...endpoints.requestLeave,
    // ...endpoints.requestTimeOff,
    // ...endpoints.resolveLongTermLeaveRequests,
    // ...endpoints.resolveRequests,
    // ...endpoints.resolveTapException,
    // ...endpoints.rowAssignment,
    // ...endpoints.scheduleEditor,
    // ...endpoints.shiftBid,
    // ...endpoints.shiftTrade,
    // ...endpoints.submitForEmployee,
  ],
};
