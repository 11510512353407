import React from 'react';
import {
  HISTORIC_ADD_EDIT,
  MANAGER_URL_TO_API,
  MANAGE_AVERAGE_HOURS,
  MANAGE_VACANCIES,
  MY_REQUESTS,
  RESOLVE_REQUESTS,
  ROW_ASSIGNMENTS,
  SCHEDULE,
  SCHEDULE_EDITOR,
  SHIFT_BID_REQUESTS,
  SHIFT_TRADE,
  SPLIT_SHIFT_REQUESTS,
  SUBMIT_FOR_EMPLOYEE,
  TAP_EXCEPTION,
  TIMECARD_PREMIUMS,
  UNIT_SCHEDULE,
} from '../routes/paths';
import { HomeIcon } from '../icons';

const enableSplitShifts =
  process.env.REACT_APP_BETA_FEATURE_SPLIT_SHIFTS === 'true';
const enableHistoricAddEdit =
  process.env.REACT_APP_BETA_FEATURE_HISTORIC_ADD_EDIT === 'true';

/* Routes and menu names for navigation drop down menu */
export const EmployeeMainNavigationItems: MainNavigation.MainNavigation = [
  {
    title: 'My Schedule',
    groups: [
      {
        title: 'My Schedule',
        groupItems: [
          {
            title: 'Schedule',
            description: 'View your schedule',
            path: SCHEDULE,
          },
        ],
      },
      {
        title: 'Manage My Shifts',
        groupItems: [
          {
            title: 'Shift Bid',
            description: 'Bid on open shifts',
            path: SHIFT_BID_REQUESTS,
          },
          {
            title: 'Shift Trade',
            description: 'Trade shifts with a coworker',
            path: SHIFT_TRADE,
          },
        ],
      },
      {
        title: 'Manage My Time',
        groupItems: [
          {
            title: 'Tap Exception',
            description: 'Resolve tap exceptions',
            path: TAP_EXCEPTION,
          },
          {
            title: 'Premium and Timecards',
            description: 'Request edits to schedule in the current pay period',
            path: TIMECARD_PREMIUMS,
          },
          ...(enableHistoricAddEdit
            ? [
                {
                  title: 'Historic Add + Edit',
                  description:
                    'Request edits to schedule for previous pay periods',
                  path: HISTORIC_ADD_EDIT,
                },
              ]
            : []),
        ],
      },
    ],
  },
  {
    title: 'My Requests',
    path: MY_REQUESTS,
  },
  ...(enableSplitShifts
    ? [
        {
          title: 'My Requests (Beta)',
          path: SPLIT_SHIFT_REQUESTS,
        },
      ]
    : []),
];

export const ManagerMainNavigationItems: MainNavigation.MainNavigation = [
  {
    path: '/',
    title: 'Dashboard',
    Icon: <HomeIcon className="fill-white" />,
  },
  {
    title: 'My Schedule',
    groups: [
      {
        title: 'My Schedule',
        groupItems: [
          {
            title: 'Schedule',
            description: 'View your schedule',
            path: SCHEDULE,
          },
        ],
      },
      {
        title: 'Manage My Shifts',
        groupItems: [
          {
            title: 'Shift Bid',
            description: 'Bid on open shifts',
            path: SHIFT_BID_REQUESTS,
          },
          {
            title: 'Shift Trade',
            description: 'Trade shifts with a coworker',
            path: SHIFT_TRADE,
          },
        ],
      },
      {
        title: 'Manage My Time',
        groupItems: [
          {
            title: 'Tap Exception',
            description: 'Resolve tap exceptions',
            path: TAP_EXCEPTION,
          },
          {
            title: 'Premium and Timecards',
            description: 'Request edits to schedule in the current pay period',
            path: TIMECARD_PREMIUMS,
          },
          ...(enableHistoricAddEdit
            ? [
                {
                  title: 'Historic Add + Edit',
                  description:
                    'Request edits to schedule for previous pay periods',
                  path: HISTORIC_ADD_EDIT,
                },
              ]
            : []),
        ],
      },
    ],
  },
  {
    title: 'Requests',
    groups: [
      {
        title: 'Requests',
        groupItems: [
          {
            title: 'My Requests',
            description: 'View your outgoing requests',
            path: MY_REQUESTS,
          },
          {
            title: 'Resolve Requests',
            description: 'View incoming employee requests',
            path: RESOLVE_REQUESTS,
          },
        ],
      },
    ],
  },
  {
    title: 'Scheduling',
    groups: [
      {
        title: 'Editing',
        groupItems: [
          {
            title: 'Unit Schedule',
            description: 'View your Schedule',
            path: UNIT_SCHEDULE,
            visibility: {
              visibleOnMobile: false,
            },
          },
          {
            title: 'Schedule Editor',
            description: 'Add and edit time assignments',
            path: SCHEDULE_EDITOR,
            visibility: {
              visibleOnMobile: false,
            },
          },
        ],
      },
      {
        title: 'Process',
        groupItems: [
          {
            title: 'Row Assignment',
            description: 'View new assignments',
            path: ROW_ASSIGNMENTS,
          },
          {
            title: 'Manage Vacancies',
            description: 'Manage vacant shifts',
            path: MANAGE_VACANCIES,
          },
          {
            title: 'Manage Average Hours',
            description:
              'Add long-term leave average hours to empoyee schedule',
            path: MANAGE_AVERAGE_HOURS,
          },
        ],
      },
    ],
  },
  {
    title: 'Admin',
    groups: [
      {
        title: 'Admin',
        groupItems: [
          {
            title: 'Submit for employee',
            description: 'Create requests on behalf of employees',
            path: SUBMIT_FOR_EMPLOYEE,
          },
        ],
      },
    ],
  },
  ...(enableSplitShifts
    ? [
        {
          title: 'My Requests (Beta)',
          path: SPLIT_SHIFT_REQUESTS,
        },
      ]
    : []),
];

export const getNavigationItems = (
  data: CleverAntAPI.MPReadPermissionsResponse | undefined,
): MainNavigation.MainNavigation => {
  const hasPermissions = (path: string): boolean => {
    return (
      MANAGER_URL_TO_API[path] &&
      MANAGER_URL_TO_API[path].some(apiName => {
        const apiPermission = data?.apiAccessRights.find(
          api => api.apiName === apiName,
        );
        return Boolean(data) && apiPermission?.accessType !== 'NONE';
      })
    );
  };

  if (!data || data?.apiAccessRights.length === 0)
    return EmployeeMainNavigationItems;

  // if all items in a group have an access level of NONE exclude the entire group
  // if some items in a group have an access level of NONE exclude just those items
  // the order of the array.push calls will dicate the order on the u.i.
  const navItems = [];
  hasPermissions('/') &&
    navItems.push({
      path: '/',
      title: 'Dashboard',
      Icon: <HomeIcon className="fill-white" />,
    });

  navItems.push({
    title: 'My Schedule',
    groups: [
      {
        title: 'My Schedule',
        groupItems: [
          {
            title: 'Schedule',
            description: 'View your schedule',
            path: SCHEDULE,
          },
        ],
      },
      {
        title: 'Manage My Shifts',
        groupItems: [
          {
            title: 'Shift Bid',
            description: 'Bid on open shifts',
            path: SHIFT_BID_REQUESTS,
          },
          {
            title: 'Shift Trade',
            description: 'Trade shifts with a coworker',
            path: SHIFT_TRADE,
          },
        ],
      },
      {
        title: 'Manage My Time',
        groupItems: [
          {
            title: 'Tap Exception',
            description: 'Resolve tap exceptions',
            path: TAP_EXCEPTION,
          },
          {
            title: 'Premium and Timecards',
            description: 'Request edits to schedule in the current pay period',
            path: TIMECARD_PREMIUMS,
          },
          ...(enableHistoricAddEdit
            ? [
                {
                  title: 'Historic Add + Edit',
                  description:
                    'Request edits to schedule for previous pay periods',
                  path: HISTORIC_ADD_EDIT,
                },
              ]
            : []),
        ],
      },
    ],
  });
  const requestGroupItems = [
    {
      title: 'My Requests',
      description: 'View your outgoing requests',
      path: MY_REQUESTS,
    },
    ...(enableSplitShifts
      ? [
          {
            title: 'My Requests (Beta)',
            path: SPLIT_SHIFT_REQUESTS,
          },
        ]
      : []),
  ];
  hasPermissions(RESOLVE_REQUESTS) &&
    requestGroupItems.push({
      title: 'Resolve Requests',
      description: 'View incoming employee requests',
      path: RESOLVE_REQUESTS,
    });
  navItems.push({
    title: 'Requests',
    groups: [
      {
        title: 'Requests',
        groupItems: requestGroupItems,
      },
    ],
  });

  const schedulingGroups: MainNavigation.Group[] = [];

  const schedulingEditingGroupItems: MainNavigation.GroupItem[] = [];
  hasPermissions(UNIT_SCHEDULE) &&
    schedulingEditingGroupItems.push({
      title: 'Unit Schedule',
      description: 'View your Schedule',
      path: UNIT_SCHEDULE,
      visibility: {
        visibleOnMobile: false,
      },
    });

  hasPermissions(SCHEDULE_EDITOR) &&
    schedulingEditingGroupItems.push({
      title: 'Schedule Editor',
      description: 'Add and edit time assignments',
      path: SCHEDULE_EDITOR,
      visibility: {
        visibleOnMobile: false,
      },
    });

  schedulingEditingGroupItems.length > 0 &&
    schedulingGroups.push({
      title: 'Editing',
      groupItems: schedulingEditingGroupItems,
    });

  const schedulingProcessingGroupItems = [];
  hasPermissions(ROW_ASSIGNMENTS) &&
    schedulingProcessingGroupItems.push({
      title: 'Row Assignment',
      description: 'View new assignments',
      path: ROW_ASSIGNMENTS,
    });
  hasPermissions(MANAGE_VACANCIES) &&
    schedulingProcessingGroupItems.push({
      title: 'Manage Vacancies',
      description: 'Manage vacant shifts',
      path: MANAGE_VACANCIES,
    });
  hasPermissions(MANAGE_AVERAGE_HOURS) &&
    schedulingProcessingGroupItems.push({
      title: 'Manage Average Hours',
      description: 'Add long-term leave average hours to employee schedule',
      path: MANAGE_AVERAGE_HOURS,
    });
  schedulingProcessingGroupItems.length > 0 &&
    schedulingGroups.push({
      title: 'Process',
      groupItems: schedulingProcessingGroupItems,
    });

  schedulingGroups.length > 0 &&
    navItems.push({ title: 'Scheduling', groups: schedulingGroups });

  hasPermissions(SUBMIT_FOR_EMPLOYEE) &&
    navItems.push({
      title: 'Admin',
      groups: [
        {
          title: 'Admin',
          groupItems: [
            {
              title: 'Submit for employee',
              description: 'Create requests on behalf of employees',
              path: SUBMIT_FOR_EMPLOYEE,
            },
          ],
        },
      ],
    });

  return navItems;
};
