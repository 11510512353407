// @ts-nocheck
/* ------------------------------------------------------------------------
 * Helper functions relating to dates & times
 * ------------------------------------------------------------------------ */
import {
  parseISO,
  intervalToDuration,
  format,
  startOfWeek,
  subWeeks,
  isToday,
  isSameDay,
  differenceInHours,
  differenceInCalendarDays,
  addMinutes,
  formatISO,
  addDays,
  isBefore,
  setHours,
  isValid,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';

import { isEmpty } from 'lodash';

function calculateDuration(startTime, endTime) {
  const startHour =
    startTime && parseInt(startTime.toString().substring(0, 2), 10);
  const startMinute =
    startTime && parseInt(startTime.toString().substring(3, 5), 10);
  const endHour = endTime && parseInt(endTime.toString().substring(0, 2), 10);
  const endMinute = endTime && parseInt(endTime.toString().substring(3, 5), 10);

  let durationHour = endHour - startHour;
  let durationMinute = endMinute - startMinute;

  if (durationMinute < 0) {
    durationMinute += 60;
    durationHour -= 1;
  }

  if (durationHour < 0) {
    durationHour += 24;
  }

  const duration = {
    hours: durationHour,
    minutes: durationMinute,
  };

  return duration;
}

// TODO: TO BE DELETED. SHOULD USE ISO FORMAT
export const getDate = date => {
  const newDate = new Date(date);
  return format(newDate, 'MMMM, dd, yyyy');
};

/**
 *'dateTimeStringFormat' function will get the selected time and selected date
 * to format to a date time string
 * @param {Dayjs} timeSelected
 * @param {Date} dateSelected
 * @returns eg: 2023-06-11T14:50:00
 */
export const dateTimeStringFormat = (timeSelected, dateSelected) => {
  let newDateTimeFormatted;

  if (timeSelected && dateSelected) {
    const selectedTime = timeSelected.format('HH:mm:ss');
    const formattedDate = format(dateSelected, 'yyyy-MM-dd');
    newDateTimeFormatted = format(
      parseISO(`${formattedDate}T${selectedTime}`),
      "yyyy-MM-dd'T'HH:mm:ss",
    );
  }

  return newDateTimeFormatted;
};

export const durationRequests = (start, end) => {
  let duration = { hours: null, minutes: null };
  if (start === '00:00' && end === '00:00') {
    duration = {
      hours: 24,
      minutes: 0,
    };
  } else {
    duration = calculateDuration(start, end);
  }
  return duration;
};

/**
 * Calculates duration between two times and returns a time-formatted string
 * @param {string} start datetime
 * @param {string} end datetime
 * @returns {string} "HH:mm hrs"
 */
export const durationAsTime = (start, end) => {
  if (isEmpty(start) || isEmpty(end)) return null;
  const duration = durationRequests(start, end);
  return `${duration.hours}:${duration.minutes
    .toString()
    .padStart(2, '0')} hrs`;
};

/**
 * Calculates duration between two times and returns a time-formatted string
 * @param {string} start time
 * @param {string} end time
 * @returns {string} "H hrs mm min"
 */
export const durationAsHoursMinutes = (start, end) => {
  if (isEmpty(start) || isEmpty(end)) return null;

  const duration = durationRequests(start, end);
  const { hours, minutes } = duration;

  const hoursLabel = hours > 1 ? 'hrs' : 'hr';
  const minutesLabel = minutes > 1 ? 'mins' : 'min';

  if (hours > 0 && minutes > 0) {
    return `${hours} ${hoursLabel} ${minutes} ${minutesLabel}`;
  }

  if (hours > 0 && minutes === 0) {
    return `${hours} ${hoursLabel}`;
  }

  if (hours === 0 && minutes > 0) {
    return `${minutes} ${minutesLabel}`;
  }

  return null;
};

/**
 * Calculates duration between two datetime values and returns a time-formatted string
 * @param {string} start datetime
 * @param {string} end datetime
 * @returns {string} "H hrs mm min"
 */
export const calculateDurationAsHoursMinutes = (start, end) => {
  if (isEmpty(start) || isEmpty(end)) return null;

  const startDate = new Date(parseISO(start));
  const endDate = new Date(parseISO(end));

  if (!isValid(startDate) || !isValid(endDate)) {
    return null;
  }

  const totalMinutes = (endDate.getTime() - startDate.getTime()) / 1000 / 60;
  const hours = totalMinutes >= 60 ? Math.trunc(totalMinutes / 60) : 0;
  const minutes = totalMinutes - hours * 60;
  const hoursLabel = 'h';
  const minutesLabel = 'min';

  if (hours && minutes) {
    return `${hours}${hoursLabel} ${minutes}${minutesLabel}`;
  }

  if (hours && !minutes) {
    return `${hours}${hoursLabel}`;
  }

  return `${minutes}${minutesLabel}`;
};

/**
 * 'formattedDateTimeWithoutTimezone' function will get a Date input
 * to convert to a DateTime string without timezone as an output
 * @param {Date} newValue
 * @returns a datetime string - eg: 2023-08-30T00:00:00
 */
export const formattedDateTimeWithoutTimezone = newValue => {
  const convertedDateToDateTime = formatISO(newValue);
  const dateTimeWithoutTimezone = convertedDateToDateTime
    .toString()
    .slice(0, 19);
  return dateTimeWithoutTimezone;
};

export const getRequestDuration = request => {
  try {
    const requestDuration = intervalToDuration({
      start: parseISO(request.startDateTime),
      end: parseISO(request.endDateTime),
    });
    return (
      requestDuration.minutes / 60 +
      requestDuration.hours +
      requestDuration.days * 24
    ).toFixed(2);
  } catch (error) {
    return '';
  }
};

export const getEventDuration = event => {
  try {
    const eventDuration = intervalToDuration({
      start: parseISO(event.startDateTime),
      end: parseISO(event.endDateTime),
    });
    return (eventDuration.minutes / 60 + eventDuration.hours).toFixed(2);
  } catch (error) {
    return '';
  }
};

/**
 * Returns a string of formatted date range or a single date
 * @param {string} startDateTime datetime
 * @param {string} endDateTime datetime
 * @param {string} formatString
 * @param {boolean} splitSameDate boolean / optional
 * @returns {string} "July 10 2023" or "July 10 2023 - July 12 2023"
 */
export const getFormattedDateString = (
  startDateTime,
  endDateTime,
  formatString = 'MMMM, dd, yyyy',
  splitSameDate = false,
) => {
  try {
    if (!startDateTime && !endDateTime) return null;

    if (!startDateTime) return format(parseISO(endDateTime), formatString);
    if (!endDateTime) return format(parseISO(startDateTime), formatString);

    const startDate = format(parseISO(startDateTime), 'yyyy-MM-dd');
    const endDate = format(parseISO(endDateTime), 'yyyy-MM-dd');

    if (startDate === endDate && !splitSameDate) return format(parseISO(startDate), formatString);

    return `${format(parseISO(startDate), formatString)} - ${format(
      parseISO(endDate),
      formatString,
    )}`;
  } catch {
    return null;
  }
};

/**
 * Temp JSDoc types
 * @typedef {object} MultiDayReturn
 * @property {string} string
 * @property {string} difference
 */
/**
 * Returns a date as a string
 * @param {number} time current time
 * @returns {date} "Sun Aug 13 2023 00:00:00 GMT-0400 (Eastern Daylight Time)"
 */
export const getLimitedDateRange = time => {
  const isSundayOfCurrentWeek = isToday(startOfWeek(new Date()));
  const isMondayOfCurrentWeek = isToday(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  );

  const isBeforePayrollTime =
    (isMondayOfCurrentWeek && time < 12) || isSundayOfCurrentWeek;

  return isBeforePayrollTime
    ? subWeeks(startOfWeek(new Date()), 1)
    : startOfWeek(new Date());
};

/**
 * Returns a string of formatted date range and a number if spans multi-day
 * @param {string, Date} startDateTime datetime
 * @param {string, Date} endDateTime datetime
 * @param {string} formatString
 * @param {boolean} returnDateRange
 * @return {MultiDayReturn}
 */
export const getFormattedMultiDayDateString = (
  startDateTime,
  endDateTime,
  formatString = 'MMMM, dd, yyyy',
  returnDateRange = false,
) => {
  if (!startDateTime || !endDateTime) return null;

  const startDateTimeParsed =
    typeof startDateTime === 'string' ? parseISO(startDateTime) : startDateTime;
  const endDateTimeParsed =
    typeof endDateTime === 'string' ? parseISO(endDateTime) : endDateTime;

  const diff = differenceInCalendarDays(endDateTimeParsed, startDateTimeParsed);
  const returnString = diff < 0 ? `${diff}` : `+${diff}`;

  if (!isSameDay(startDateTimeParsed, endDateTimeParsed)) {
    return {
      string: returnDateRange
        ? `${format(startDateTimeParsed, formatString)} - ${format(
            endDateTimeParsed,
            formatString,
          )}`
        : `${format(startDateTimeParsed, formatString)}`,
      difference: returnString,
    };
  }
  return {
    string: returnDateRange
      ? `${format(startDateTimeParsed, formatString)} - ${format(
          endDateTimeParsed,
          formatString,
        )}`
      : format(startDateTimeParsed, formatString),
    difference: null,
  };
};

/**
 * The function 'isValidTimeInput' checks if the endTime value is larger than
 * the startTime value within the same hour. And also accommodates 24 hours
 * overnight events by accepting '00:00' as startTime and endTime inputs.
 * @param {Dayjs | null} startTime
 * @param {Dayjs | null} endTime
 * @returns boolean
 */
export const isValidTimeInput = (startTime, endTime) => {
  if (startTime === null && endTime === null) {
    return true;
  }
  const overNight24hoursEventTime = 0;
  const startHour = +startTime.format('HH');
  const startMinute = +startTime.format('mm');
  const endHour = +endTime.format('HH');
  const endMinute = +endTime.format('mm');

  if (
    overNight24hoursEventTime === startHour &&
    overNight24hoursEventTime === endHour &&
    overNight24hoursEventTime === startMinute &&
    overNight24hoursEventTime === endMinute
  ) {
    return true;
  }
  if (startHour === endHour && startMinute >= endMinute) return false;
  return true;
};

/**
 * @param {*} timeString timestamp
 * @returns formatted string eg. 23:44 or 7:00
 */
export const to24HrTime = timeString => {
  try {
    return format(new Date(timeString), 'H:mm');
  } catch {
    return null;
  }
};

/**
 * @param {*} timeString timestamp
 * @returns formatted string eg. 07:00 or 23:00
 */
export const toFullHour24HrTime = timeString => {
  try {
    return format(new Date(timeString), 'HH:mm');
  } catch {
    return null;
  }
};

/**
 * Parses a date range and returns the duration between the dates in a `H hrs mm min` format
 * @param {*} startDate start date
 * @param {*} endDate end date
 * @returns duration in `H hrs mm min` format
 */
export const dateRangeToDuration24HrTime = (startDate, endDate) => {
  const startDateTime = toFullHour24HrTime(startDate) || '';
  const endDateTime = toFullHour24HrTime(endDate) || '';

  return durationAsHoursMinutes(startDateTime, endDateTime) || '';
};

export const to24HrTimeFullWithSeconds = timeString => {
  try {
    return format(new Date(timeString), 'HH:mm:ss');
  } catch {
    return null;
  }
};

export const timeStampToYearMonthDay = (timeString, delimeter = '-') => {
  try {
    return format(new Date(timeString), `yyyy${delimeter}MM${delimeter}dd`);
  } catch {
    return null;
  }
};

export const differenceInHoursOrNull = (start, end) => {
  try {
    return differenceInHours(new Date(end), new Date(start)).toString();
  } catch {
    return null;
  }
};

/**
 * @param {*} Date
 * @returns formatted string, example: Tues, Sept 12, 2023, 17:00
 */
export const getShortDayShortDateTime = dateTimeStamp => {
  if (!dateTimeStamp) return '';
  return format(parseISO(dateTimeStamp), 'EEE, MMM d, yyyy, HH:mm');
};

/**
 * @param {*} Date
 * @returns formatted string, example: Tues, Sept 12, 2023
 */
export const getShortDayShortDate = dateTimeStamp => {
  if (!dateTimeStamp) return '';
  return format(parseISO(dateTimeStamp), 'EEE, MMM d, yyyy');
};

/**
 * @param {*} Date
 * @returns formatted string, example: Tuesday, Sept 12, 2023
 */
export const getLongDayShortDate = dateTimeStamp => {
  if (!dateTimeStamp) return '';
  return format(parseISO(dateTimeStamp), 'EEEE, MMM d, yyyy');
};

/**
 * @param {*} Date
 * @returns formatted string, example: Tuesday, Sept 12, 2023, 17:00:00
 */
export const getLongDayShortDateFullTime = dateTimeStamp => {
  if (!dateTimeStamp) return '';
  return format(parseISO(dateTimeStamp), 'EEEE, MMM d, yyyy, HH:mm:ss');
};

// TODO this function also exists in api/rto
// make usage of this function in api/rto
const getDateFmt = dateTimeIsoFormat => {
  return format(dateTimeIsoFormat, 'yyyy-MM-dd');
};

/**
 * @param {string} startTime
 * @param {string} endTime
 * @param {string} date
 * @returns {boolean}
 *
 */
export const isOverNightEvent = (startTime, endTime, date) => {
  if (isEmpty(startTime) && isEmpty(endTime)) return true;
  const duration = durationRequests(startTime, endTime);
  const durationInMinutes = duration.hours * 60 + duration.minutes;
  const startDateTime = parseISO(`${date}T${startTime}`);
  const endDateTime = addMinutes(startDateTime, durationInMinutes);
  return getDateFmt(startDateTime) !== getDateFmt(endDateTime);
};

export const dateToString = (dateToFormat, formatString = 'yyyy-MM-dd') => {
  try {
    return format(dateToFormat, formatString);
  } catch {
    return '';
  }
};

/**
 * @param {string} dateTime
 */
export const getTimeFromDateTime = dateTime => {
  try {
    return format(parseISO(dateTime), 'hh:mm');
  } catch {
    return '';
  }
};

/**
 * @param {string} dateTime
 */
export const getTimeLongFormatFromDateTime = dateTime => {
  try {
    return format(parseISO(dateTime), 'HH:mm');
  } catch {
    return null;
  }
};

export const isTimeWithinRange = (
  startDateTime,
  endDateTime,
  rangeStartDateTime,
  rangeEndDateTime,
) => {
  try {
    const start = parseISO(startDateTime).getTime();
    const end = parseISO(endDateTime).getTime();
    const rangeStart = parseISO(rangeStartDateTime).getTime();
    const rangeEnd = parseISO(rangeEndDateTime).getTime();

    return start >= rangeStart && end <= rangeEnd;
  } catch (error) {
    return null;
  }
};

/**
 * @param {Date} date
 */
export const isPastNoHourCalc = date => {
  const today = new Date().setHours(0, 0, 0, 0);
  const compareDate = date.setHours(0, 0, 0, 0);
  return compareDate < today;
};

/**
 * @param {*} string ex. Oct 22-Oct 28
 * @returns an object with a startDate and endDate dates
 */
export const parseDateRange = (dateRange, scheduleStartDate) => {
  if (!dateRange) return {};
  const splitString = dateRange.split('-');
  if (splitString.length < 2) {
    return {
      startDate: scheduleStartDate,
      endDate: scheduleStartDate,
    };
  }
  return {
    startDate: scheduleStartDate,
    endDate: addDays(scheduleStartDate, 6),
  };
};

export const isBeforeNoonMondayInSk = () => {
  const nowUtc = zonedTimeToUtc(
    new Date(),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const reginaTimezone = 'America/Regina';
  const reginaNow = utcToZonedTime(nowUtc, reginaTimezone);
  const mondayNoon = addDays(setHours(startOfWeek(reginaNow), 12), 1);

  return isBefore(reginaNow, mondayNoon);
};

/**
 * 'formattedReadOnlyDateString' function will get a datetime string as an input
 * to convert it to a different format in date string as an output
 * @param {string} dateString
 * @returns date string - eg: Mon, July 31, 2023
 */
export const formattedReadOnlyDateString = dateString => {
  return format(parseISO(dateString), 'EEE, MMM dd, yyyy');
};

/**
 * validateTime function
 * @description Verifies if a string has the HH:mm format.
 * @param {string} time  Time to test
 */
export const validateTime = time => {
  const timeIsValid = time && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i.test(time);
  return timeIsValid;
};

/**
 * calculateDurationInMinutes function
 * @description Calculate the duration in minutes
 * @param {string} startTime Start time  (HH:mm)
 * @param {string} endTime End time (HH:mm)
 */
export const calculateDurationInMinutes = (startTime, endTime) => {
  if (!validateTime(startTime) || !validateTime(endTime)) {
    return null;
  }

  const durationObj = durationRequests(startTime, endTime);
  if (durationObj) {
    const { hours, minutes } = durationObj;
    const minutesValue = minutes || 0;
    const hoursValue = hours ? hours * 60 : 0;
    const total = hoursValue + minutesValue;
    return total;
  }
  return null;
};

/**
 * getHoursAndMinutesFromTotalMinutes function
 * @description Convert a total of minutes to an object with hours and minutes. 
 * @param {number} totalMinutes  Total of minutes as number
 */
export const getHoursAndMinutesFromTotalMinutes = (totalMinutes) => {
  const hours = totalMinutes && Math.floor(totalMinutes / 60);
  const minutes = totalMinutes && totalMinutes % 60;
  return { hours, minutes };
};

/**
 * formatTotalMinutes function
 * @description Convert a total of minutes to string format HH hrs mm mins. 
 * @param {number} totalMinutes  Total of minutes as number
 */
export const formatTotalMinutes = (totalMinutes) => {
  const obj = getHoursAndMinutesFromTotalMinutes(totalMinutes);
  const { hours, minutes } = obj;

  const hoursLabel = 'h';
  const minutesLabel = 'min';

  if (hours > 0 && minutes > 0) {
    return `${hours}${hoursLabel}${minutes}${minutesLabel}`;
  }

  if (hours > 0 && minutes === 0) {
    return `${hours}${hoursLabel}`;
  }

  if (hours === 0 && minutes > 0) {
    return `${minutes}${minutesLabel}`;
  }

  return '-- hr';
}

/**
 * getNextDay function
 * @description Returns the next day to a provided date 
 * @param {string} date  a date on format yyyy-MM-dd
 * @returns {string} a date on format yyyy-MM-ddT00:00:00
 */
export const getNextDay = (date) => {
  if (!date) return '';
  const dt = addDays(parseISO(date), 1);
  const nextDay = format(dt, "yyyy-MM-dd");
  return `${nextDay}T00:00:00`;
}

/** 
 * Merges the date part of the first Dayjs object with the time part of the second Dayjs object
 * to generate a new Dayjs object.
 * @param {*} date The Dayjs object representing the date part.
 * @param {*} time The Dayjs object representing the time part.
 * @returns A new Dayjs object representing the merged date and time.
 */
export const mergeDateAndTime = (date, time) => {
  const mergedDate = dayjs(date)
    .set('hour', time.hour())
    .set('minute', time.minute())
    .set('second', time.second())
    .set('millisecond', time.millisecond());

  return mergedDate;
};

/**
 * converts a float to formatted time
 * @param {*} minutes 
 * @returns formatted string eg. `3 hours 45 mins` or `1 hour 1 min`
 */
export const convertMinutesToString=(minutes)=> {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  
  let result = "";
  
  if (hours > 0) {
    result = `${hours} hour${(hours > 1 ? 's' : '')}`;
  }
  
  if (mins > 0) {
    result =`${result} ${mins} min${(mins > 1 ? 's' : '')}`;
  }
  
  return result.trim();
}
