import { formatEmployeeNameAndNumber } from '../../utils/helper/format';
import { SelectOption } from '../common/Select/Select.types';
import { EmployeeInfo } from './SubmitForEmployee.types';

/**
 * 'formatEmployeeFirstName' function will get a selected employee
 * and format it to a new string that only shows employee first name
 * @returns a string
 */
export const formatEmployeeFirstName = (selectedEmployee: EmployeeInfo) => {
  return selectedEmployee && `${selectedEmployee.fullName.split(', ')[1]}`;
};

/**
 * 'formatEmployeeLastName' function will get a selected employee
 * and format it to a new string that only shows employee last name
 * @returns a string
 */
export const formatEmployeeLastName = (selectedEmployee: EmployeeInfo) => {
  return selectedEmployee && `${selectedEmployee.fullName.split(',')[0]}`;
};

/**
 * 'formatEmployeeNumber' function will get a selected employee
 * and format it to a new string that only shows employee number
 * @returns a string
 */
export const formatEmployeeNumber = (selectedEmployee: EmployeeInfo) => {
  return selectedEmployee && `(${selectedEmployee.personNumber})`;
};

/**
 * 'formatEmployeePhoneNumberOne' function will get a selected employee
 * and format it to a new string that only shows employee phone number 1
 * @returns a string
 */
export const formatEmployeePhoneNumberOne = (
  selectedEmployee: EmployeeInfo,
) => {
  return selectedEmployee && selectedEmployee.phone1
    ? `(${selectedEmployee.phone1})`
    : '--';
};

/**
 * 'formatEmployeePhoneNumberTwo' function will get a selected employee
 * and format it to a new string that only shows employee phone number 2
 * @returns a string
 */
export const formatEmployeePhoneNumberTwo = (
  selectedEmployee: EmployeeInfo,
) => {
  return selectedEmployee && selectedEmployee.phone2
    ? `(${selectedEmployee.phone2})`
    : '--';
};

/**
 * 'getLocationOptions' function will get the transformed API response data
 * to create a new shape of the list of locations
 * @returns an array of object with the type 'SelectOption[]' or an empty array
 */
export const getLocationOptions = (
  data: SubmitForEmployee.SubmitForEmployeeInitializeResponse,
): SelectOption[] => {
  const locationOptions = data?.locationData?.map((locationList, index) => {
    return {
      id: index,
      text: locationList.locationName,
      value: locationList.locationName,
    };
  });
  return locationOptions || [];
};

/**
 * 'getDepartmentOptions' function will get the transformed API response data
 * to create a new shape of the list of departments based on the selected location
 * @returns an array of object with the type 'SelectOption[]' or an empty array
 */
export const getDepartmentOptions = (
  data: SubmitForEmployee.SubmitForEmployeeInitializeResponse,
  selectedLocation: string,
): SelectOption[] => {
  const listOfDepartment = data?.departmentData?.find(
    matchedItem => matchedItem?.locationName === selectedLocation,
  );
  const departmentOptions = listOfDepartment?.departments?.map(
    (departmentList, index) => {
      return {
        id: index,
        text: departmentList,
        value: departmentList,
      };
    },
  );

  return departmentOptions || [];
};

/**
 * 'getJobOptions' function will get the transformed API response data
 * to create a new shape of the list of jobs based on the selected location and department
 * @returns an array of object with the type 'SelectOption[]' or an empty array
 */
export const getJobOptions = (
  data: SubmitForEmployee.SubmitForEmployeeInitializeResponse,
  selectedDepartment: string,
) => {
  const listOfJob =
    data &&
    data.jobData &&
    data.jobData.find(
      matchedItem => matchedItem?.departmentName === selectedDepartment,
    );
  const jobOptions = listOfJob?.jobs?.map(jobList => {
    return {
      id: jobList.orgId,
      text: jobList.name,
      value: jobList.name,
      cba: jobList.cba,
    };
  });
  return jobOptions || [];
};

/**
 * 'etEmployeeOptions' function will get the list of employee based on the 'orgId'
 * which is determined based on the selected location, department and job
 * to create a new shape of the list of employee
 * @returns an array of object with the type 'SelectOption[]' or an empty array
 */
export const getEmployeeOptions = (
  listOfEmployee: SubmitForEmployee.LoadEmployeeListResponse,
) => {
  const employeeOptions = listOfEmployee?.employeeList?.map(employee => {
    return {
      id: employee.personNumber,
      text: formatEmployeeNameAndNumber(employee),
      value: formatEmployeeNameAndNumber(employee),
      customValue: `${formatEmployeeFirstName(
        employee,
      )}/${formatEmployeeLastName(employee)}/${formatEmployeeNumber(
        employee,
      )}/${formatEmployeePhoneNumberOne(
        employee,
      )}/${formatEmployeePhoneNumberTwo(employee)}/${employee.personNumber}/${
        listOfEmployee?.orgId
      }`,
    };
  });

  return listOfEmployee &&
    listOfEmployee.employeeList &&
    listOfEmployee.employeeList.length > 0
    ? employeeOptions
    : [];
};

export const getDelegateParams = (
  delegateId?: string,
  defaultRoleId?: string,
  secondaryRoleId?: string,
) => {
  let delegateParams = {};
  const hasDelegateParams = !!(delegateId && defaultRoleId && secondaryRoleId);

  if (hasDelegateParams) {
    delegateParams = {
      delegateId,
      defaultRoleId,
      secondaryRoleId,
    };
  }
  return delegateParams;
};
