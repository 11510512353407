// TODO: can this be cleaned up? Some re-typing will be necessary
// throughout the API responses and transformers
export type StatusBadgeStatus =
  | ManageAverageHours.Status
  | RequestLeave.StatusType
  | TimeManagement.Status
  | 'Action Needed'
  | 'Approved'
  | 'Avg. Hrs Required'
  | 'Cancelled'
  | 'Cancel Submitted'
  | 'Open'
  | 'Pending'
  | 'Pending Immediate Need'
  | 'Processing Rules'
  | 'Refused'
  | 'Refused Premium Historic'
  | 'Submitted'
  | 'Submitted Shift Bid'
  | 'Submitted Premium Historic'
  | 'Submitted to Manager'
  | 'Withdrawn'
  | 'Posted'
  | 'Vacant'
  | 'Offering Regular'
  | 'Offering Overtime'
  | 'Bid Regular'
  | 'Bid Overtime'
  | 'RNN'
  | string;

/**
 * @docs Figma: https://www.figma.com/file/ME6NVqtIsgC4WASIrHEOKa/%E2%9C%A8-SK-Design-System-%F0%9F%A4%9F%F0%9F%8F%BC?type=design&node-id=171-5247&mode=design&t=qhwJbBPgUFBxJ2Ej-4
 */
export enum StatusBadgeVariants {
  /** grey */
  LIGHT = 'light',
  /** yellow */
  WARNING = 'warning',
  /** red */
  ERROR = 'error',
  /** green */
  SUCCESS = 'success',
  /** red outline */
  ACTION = 'action',
  /** blue */
  OPEN = 'open',
}

export interface StatusBadgeProps {
  status: StatusBadgeStatus | string;
  isDotrequired?: boolean;
}
