import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isErrorBannerOpen: false,
  isTapExceptionsBannerOpen: false,
  hasDismissedTapExceptionBanner: false,
};

export const tapExceptionsSlice = createSlice({
  name: 'tapException',
  initialState,
  reducers: {
    setErrorBanner: (state, action) => {
      state.isErrorBannerOpen = action.payload;
    },
    setTapExceptionsBanner: (state, action) => {
      state.isErrorBannerOpen = action.payload;
    },
    setisTapExceptionsBannerOpen: (state, action) => {
      state.isTapExceptionsBannerOpen = action.payload;
    },
    setHasDismissedTapExceptionBanner: (state, action) => {
      state.hasDismissedTapExceptionBanner = action.payload;
    },
  },
});

export const {
  setErrorBanner,
  setisTapExceptionsBannerOpen,
  setHasDismissedTapExceptionBanner,
} = tapExceptionsSlice.actions;
export default tapExceptionsSlice.reducer;
