import React, { createContext, useContext, useMemo, useState } from 'react';
import { ManageVacanciesContextState } from './ManageVacanciesContext.types';
import { SelectFieldValue } from '../../common/Select/Select.types';

const ManageVacanciesContext = createContext<
  ManageVacanciesContextState | undefined
>(undefined);

export const ManageVacanciesProvider: React.FC<{
  children: React.ReactNode;
}> = props => {
  const { children } = props;
  /** State variable for selected location */
  const [selectedLocation, setSelectedLocation] = useState('');
  const addSelectedLocation = (sLoc: SelectFieldValue) => {
    setSelectedLocation(sLoc?.value ?? '');
  };

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const addSelectedDepartment = (sDept: SelectFieldValue) => {
    setSelectedDepartment(sDept?.value ?? '');
  };

  const jobDefaultValue = {
    id: undefined,
    text: '',
    value: '',
  };
  /** State variable for selected job */
  const [selectedJob, setSelectedJob] =
    useState<SelectFieldValue>(jobDefaultValue);
  const addSelectedJob = (sJob: SelectFieldValue) => {
    setSelectedJob(sJob ?? jobDefaultValue);
  };

  /** State variable for selected date range */
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({});
  const addSelectedDateRange = (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => {
    setSelectedDateRange({
      startDate,
      endDate,
    });
  };

  const providerValues = useMemo(
    () => ({
      selectedLocation,
      addSelectedLocation,

      selectedDepartment,
      addSelectedDepartment,

      jobDefaultValue,
      selectedJob,
      addSelectedJob,

      selectedDateRange,
      addSelectedDateRange,
    }),
    [selectedLocation, selectedDepartment, selectedJob, selectedDateRange],
  );
  return (
    <ManageVacanciesContext.Provider value={providerValues}>
      {children}
    </ManageVacanciesContext.Provider>
  );
};

export const useManageVacancies = () => {
  const context = useContext(ManageVacanciesContext);
  if (context === undefined) {
    throw new Error(
      'useManageVacancies must be used within a ManageVacanciesProvider',
    );
  }
  return context;
};
