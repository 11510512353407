/**
 * BannerNotification
 *
 * @description Full-width banner displayed below header, used for site-wide
 * notifications. Persists throughout site navigation. Once dismissed, should
 * remain dismissed throughout the session.
 *
 */
import React from 'react';

const BannerNotification = ({ message }: { message: TodoTypeMe | null }) => {
  return (
    <>
      {message[0]}

      {message.length > 1 && (
        <ul className="list-disc pl-1 mt-4">
          {message.map(
            (item: string, index: number) =>
              index !== 0 && (
                <li key={index} className="mb-2">
                  {item}
                </li>
              ),
          )}
        </ul>
      )}
    </>
  );
};

export default BannerNotification;
