/**
 * 8 minutes.
 *
 * `seconds * minutes`
 */
export const keepOpenDataInSeconds = 60 * 8;

export const enableOpenCaching =
  process.env.REACT_APP_BETA_FEATURE_OPEN_CACHING === 'true';

export const USE_V2_REQUEST_ABSENCE =
  process.env.REACT_APP_BETA_FEATURE_API_v2_REQUEST_ABSENCE === 'true';

export const EMPTY_SELECTION_RESULTS =
  'There are no requests for the current selection. Please choose a different location, department, or job.';

export const USE_SPLIT_SHIFTS =
  process.env.REACT_APP_BETA_FEATURE_SPLIT_SHIFTS === 'true';

export const USE_HISTORIC_EDIT_V2 =
  process.env.REACT_APP_BETA_FEATURE_HISTORIC_ADD_EDIT === 'true';
