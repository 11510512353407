import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { Timer } from './Timer';

export interface Timers {
  api: string;
  endpoints: string[];
  timer: Timer;
}

type APITrackerMap = Record<
  string,
  {
    endpoints: string[];
  }
>;

/**
 * A container for an array Timer classes mapped to RTK Query endpoints.
 * APITracker can recieve information about which  RTK Query endpoints was called
 * and can then reset the relveant countdown Timer.
 */
export class APITracker {
  apiMap: APITrackerMap;

  timers: Timers[];

  constructor(config: { timers?: Timers[]; map: APITrackerMap }) {
    this.timers = config?.timers || [];
    this.apiMap = config?.map || {};
  }

  /**
   * For each feature define a set of timers for all the API endpoints
   */
  initializeTimers() {
    Object.keys(this.apiMap).forEach(api => {
      const { endpoints } = this.apiMap[api as keyof typeof this.apiMap];
      const timer = new Timer({
        name: api,
      });
      this.timers.push({
        api,
        endpoints,
        timer,
      });
    });
  }

  cleanupTimers() {
    this.timers.map(t => t.timer.cleanup());
    this.timers = [];
  }

  /**
   * When an API call is made it 'resets' the session timer.
   * This fn will do the same by matching the name of the Rtk query endpoint
   * to the timer and call the timer reset fn.
   * @param endpoint the Rtk query endpoint that was called
   * @param fetchArgs the fetch arguments used to make the Rtk query
   * @returns void
   */
  trackAndResetTimer(endpoint: string, fetchArgs: FetchArgs) {
    const timerToReset = this.timers?.find(timer =>
      timer.endpoints?.find(e => e === endpoint),
    );
    if (!timerToReset) {
      return;
    }

    // in order to make an API call
    // we need set the latest headers body, params etc.
    // we can set the latest fetch arguments so they will be defined by the time the Timer callback is called
    timerToReset.timer?.setFetchDetails(fetchArgs);
    timerToReset.timer?.resetTimer();
  }
}
