import accessTokenObj from '../api/.accesstoken.json';

export const addUKGSession = (session: UKG.UKGSession) => {
  // TODO encrypt session
  window.sessionStorage.setItem('ukgSession', JSON.stringify(session));
};

export const clearUKGSession = () => {
  window.sessionStorage.clear();
};

export const getUKGSession = (): UKG.UKGSession | undefined => {
  // TODO decrypt session
  const ukgSessionString = window.sessionStorage.getItem('ukgSession');

  if (ukgSessionString) {
    try {
      return JSON.parse(ukgSessionString);
    } catch (e: unknown) {
      return undefined;
    }
  }
  return undefined;
};

export const findUKGSession = (): UKG.UKGSession | undefined => {
  /** In local env, we need to update session every time app reloads  */
  if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
    return {
      accessToken: accessTokenObj.access_token,
      tokenType: 'Bearer',
      refreshToken: accessTokenObj.refresh_token,
      expiresInSeconds: '1799',
      username: process.env.REACT_APP_UKG_USER_NAME,
      userRole: process.env.REACT_APP_UKG_USER_ROLE,
    };
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (
    urlParams.has('accessToken') &&
    urlParams.has('tokenType') &&
    urlParams.has('refreshToken') &&
    urlParams.has('expiresInSeconds') &&
    urlParams.has('username') &&
    urlParams.has('userRole')
  ) {
    const ukgSessionFromURL = {
      accessToken: urlParams.get('accessToken'),
      tokenType: urlParams.get('tokenType'),
      refreshToken: urlParams.get('refreshToken'),
      expiresInSeconds: urlParams.get('expiresInSeconds'),
      username: urlParams.get('username'),
      userRole: urlParams.get('userRole'),
    };
    const newURL = new URL(window.location.toString());
    newURL.search = '';
    window.history.replaceState(window.history.state, '', newURL);
    return ukgSessionFromURL;
  }

  /** Return ukg session from browser's session storage */
  return getUKGSession();
};
