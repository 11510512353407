import React from 'react';
import { NavLink } from 'react-router-dom';
// @ts-ignore
import AIMSLogo from '../../../assets/images/aims-logo.svg';

const AimsLogoAsLink = () => {
  return (
    <NavLink
      to="/"
      className="shrink-0 focus:outline focus:outline-white focus:outline-2 rounded-calendar"
    >
      <img src={AIMSLogo} alt="AIMS logo" />
    </NavLink>
  );
};

export default AimsLogoAsLink;
