import { all } from 'redux-saga/effects';
import { saga as TimeOffRequest } from './timeOffRequest/saga';
import { saga as Session } from './session/saga';

export function* rootSaga() {
  try {
    yield all([TimeOffRequest(), Session()]);
  } catch (err) {
    console.error('RootSaga exception', err); // eslint-disable-line no-console
  }
}
