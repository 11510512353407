import { createStateSyncMiddleware, Config } from 'redux-state-sync';
import {
  addUKGSessionToStore,
  revokeUkgAndCleverAntSession,
} from '../session/sessionSlice';

/**
 * We only want to sync ukgSession from redux across all open tabs,
 * that's why we will only whitelist addUKGSessionToStore action
 * */
const syncReduxStateMiddlewareConfig: Config = {
  whitelist: [addUKGSessionToStore.type, revokeUkgAndCleverAntSession.type],
  /** we only need to share session state from redux to other open tabs, not the whole store */
  prepareState: state => ({
    session: state.session,
  }),
  /** if running in test environment, need to simulate broadcastChannel */
  ...(process.env.NODE_ENV === 'test' && {
    broadcastChannelOption: {
      type: 'simulate',
    },
  }),
};

const syncReduxStateMiddleware = createStateSyncMiddleware(
  syncReduxStateMiddlewareConfig,
);
export default syncReduxStateMiddleware;
