import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { NotificationProps } from './Notification.types';
import {
  CheckCircleIcon,
  ErrorCircleIcon,
  InfoIcon,
  RemoveCircleIcon,
  WarningIcon,
} from '../../../icons/index';

/**
 * Notification
 * @description Displays a notification as either success, warning,
 * or error message. To handle mutliple Notification elements, and/or to
 * display them as "toasts", use them inside `Notifications` component.
 * Used for notifications relevant to the current context only; for site-wide
 * notifications, see `BannerNotification`.
 * @param {NotificationProps} props
 * @see https://www.figma.com/file/ME6NVqtIsgC4WASIrHEOKa/%E2%9C%A8-SK-Design-System-%F0%9F%A4%9F%F0%9F%8F%BC?type=design&node-id=2164-3219&mode=dev
 */
export function Notification(
  props: PropsWithChildren<NotificationProps>,
): ReactElement | null {
  const {
    ariaLabel,
    children,
    handleDismiss,
    handleLinkClick,
    link,
    noDismiss,
    type,
    fullWidth,
    hasTimeout = false,
  } = props;

  const [isOpen, setIsOpen] = useState(true);
  const dismiss = () => {
    setIsOpen(false);
    handleDismiss && handleDismiss();
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (hasTimeout) timeoutId = setTimeout(dismiss, 10_000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasTimeout]);

  const config = {
    system: {
      bg: 'bg-neutral-grey-800',
      border: 'border-neutral-grey-800',
      leftEdge: 'before:bg-neutral-grey-800',
      iconColorClass: 'text-primary-white',
      icon: <InfoIcon />,
    },
    error: {
      bg: 'bg-error-50',
      border: 'border-error-200',
      leftEdge: 'before:bg-error-800',
      iconColorClass: 'text-error-800',
      icon: <ErrorCircleIcon />,
    },
    criticalError: {
      bg: 'bg-white',
      border: 'border-error-200',
      leftEdge: 'before:bg-error-800',
      iconColorClass: 'text-[#DA1E28]',
      icon: <RemoveCircleIcon />,
    },
    warning: {
      bg: 'bg-[#FCF4D6]',
      border: 'border-warning-400',
      leftEdge: 'before:bg-secondary-yellow',
      iconColorClass: 'text-[#FCDB3E]',
      icon: <WarningIcon />,
    },
    success: {
      bg: 'bg-[#EBFBDE]',
      border: 'border-success-400',
      leftEdge: 'before:bg-primary-dark-green',
      iconColorClass: 'text-primary-dark-green',
      icon: <CheckCircleIcon />,
    },
    info: {
      bg: 'bg-[#E5F4FC]',
      border: 'border-[#9BC7E0]',
      leftEdge: 'before:bg-[#0043CE]',
      iconColorClass: 'text-[#0043CE]',
      icon: <ErrorCircleIcon />,
    },
  };

  return isOpen ? (
    <div
      role="alertdialog"
      aria-label={ariaLabel}
      className={cn(
        `relative border min-w-[67%] flex flex-col sm:flex-row gap-4 px-6 py-4 before:absolute before:w-1 before:left-0 before:top-0 before:bottom-0 before:rounded-tl-[3px] before:rounded-bl-[3px] ${config[type].bg} ${config[type].leftEdge} ${config[type].border}`,
        {
          'w-full': fullWidth,
          'max-w-toaster': !fullWidth,
          'rounded-none': type === 'system',
          rounded: type !== 'system',
        },
      )}
    >
      <div className="flex gap-4 items-start flex-1 break-all">
        {/* Use icon with added props: */}
        {cloneElement(config[type].icon, {
          className: `aims-iconfix text-2xl ${config[type].iconColorClass}`,
          alt: '',
        })}

        <div
          className={cn('flex-1 break-keep', {
            'text-neutral-grey-800': type !== 'system',
            'text-primary-white': type === 'system',
          })}
        >
          {children}
        </div>
      </div>

      {(link || !noDismiss) && (
        <div className="text-right flex items-start justify-end">
          {link && (
            <div
              onClick={handleLinkClick}
              className="border border-neutral-grey-400 rounded py-1 px-2 text-xs font-semibold"
            >
              <NavLink to={link.path}>{link.text}</NavLink>
            </div>
          )}

          {!noDismiss && (
            <button
              type="button"
              onClick={dismiss}
              className={cn(
                'py-1 px-2 text-xs font-semibold focus:outline-1 focus:outline-offset-2 focus:outline-black',
                { 'text-primary-white': type === 'system' },
              )}
            >
              Dismiss
            </button>
          )}
        </div>
      )}
    </div>
  ) : null;
}

export default Notification;
