import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isErrorToastMessageOpen: false,
  isSuccessToastMessageOpen: false,
  successToastMessage: '',
  isSuccessWithAnswersToastMessageOpen: false,
  formFields: {
    isStartDateError: false,
    isEndDateError: false,
    isLocationError: false,
    isDepartmentError: false,
    isRoleError: false,
    isStartTimeError: false,
    isEndTimeError: false,
    isReasonError: false,
  },
  isAnswerFieldsError: false,
};

export const requestLeaveSlice = createSlice({
  name: 'requestLeave',
  initialState,
  reducers: {
    setErrorBanner: (state, action) => {
      state.isErrorToastMessageOpen = action.payload;
    },
    setSuccessToastMessage: (
      state,
      {
        payload,
      }: {
        payload: {
          isSuccessToastMessageOpen: boolean;
          successToastMessage: string;
        };
      },
    ) => {
      state.isSuccessToastMessageOpen = payload.isSuccessToastMessageOpen;
      state.successToastMessage = payload.successToastMessage;
    },
    setSuccessWithAnswersToastMessage: (
      state,
      {
        payload,
      }: {
        payload: {
          isSuccessToastMessageOpen: boolean;
          successToastMessage: string;
        };
      },
    ) => {
      state.isSuccessWithAnswersToastMessageOpen =
        payload.isSuccessToastMessageOpen;
      state.successToastMessage = payload.successToastMessage;
    },
    setIsStartDateError: (state, action) => {
      state.formFields.isStartDateError = action.payload;
    },
    setIsEndDateError: (state, action) => {
      state.formFields.isEndDateError = action.payload;
    },
    setIsLocationError: (state, action) => {
      state.formFields.isLocationError = action.payload;
    },
    setIsDepartmentError: (state, action) => {
      state.formFields.isDepartmentError = action.payload;
    },
    setIsRoleError: (state, action) => {
      state.formFields.isRoleError = action.payload;
    },
    setIsStartTimeError: (state, action) => {
      state.formFields.isStartTimeError = action.payload;
    },
    setIsEndTimeError: (state, action) => {
      state.formFields.isEndTimeError = action.payload;
    },
    setIsReasonError: (state, action) => {
      state.formFields.isReasonError = action.payload;
    },
    setIsAnswerFieldsError: (state, action) => {
      state.isAnswerFieldsError = action.payload;
    },
  },
});

export const {
  setErrorBanner,
  setSuccessToastMessage,
  setSuccessWithAnswersToastMessage,
  setIsStartDateError,
  setIsEndDateError,
  setIsLocationError,
  setIsDepartmentError,
  setIsRoleError,
  setIsStartTimeError,
  setIsEndTimeError,
  setIsReasonError,
  setIsAnswerFieldsError,
} = requestLeaveSlice.actions;
export default requestLeaveSlice.reducer;
