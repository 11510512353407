import { SelectOption } from '../../components/common/Select/Select.types';
import { EmployeeInfo } from '../../components/submit-for-employee/SubmitForEmployee.types';

/**
 * 'formatEmployeeNameAndNumber' function will get a selected employee from the list as a string
 * and format it to a new string that includes employee fullname and their number
 * @returns a string
 */
export const formatEmployeeNameAndNumber = (
  selectedEmployee: any | EmployeeInfo,
  // TODO: Remove any once schedule editor is typed
) => {
  return (
    selectedEmployee &&
    `${selectedEmployee.fullName} #${selectedEmployee.personNumber}`
  );
};

/**
 * Retrieves location options from the provided data.
 * @param {RequestLeave.RequestLeaveResponse | undefined} data - RequestLeaveResponse data.
 * @param {SelectOption} specialOption - Special option to be included.
 * @returns {SelectOption[]} An array of SelectOptions.
 */
export const getLocationOptions = (
  data: RequestLeave.RequestLeaveResponse | undefined,
  specialOption?: SelectOption,
): SelectOption[] => {
  const locationOptions = data?.departmentData?.map((item, index) => {
    return {
      id: index,
      value: item.locationName,
      text: item.locationName,
    };
  });

  if (specialOption?.id && locationOptions?.length)
    return [specialOption, ...locationOptions];

  if (specialOption?.id && !locationOptions?.length) return [specialOption];

  return locationOptions || [];
};

/**
 * Finds unique departments in the provided array.
 * @param {string[]} departments - Array of departments.
 * @returns {string[]} An array of unique departments.
 */
const getUniqueDepartment = (departments: string[]): string[] =>
  departments?.filter(
    (currentDepartment, index, arr) =>
      arr.findIndex(department => department === currentDepartment) === index,
  );

/**
 * Retrieves department options based on the selected location.
 * @param {string} selectedLocation - The selected location.
 * @param {RequestLeave.RequestLeaveResponse | undefined} data - RequestLeaveResponse data.
 * @param {SelectOption} specialOption - Special option to be included.
 * @returns {SelectOption[]} An array of SelectOptions.
 */
export const getDepartmentOptions = (
  selectedLocation: string,
  data: RequestLeave.RequestLeaveResponse | undefined,
  specialOption?: SelectOption,
): SelectOption[] => {
  const depAtSelectedLoc =
    selectedLocation === 'All'
      ? {
          location: 'All',
          departments: getUniqueDepartment(
            data?.departmentData?.flatMap(
              element => element.departments || '',
            ) || [],
          ),
        }
      : data?.departmentData?.find(
          item => item.locationName === selectedLocation,
        );

  const departmentOptions = depAtSelectedLoc?.departments?.map(
    (deptName, index) => {
      return { id: index, value: deptName, text: deptName };
    },
  );

  if (specialOption?.id && departmentOptions?.length)
    return [specialOption, ...departmentOptions];

  if (specialOption?.id && !departmentOptions?.length) return [specialOption];

  return departmentOptions || [];
};

/**
 * Finds unique jobs in the provided array.
 * @param {Aims.Job[]} jobs - Array of jobs.
 * @returns {Aims.Job[]} An array of unique jobs.
 */
const getUniqueJobs = (jobs: Aims.Job[]): Aims.Job[] =>
  jobs?.filter(
    (currentJob, index, arr) =>
      arr.findIndex(job => job.name === currentJob.name) === index,
  );

/**
 * Finds jobs based on location and department filters.
 * @param {string} locationFilter - Selected location filter.
 * @param {string} departmentFilter - Selected department filter.
 * @param {RequestLeave.RequestLeaveResponse | undefined} data - RequestLeaveResponse data.
 * @returns Object with location, department, and unique jobs.
 */
const findJobByFilters = (
  locationFilter: string,
  departmentFilter: string,
  data: RequestLeave.RequestLeaveResponse | undefined,
) => {
  if (locationFilter === 'All' && departmentFilter === 'All') {
    return {
      location: 'All',
      department: 'All',
      jobs: getUniqueJobs(data?.jobData?.flatMap(job => job.jobs) || []),
    };
  }

  const locationJobs = data?.jobData?.find(job => job.locationName === locationFilter)?.jobs || [];
  const departmentJobs = data?.jobData?.find(job => job.departmentName === departmentFilter)?.jobs || [];

  return {
    location: locationFilter !== 'All' ? locationFilter : 'All',
    department: departmentFilter !== 'All' ? departmentFilter : 'All',
    jobs: getUniqueJobs(locationFilter !== 'All' ? locationJobs : departmentJobs),
  };
};

/**
 * Retrieves role options based on the selected department and location.
 * @param {string} selectedDepartment - Selected department.
 * @param {RequestLeave.RequestLeaveResponse | undefined} data - RequestLeaveResponse data.
 * @param {SelectOption} specialOption - Special option to be included.
 * @param {string} selectedLocation - Selected location.
 * @returns {SelectOption[]} An array of SelectOptions.
 */
export const getRoleOptions = (
  selectedDepartment: string,
  data: RequestLeave.RequestLeaveResponse | undefined,
  specialOption?: SelectOption,
  selectedLocation?: string,
): SelectOption[] => {
  const roleAtSelectedDep = findJobByFilters(
    selectedLocation || '',
    selectedDepartment,
    data,
  );

  const roleOptions = roleAtSelectedDep?.jobs?.map((item, index) => {
    return { id: index, value: item.name, text: item.name };
  });

  if (specialOption?.id && roleOptions?.length)
    return [specialOption, ...roleOptions];

  if (specialOption?.id && !roleOptions?.length) return [specialOption];

  return roleOptions ?? [];
};
