// @ts-nocheck
import { isArray } from 'lodash';

export const getFormattedErrorMessage = errors => {
  const listOfErrors = [];
  const caid = errors?.run?.[0]?.caid;
  listOfErrors.push(`${errors?.run[0]?.logentries[0]?.Message} Error Details: ${caid}` || '');
  if (isArray(errors.response))
    errors.response.map(item => {
      return listOfErrors.push(item.message);
    });
  else if (errors?.response?.message) {
    listOfErrors.push(errors?.response?.message);
  }
  else if (isArray(errors.response?.errors)) {
    errors.response.errors.map(item => {
      return listOfErrors.push(item?.message);
    });
  }
  return listOfErrors;
};
