export type ApiTrackerMap = Record<
  keyof (NodeJS.CleverAntApiTemplates & { CancelRequestV1dot11: string }),
  {
    endpoints: string[];
  }
>;
/**
 * A mapping of API feature and their rtk query apiSlice endpoint names.
 * We are using the NodeJS.CleverAntApiTemplates as an interface
 * to ensure we update this map when a new API is added.
 *
 * If adding a new API or modifying the names of any existing RTK endpoint you will need to
 * reflect those changes here.
 *
 */
export const API_TO_RTK_ENDPOINTS_MAP: ApiTrackerMap = {
  REACT_APP_API_MY_SCHEDULE_READ_TEMPLATE: {
    endpoints: ['readEmpSchedule', 'openMyScheduleSession'],
  },
  /**
   * since RTO is the last feature using the saga pattern
   * we can't identify its network calls made via apiSlice
   * */
  REACT_APP_API_RTO_READ_TEMPLATE: { endpoints: [] },
  REACT_APP_API_RL_READ_TEMPLATE: {
    endpoints: [
      'submitRequestLeaveWithAnswers',
      'submitRequestLeave',
      'openRequestLeaveSession',
      'loadSelectedDetailsRL',
      'initializeRequestLeave',
    ],
  },
  REACT_APP_API_CR_READ_TEMPLATE: {
    endpoints: [
      'submitCancelRequest',
      'openCancelRequestSession',
      'loadCancelRequests',
      'initializeCancelRequest',
    ],
  },
  CancelRequestV1dot11: {
    endpoints: [
      'submitCancelRequestV1dot11',
      'openCancelRequestV1dot11Session',
      'loadCancelRequestsV1dot11',
      'initializeCancelRequestV1dot11',
    ],
  },
  REACT_APP_API_TE_READ_TEMPLATE: {
    endpoints: [
      'submitResolveTapException',
      'openResolveTapExceptionSession',
      'loadExceptions',
    ],
  },
  REACT_APP_API_SHIFT_BID_READ_TEMPLATE: {
    endpoints: ['submitShiftBid', 'openShiftBidSession', 'loadOpenShiftsSB'],
  },
  REACT_APP_API_SHIFT_TRADE_READ_TEMPLATE: {
    endpoints: [
      'updateRequestsST',
      'submitRequestST',
      'openShiftTradeSession',
      'loadRequestsST',
      'loadEmployeeScheduleST',
      'loadColleagueScheduleST',
      'loadColleagueListST',
    ],
  },
  REACT_APP_API_MAH_READ_TEMPLATE: {
    endpoints: [
      'runTerminationRequestMAH',
      'openSessionMAH',
      'loadRequestsMAH',
      'initializeMAH',
      'calculateAverageHoursMAH',
      'MAHSubmit',
    ],
  },
  REACT_APP_API_RR_READ_TEMPLATE: {
    endpoints: [
      'updateRequestsRR',
      'openResolveRequestsSession',
      'loadRequestsRR',
      'initializeResolveRequests',
    ],
  },
  REACT_APP_API_RESOLVE_LONG_TERM_LEAVE_READ_TEMPLATE: {
    endpoints: [
      'submitRequestsRLTL',
      'openResolveLongTermLeaveRequestsSession',
      'loadLongTermLeaveRequests',
      'initializeRLTL',
    ],
  },
  REACT_APP_API_MANAGE_UNIT_SCHEDULE_READ_TEMPLATE: {
    endpoints: [
      'openManageUnitScheduleSession',
      'loadSchedulesMUS',
      'initializeMUS',
      'initializeMUS',
    ],
  },
  REACT_APP_API_PREMIUM_REQUEST_READ_TEMPLATE: {
    endpoints: [
      'submitPremiumRequests',
      'openPremiumRequestSession',
      'loadPremiumData',
    ],
  },
  REACT_APP_API_MANAGE_OPEN_SHIFTS_READ_TEMPLATE: {
    endpoints: [
      'submitMOS',
      'openManageOpenShiftsSession',
      'loadSelectedOpenShiftMOS',
      'loadOpenShiftsMOS',
      'initializeMOS',
    ],
  },
  REACT_APP_API_HISTORICAL_EDITS_READ_TEMPLATE: {
    endpoints: [
      'submitHistoricRequests',
      'openRequestHistoricalEditsSession',
      'loadHistoricalDataWithoutTags',
      'loadHistoricalData',
    ],
  },
  REACT_APP_API_MANAGE_VACANCIES_READ_TEMPLATE: {
    endpoints: [
      'submitOffersMV',
      'openSessionMV',
      'loadRowScheduleMV',
      'loadRotationVacanciesMV',
      'initializeMV',
    ],
  },
  REACT_APP_API_SCHEDULE_EDITOR_READ_TEMPLATE: {
    endpoints: ['submitSE', 'openSessionSE', 'loadSchedulesSE', 'initializeSE'],
  },
  REACT_APP_API_ROW_ASSIGNMENT_READ_TEMPLATE: {
    endpoints: [
      'submitAssignmentsRA',
      'runTerminationReportRA',
      'openRowAssignmentSession',
      'loadSchedulesRA',
      'loadMastersRA',
      'loadAssignmentsRA',
      'initializeRA',
    ],
  },
  REACT_APP_API_BOOK_ON_READ_TEMPLATE: {
    endpoints: [
      'openSessionBO',
      'loadOpenShiftDataBO',
      'loadAssignShiftDataBO',
      'loadAssignPayCodeDataBO',
      'initializeBO',
      'createOpenShiftBO',
      'assignShiftBO',
      'assignPayCodeBO',
    ],
  },
  REACT_APP_API_CALL_LIST_BY_EMPLOYEE_READ_TEMPLATE: {
    endpoints: [
      'submitCLBE',
      'openSessionCLBE',
      'loadSelectedEmployeeCLBE',
      'loadEmployeeListCLBE',
      'initializeCLBE',
    ],
  },
  REACT_APP_API_CALL_LIST_READ_TEMPLATE: {
    endpoints: [
      'submitCL',
      'refusePendingApprovalCL',
      'openSessionCL',
      'loadOpenShiftsCL',
      'loadListCL',
      'initializeCL',
    ],
  },
  REACT_APP_API_SUBMIT_FOR_EMPLOYEE_READ_TEMPLATE: {
    endpoints: ['openSessionSFE', 'loadEmployeeListSFE', 'initializeSFE'],
  },
  REACT_APP_API_REQUEST_ABSENCE_READ_TEMPLATE: {
    endpoints: [
      'submitWithAnswersRequestAbsence',
      'submitRequestAbsence',
      'openRequestAbsenceSession',
      'loadSelectedDetailsRequestAbsence',
      'loadSelectedDetailsRequestAbsence',
      'initializeRequestAbsence',
    ],
  },
  REACT_APP_API_MANAGER_PERMISSIONS: {
    endpoints: ['readPermissionDetailsMP', 'openManagerPermissionsSession'],
  },
};
