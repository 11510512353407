import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Dialog } from '@headlessui/react';
import { DialogModalProps, ModalDesktopSize } from './DialogModal.types';
import { AllowClear } from '../../../icons';

/**
 * Alternative Modal that uses headless UI's Dialog component
 */
const DialogModal = ({
  isOpen,
  onClose,
  children,
  size,
  hideYOverflow,
  zIndex,
}: PropsWithChildren<DialogModalProps>) => {
  const closeModal = () => {
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => null} // This function allows to close the modal when clicking outside of the modal
      className={cn('relative', zIndex)}
    >
      <div className="fixed inset-0 bg-black opacity-50 " aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel
          className={cn(
            'bg-white py-6 px-4 sm:px-10 md:rounded-lg md:mx-8 z-10 relative flex flex-col w-[100vw] h-[100%] md:max-h-[90%] md:h-auto',
            {
              'md:w-[420px]': size === ModalDesktopSize.SMALL,
              'md:w-[866px]': size === ModalDesktopSize.LARGE,
              'overflow-y-auto': !hideYOverflow,
            },
          )}
        >
          <button
            type="button"
            className="absolute right-0 top-0 m-4 text-gray-700 hover:text-gray-900"
            onClick={closeModal}
          >
            <AllowClear />
          </button>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DialogModal;
