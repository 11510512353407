export default {
  rtoSession: { CaId: '', Jwt: '' },
  ukgSession: {
    accessToken: '',
    tokenType: '',
    refreshToken: '',
    expiresInSeconds: 0,
    username: '',
    userRole: '',
  },
  loading: false,
  error: '',
  isRevoked:false,
};
