import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenAddTimePremiumSidePanel: false,
  noJobSelected: false,
  selectedLocation: '',
  selectedDepartment: '',
  selectedRole: {
    value: '',
    text: '',
    id: '',
    orgId: '',
    cba: '',
  },
  isEditedData: false,
  unsavedChangesModalState: { isOpen: false },

  locationOptions: [
    {
      value: '',
      text: '',
      id: '',
    },
  ],
  departmentOptions: [
    {
      value: '',
      text: '',
      id: '',
    },
  ],
  roleOptions: [
    {
      value: '',
      text: '',
      id: '',
    },
  ],
  overlappingErrors: [],
  isMissingStartTimeField: false,
  isMissingEndTimeField: false,
  isMissingTypeField: false,
  isTimeWithin: true,
  isDuplicatedRequest: false,
  areAllFieldsValid: true,
};

export const timecardPremiumsSlice = createSlice({
  name: 'timecardPremiums',
  initialState,
  reducers: {
    setIsOpenAddTimePremiumSidePanel: (state, action) => {
      state.isOpenAddTimePremiumSidePanel = action.payload;
    },
    setNoJobSelected: (state, action) => {
      state.noJobSelected = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setIsEditedData: (state, action) => {
      state.isEditedData = action.payload;
    },
    setUnsavedChangesModalState: (state, action) => {
      state.unsavedChangesModalState = action.payload;
    },
    setLocationOptions: (state, action) => {
      state.locationOptions = action.payload;
    },
    setDepartmentOptions: (state, action) => {
      state.departmentOptions = action.payload;
    },
    setRoleOptions: (state, action) => {
      state.roleOptions = action.payload;
    },
    setOverlappingErrors: (state, action) => {
      state.overlappingErrors = action.payload;
    },
    setIsMissingStartTimeField: (state, action) => {
      state.isMissingStartTimeField = action.payload;
    },
    setIsMissingEndTimeField: (state, action) => {
      state.isMissingEndTimeField = action.payload;
    },
    setIsMissingTypeField: (state, action) => {
      state.isMissingTypeField = action.payload;
    },
    setIsTimeWithin: (state, action) => {
      state.isTimeWithin = action.payload;
    },
    setIsDuplicatedRequest: (state, action) => {
      state.isDuplicatedRequest = action.payload;
    },
    setAreAllFieldsValid: (state, action) => {
      state.areAllFieldsValid = action.payload;
    },
  },
});

export const {
  setIsOpenAddTimePremiumSidePanel,
  setNoJobSelected,
  setSelectedLocation,
  setSelectedDepartment,
  setSelectedRole,
  setIsEditedData,
  setUnsavedChangesModalState,
  setLocationOptions,
  setDepartmentOptions,
  setRoleOptions,
  setIsMissingStartTimeField,
  setIsMissingEndTimeField,
  setIsMissingTypeField,
  setIsTimeWithin,
  setIsDuplicatedRequest,
  setOverlappingErrors,
  setAreAllFieldsValid,
} = timecardPremiumsSlice.actions;

export default timecardPremiumsSlice.reducer;
