import {
  configureStore,
  PreloadedState,
  combineReducers,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { initStateWithPrevTab, withReduxStateSync } from 'redux-state-sync';
import scheduleReducer from './schedule/scheduleSlice';
import timeOffRequestReducer from './timeOffRequest/timeOffRequestSlice';
import systemErrorSlice from './systemError/systemErrorSlice';
import sessionSlice from './session/sessionSlice';
import { rootSaga } from './saga';
import { apiSlice } from './api/apiSlice';
import tapExceptionsSlice from './tapExceptions/tapExceptionsSlice';
import requestLeaveSlice from './requestLeave/requestLeaveSlice';
import timecardPremiumsSlice from './timecardPremiums/timecardPremiumsSlice';
import notificationsSlice from './notifications/notificationsSlice';
import persistUkgSessionMiddleware from './middlewares/persistUkgSession';
import syncReduxStateMiddleware from './middlewares/syncReduxState';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  schedule: scheduleReducer,
  timeOffRequest: timeOffRequestReducer,
  session: sessionSlice,
  error: systemErrorSlice,
  tapExceptions: tapExceptionsSlice,
  requestLeave: requestLeaveSlice,
  timecardPremiums: timecardPremiumsSlice,
  notifications: notificationsSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    preloadedState,
    reducer: withReduxStateSync(rootReducer),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        sagaMiddleware,
        apiSlice.middleware,
        persistUkgSessionMiddleware,
        syncReduxStateMiddleware,
      ),
    /** We should only have access to redux dev tools on local & development env */
    devTools:
      process.env.REACT_APP_DEPLOY_ENV === 'local' ||
      process.env.REACT_APP_DEPLOY_ENV === 'development',
  });
  sagaMiddleware.run(rootSaga);
  /** When user opens a new tab we want to sync ukgSession in redux with previous open tab */
  initStateWithPrevTab(store);
  return store;
};
const store = setupStore();
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
