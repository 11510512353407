export const TIME_MANAGEMENT = '/time-management';
export const SHIFT_MANAGEMENT = '/shift-management';
export const SPLIT_SHIFTS = '/split-shifts';
const MY_SCHEDULE = '/my-schedule';

/** Schedule */
export const SCHEDULE = `${MY_SCHEDULE}/schedule`;

/** Shift Bid */
export const SHIFT_BID_REQUESTS = `${MY_SCHEDULE}/shift-bid-requests`;
export const SHIFT_BID = `${SHIFT_BID_REQUESTS}/shift-bid`;

/** Shift Trade */
export const SHIFT_TRADE = `${MY_SCHEDULE}/shift-trade`;
export const SHIFT_TRADE_REQUEST_SELECT_SHIFT = `${SHIFT_TRADE}/request/select-shift`;
export const SHIFT_TRADE_REQUEST_SELECT_WORKER = `${SHIFT_TRADE}/request/select-worker`;
export const SHIFT_TRADE_REQUEST_REVIEW = `${SHIFT_TRADE}/request/review`;

/* All routes of /split-shifts page */
export const SPLIT_SHIFT_REQUESTS = `${SPLIT_SHIFTS}/requests`;
export const REQUEST_LEAVE_TIME_OFF = 'request-leave-time-off';

/* All routes of /manage-average-hours page */
export const MANAGE_AVERAGE_HOURS = `/manage-average-hours`;
export const TERMINATION_REPORT = `${MANAGE_AVERAGE_HOURS}/exception-reports`;
/** Tap Exception */
export const TAP_EXCEPTION = `${MY_SCHEDULE}/tap-exception`;

/** Timecard & Premiums */
export const TIMECARD_PREMIUMS = `${MY_SCHEDULE}/timecard-premiums`;

/** Historic Add + Edit */
export const HISTORIC_ADD_EDIT = `${MY_SCHEDULE}/historic-add-edit`;

const REQUESTS = '/requests';

/** Request Time-off & Request Leave */
export const MY_REQUESTS = `${REQUESTS}/my-requests`;
export const REQUESTLEAVE = 'request-leave';
export const REQUESTTIMEOFF = 'request-time-off';
export const ADDITIONALQUESTIONS = `${REQUESTLEAVE}/additional-questions`;

/** Resolve Requests */
export const RESOLVE_REQUESTS = `${REQUESTS}/resolve-requests`;

const SCHEDULING = '/scheduling';

/** Schedule Editor */
export const SCHEDULE_EDITOR = `${SCHEDULING}/schedule-editor`;
export const SCHEDULE_EDITOR_SHIFT_MANAGEMENT = `${SCHEDULE_EDITOR}/shift-management`;

/** Unit Schedule */
export const UNIT_SCHEDULE = `${SCHEDULING}/unit-schedule`;

/** Row Assignments */
export const ROW_ASSIGNMENTS = `${SCHEDULING}/row-assignments`;
export const ROW_ASSIGNMENTS_EXCEPTION_REPORT = `${ROW_ASSIGNMENTS}/exception-reports`;

/** Manage Vacancies */
export const MANAGE_VACANCIES = `${SCHEDULING}/manage-vacancies`;

/** Manage Average Hours */
export const MANAGE_AVERAGE_HOURS_EXCEPTION_REPORT = `${MANAGE_AVERAGE_HOURS}/exception-reports`;

const ADMIN = '/admin';

/** Submit For Employee */
export const SUBMIT_FOR_EMPLOYEE = `${ADMIN}/submit-for-employee`;

/**
 * Mapping of a url and the manager APIs available on the url.
 * Used to decide if we should revoke access to the url.
 */
export const MANAGER_URL_TO_API: Record<string, CleverAntAPI.ManagerApiName[]> =
  {
    [`${SCHEDULE_EDITOR}`]: ['Schedule Editor', 'Book On'],
    [`${SCHEDULE_EDITOR_SHIFT_MANAGEMENT}`]: [
      'Call List',
      'Call List by Employee',
      'Manage Open Shifts',
    ],
    [`${UNIT_SCHEDULE}`]: ['Manager Unit Schedule'],
    [`${RESOLVE_REQUESTS}`]: [
      'Resolve Requests',
      'Resolve Long-Term Leave Requests',
    ],
    [`${ROW_ASSIGNMENTS}`]: ['Row Assignment'],
    [`${ROW_ASSIGNMENTS_EXCEPTION_REPORT}`]: ['Row Assignment'],
    [`${MANAGE_VACANCIES}`]: ['Manage Vacancies'],
    [`${MANAGE_AVERAGE_HOURS}`]: ['Manage Average Hours'],
    [`${MANAGE_AVERAGE_HOURS_EXCEPTION_REPORT}`]: ['Manage Average Hours'],
    [`${SUBMIT_FOR_EMPLOYEE}`]: ['Submit For Employee'],
    [`/`]: ['Manager Dashboard', 'Scheduler Dashboard'],
  };
