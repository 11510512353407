// @ts-nocheck
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { rtoOpen } from '../../api/rto/api';
import { fetchRtoSessionFailure, fetchRtoSessionSuccess } from './sessionSlice';

import { getDelegateParams } from '../../components/submit-for-employee/utils';

/**
 * fetchRtoSessionSaga generator function.
 * @param {Aims.OpenSubmitForEmployeeSessionInput} [delegateParams] - The delegate parameters.
 */

export function* fetchRtoSessionSaga(delegateParams) {
  const delegateId = delegateParams?.payload?.delegateId;
  const defaultRoleId = delegateParams?.payload?.defaultRoleId;
  const secondaryRoleId = delegateParams?.payload?.secondaryRoleId;

  const hasDelegateParams = !!(delegateId && defaultRoleId && secondaryRoleId);
  const params = getDelegateParams(delegateId, defaultRoleId, secondaryRoleId);
  const getOptionalParams = hasDelegateParams ? params : null;

  try {
    const ukgSession = yield select(state => state.session?.ukgSession);
    const response = yield call(
      rtoOpen,
      getOptionalParams,
      ukgSession?.accessToken ?? '',
      ukgSession?.username ?? '',
    );
    yield put(fetchRtoSessionSuccess(response.data.Open[0]));
  } catch (error) {
    yield put({ type: fetchRtoSessionFailure, error: error.message });
  }
}
export function* saga() {
  yield takeEvery('sessionSlice/fetchRtoSession', fetchRtoSessionSaga);
}
