// @ts-nocheck
import axios from 'axios';
import { apiParams } from '../params';
import {
  authenticationCredentials,
  apiHeaderConfig,
  postData,
} from '../../utils/helper';
import { PARAMSACTIONVALUE } from '../../utils/constants';

const { closeSessionUrl, runActionUrl, openSessionUrl } = apiParams;
// send cookies along the requests
axios.defaults.withCredentials = true;
const { initialize, loadSchedule, submitRequests } = PARAMSACTIONVALUE;

const rtoRunInitialize = async ({ caid, accesstoken, jwt }) => {
  const initializeResponse = await postData(
    runActionUrl,
    JSON.stringify({}),
    { action: initialize, accessToken: accesstoken, caid },
    apiHeaderConfig(jwt, initialize),
  );
  return initializeResponse;
};

/**
 * rtoOpen And Initialize
 * TODO: split rtoOpen and Initialize
 * Run Initialize only on Dashboard Route
 * @param {Aims.OpenSubmitForEmployeeSessionInput} [delegateParams] - The delegate parameters.
 * @param accessToken string
 * @param username string
 */
const rtoOpen = async (delegateParams, accessToken, username) => {
  const openResponse = await postData(
    openSessionUrl,
    authenticationCredentials(
      process.env.REACT_APP_API_RTO_READ_TEMPLATE,
      username,
      accessToken,
      delegateParams,
    ),
  );

  await rtoRunInitialize({
    caid: openResponse.data.Open[0].CaId,
    accessToken,
    jwt: openResponse.data.Open[0].Jwt,
  });

  return openResponse;
};

const rtoRunLoadSchedule = async ({
  caid,
  jwt,
  startDate,
  endDate,
  accessToken,
}) => {
  const runLoadResponse = await postData(
    runActionUrl,
    JSON.stringify({}),
    {
      action: loadSchedule,
      accessToken,
      caid,
      startDate,
      endDate,
    },
    apiHeaderConfig(jwt, loadSchedule),
  );

  return runLoadResponse;
};

const rtoSubmitSchedule = async ({ payload, caid, jwt, accessToken }) => {
  const submitResponse = postData(
    runActionUrl,
    JSON.stringify(payload),
    {
      action: submitRequests,
      accesstoken: accessToken,
      caid,
    },
    apiHeaderConfig(jwt, submitRequests),
  );
  return submitResponse;
};

const rtoClose = async ({ caid, jwt }) => {
  const closeResponse = await postData(
    closeSessionUrl,
    JSON.stringify({}),
    apiHeaderConfig(jwt),
    {
      caid,
    },
  );
  return closeResponse;
};

export {
  rtoOpen,
  rtoRunInitialize,
  rtoRunLoadSchedule,
  rtoSubmitSchedule,
  rtoClose,
};
